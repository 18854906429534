import {
  Box,
  Text,
  Image,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
  Stack,
  Hide,
  StatNumber,
  Stat,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import admin from "../../assets/images/admin.jpg";
import timezone from "moment-timezone"

function Items({ currentItems, handleChange, handleId, onOpen,isLoading }) {
  return (
    <>
    
      <Box mt="2%" w={{md:"98%",lg:"100%"}}>
     
        <TableContainer rounded="md">
        {currentItems?.length > 0 ? (
          <Table size={{ xl: "sm", lg: "xs", md: "xs" }}>
         
            <Thead bg="gray.100">
              <Tr>
                <Th>
                  <Checkbox
                    p={4}
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter((user) => user?.isChecked !== true)
                        .length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                  ></Checkbox>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                    // textAlign="center"
                  >
                    offer name
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                    // textAlign="center"
                  >
                    user
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px " }}
                    // textAlign="center"
                  >
                    redeemed
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                    // textAlign="center"
                  >
                    quantity
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                    // textAlign="center"
                  >
                    created
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                    // textAlign="center"
                  >
                    close
                  </Text>
                </Th>
              </Tr>
            </Thead>
           
         {currentItems?.map((value) => (
            <Tbody style={{width:"full"}}>
                <Tr key={value._id} mt={4}>
                  <Td mt={4}>
                    <Checkbox
                    p={4}
                      colorScheme="red"
                      isChecked={value?.isChecked || false}
                      name={value?._id}
                      onChange={(e) => handleChange(e, value)}
                    />
                  </Td>
                  <Td>
                    <Flex gap={"2"} alignItems="center">
                      <Image
                        borderRadius="full"
                        boxSize="40px"
                        w={{ xl: "32px", lg: "30px", md: "35px" }}
                        h={{ xl: "32px", lg: "30px", md: "35px" }}
                        src={value?.gallery ? value?.gallery[0] : admin}
                        // alt="Dan Abramov"
                      />
                      <Text
                        fontSize={{ xl: "14px", md: "12px" }}
                        fontWeight={600}
                        ml="10px"
                        color="gray.500"
                      >
                        {value?.offerName}

                        <br />
                        <Text
                          fontSize={{ xl: "12px", md: "11px" }}
                          fontWeight={400}
                          color="gray.500"
                        >
                          {value?.price?.percentagePrice}% Off
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Text
                      fontSize={{ xl: "14px", md: "12px" }}
                      fontWeight={600}
                      color="gray.500"
                    >
                      {value.buyerDetails.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={{ xl: "14px", md: "12px" }}
                      fontWeight={600}
                      ml="12px"
                      color="gray.500"
                    >
                      {value.redeemed}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={{ xl: "14px", md: "12px" }}
                      fontWeight={600}
                      ml="25px"
                      color="gray.500"
                    >
                      {value.quantity}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={{ xl: "14px", md: "12px" }}
                      fontWeight={600}
                      color="gray.500"
                    >
                  
                      <br />
                      {timezone.tz(value.createdAt,"BTS").format("lll")}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      fontSize={{ xl: "14px", md: "12px" }}
                      fontWeight={600}
                      color="gray.500"
                    >
                      {value.status}
                    </Text>
                  </Td>
                </Tr>
              
            </Tbody>
              ))} 
              
          </Table>)
          : (<Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"50vh"} >
            <Text fontFamily={"bold"} fontSize={"25px"}>  No Data Found</Text>
              </Box>
              )}
        </TableContainer>
      </Box>
    </>
  );
}

function RedeemedOfferTable({
  itemsPerPage,
  items,
  handleChange,
  handleId,
  onOpen,
}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    // console.log(newOffset);
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
        onOpen={onOpen}
      />
      <Box bg="white" w="98%" mx="auto">
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default RedeemedOfferTable;
