import { Box, Button, } from '@chakra-ui/react'
import React from 'react';
import Modal from 'react-bootstrap/Modal';

const RequestOfferInfo = ({ handleClose, show, information }) => {
  return (
    <Box>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Message</Modal.Title> */}
          <div style={{
            fontSize: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontWeight: "600",
            fontFamily: "Rubik"
          }}>
            Message
          </div>
        </Modal.Header>
        <Modal.Body>{information}</Modal.Body>
        <Modal.Footer>
          <div>
          <Button variant="secondary" onClick={handleClose}
          style={{color:"#FF1A57",
          border:"1px solid #FF1A57",
          fontSize:"14px",
          background:"transparent"

        
        }}
          >
            Close
          </Button>
          </div>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Box>
  )
}

export default RequestOfferInfo