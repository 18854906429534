import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  homepage: null,
  graphData: null
};

const homeSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    getHomeData: (state, action) => {
      state.homepage = action.payload;
    },
    getGraph: (state, action) => {
      state.graphData = action.payload;
    },
  },
});

export const {getHomeData, getGraph} = homeSlice.actions;

export default homeSlice.reducer;
