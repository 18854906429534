import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  order: {},
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrders: (state, action) => {
      state.orders = action.payload;
      
    },
    getOrder: (state, action) => {
        state.order = action.payload;
        
      },
  },
});

export const { getOrders, getOrder } = orderSlice.actions;

export default orderSlice.reducer;
