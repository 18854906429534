import {combineReducers} from "@reduxjs/toolkit";
import offers from "./offerSlice";
import authUser from "./userSlice";
import seller from "./sellerSlice"
import categories, { getSubCategories } from "./categorySlice";
import homepage from "./homeSlice";
import tickets from './ticketSlice';
import orders from './orderSlice';
import requestedOffers from './requestedOfferSlice';
import redeemedOffers from './redeemOfferSlice';
import notification from './notificationSlice'

const rootReducer = combineReducers({
    authUser,
    offers,
    seller,
    categories,
    getSubCategories,
    homepage,
    tickets,
    orders,
    requestedOffers,
    redeemedOffers,
    notification
});

export default rootReducer;