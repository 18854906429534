import { differenceInYears, isValid, parse } from "date-fns";
import moment from "moment";
import * as yup from "yup";

export const offerValidator = yup
  .object({
    offerName: yup.string().required("Offer Name is required"),
    quantity: yup
      .number()
      .typeError("Quantity must be a number")
      .required("field is required*"),
    startDate: yup.string().required("StartDate is required"),
    endDate: yup.string().required("EndDate is required")
    .test("is-greater", "EndDate should be greater than StartDate", function (value) {
      const { startDate } = this.parent;
      return !startDate || !value || moment(value).isAfter(moment(startDate));
    }),
    cancellationPolicy: yup
      .string()
      .required("Cancellation Policy is required"),
    validUntill: yup.string().required("Cancellation validity is required"),
    description: yup.string().required("offer Description is required"),
    ageRestrictedProduct: yup.string().required("Select any one"),
    terms: yup.string().required("Terms & Conditions are required"),
    popularity: yup.string().required("Popularity is required"),
    deliveryCharges: yup.string().required("Delivery charge is required when delivery option is selected.").optional(),
  })
  .required();

export const signupUpValidator = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid email"),
    password: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        // "8-16 chars, with uppercase, lowercase, number, and special char"
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    businessName: yup.string().required("Business name is mandatory"),
    contactPerson: yup.string().required("Contact person is mandatory"),
    // bio: yup.string().required("Bio is mandatory"),
    category: yup.string().required("Category is mandatory"),
    subCategory: yup.string().required("Sub category is mandatory"),
    phone :yup.string().matches(/^(?!0000)\d{10,10}$/, {message:"Enter valid format",excludeEmptyString:true}).required("Phone number is mandatory")

    // .test(
    //   "no-leading-zero",
    //   "Phone number should be valid",
    //   (value) => !/^0/.test(value) // This regex checks if the phone number starts with 0
    // ),
  })
  .required();

  const accountNumberRegex = /^[0-9]{6,}$/; // At least 6 digits
const routingNumberRegex = /^[0-9]{9}$/; 


 export const stripeDetailsValidator = yup.object({
    dob: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "DOB must be in the format yyyy-MM-dd")
    .required("DOB is required")
    .test('is-18', 'You must be at least 18 years old', function (value) {
      const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
      return isValid(parsedDate) && differenceInYears(new Date(), parsedDate) >= 18;
    }),
    name: yup
      .string().trim()
      .required("Name is required"),
    surName: yup
      .string()
      .required("Lastname is required"),
      accountNumber: yup
      .string()
      .matches(accountNumberRegex, "Invalid account number")
      .required("Account number is required"),
    routingNumber: yup
      .string()
      .matches(routingNumberRegex, "Invalid routing number")
      .required("Routing number is required"),
    businessUrl: yup
    .string()
    .required("Business URL is required")
    .transform(value => {
      if (value && !/^(http:\/\/|https:\/\/)/.test(value)) {
        return 'http://' + value;
      }
      return value;
    })
    .url("Must be a valid URL"),
    // businessUrl: yup
    //   .string()
    //   .url("Must be a valid URL")
    //   .required("Business URL is required"),
    line1: yup
      .string()
      .required("Address Line 1 is required"),
    line2: yup
      .string(),
    postalCode: yup
      .string()
      .required("Postal code is required"),
    state: yup
      .string()
      .required("State is required"),
    city: yup
      .string()
      .required("City is required"),
    currency: yup
      .string()
      .required("Currency is required"),
    country: yup
      .string()
      .matches(/^[A-Z]{2}$/, "Country must be a valid 2-letter ISO code")
      .required("Country is required"),
  }).required();
  

export const loginValidator = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid email"),
    password: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  })
  .required();

export const updatePassword = yup
  .object({
    oldPassword: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Password confirm is required")
      .min(8)
      .max(16),
  })
  .required();

export const resetPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

  export const newTicketValidator = yup.object({
    issue:yup.string().required("Issue is a required field"),
    message:yup.string().required("Message is a required field")
  })