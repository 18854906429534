import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Hide,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

import { Stat, StatNumber } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import Layout from "../../components/Layout";
import { icon_16 } from "../../assets/icons";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultipleOffers,
  getOfferList,
  statusChangeMultipleOffers,
} from "../../api/user";
import { SearchIcon } from "@chakra-ui/icons";
import moment from "moment";
import { toast } from "react-toastify";
import OfferTable from "../../components/modals/offersTable";
import useFetch from "../../customHooks/useFetch";
import Navbar from "../../components/Navbar";

const Dashboard = () => {
  const [ids, setIds] = useState([]);
  const [status, setStatus] = useState("");
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const navigate = useNavigate();

  const handleId = (id) => {
    navigate(`/edit-offers/${id}`);
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      let status = tempUser.map((value) => value.status);
      if (checked) {
        setIds(ids);
        setStatuses(status);
      } else {
        setIds([]);
        setStatuses([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setStatus(value.status);
        setIds([...ids, idss[0]]);
      } else {
        setStatus("");
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };

  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers);
  // console.log("offers", offers);
  let redeemedPrice = Number(offers?.totalRedeemed?.redeemedPrice).toFixed(2);
  const { data, isLoading } = useFetch(getOfferList());

  useEffect(() => {
    if (data) {
      setUsers(data?.data?.offersListing);
    }
  }, [data]);

  const handlePauseOffers = () => {
    if (ids?.length > 0) {
      const apiData = {
        ids,
        status: "pending",
      };
      dispatch(statusChangeMultipleOffers(apiData))
        .then((res) => {
          if (res.statusCode == 200) {
            const data = users.map((item) => {
              const item2 = ids.find((i2) => i2 == item._id);
              return item2 ? { ...item, status: "pending" } : item;
            });
            setUsers(data);
            toast.success(res.message);
            setIds([]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleResumeOffers = () => {
    if (ids?.length > 0) {
      const apiData = {
        ids,
        status: "active",
      };
      if (status === "pending") {
        toast.error("offer is not approved by admin yet.");
      } else if (statuses.length > 0) {
        if (statuses.includes("pending")) {
          toast.error("Offers are not approved by the admin yet.");
        }
      } else {
        dispatch(statusChangeMultipleOffers(apiData))
          .then((res) => {
            if (res.statusCode == 200) {
              const data = users
                .filter(function (obj) {
                  return (
                    ids.filter(function (id) {
                      return id == obj._id;
                    }).length == 0
                  );
                })
                .map((item) => {
                  return {
                    ...item,
                    status: "active",
                  };
                });
              setUsers(data);
              toast.success(res.message);
              setIds([]);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    const updatedList = users.filter((item) => {
      return (
        item.offerName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.status.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });

    if (query === "") {
      setUsers(offers?.offersListing);
    } else {
      setUsers(updatedList);
    }
  };

  const handleDeleteOffers = () => {
    const apiData = {
      ids,
    };

    dispatch(deleteMultipleOffers(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          const data = users.filter((x) => ids.indexOf(x._id) < 0);
          setUsers(data);
          setIds([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="100%" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box  w={{ xl:"99%", lg: "99%",md:"96%"}} 
                  mx={{lg:"auto",md:"auto"}}
                >
            <Navbar />
            <Grid
              w={{ xl: "99%" }}
              mt={4}
              templateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={6}
              ml={{ md: 1, xl: 0 }}
              mr={{ md: 0, xl: 0 }}
            >
              <GridItem w="100%">
                <Box
                  h={"186px"}
                  textAlign="center"
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={"space-evenly"}
                  bg="white"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#B5D779"
                    >
                      {offers?.totalActiveOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize="20px"
                      lineHeight="34px"
                      rounded="lg"
                      mt="-15px"
                      textTransform="capitalize"
                    >
                      active offers
                    </Text>
                  </Box>
                  <Box mt="-15px">
                    <Text
                      fontWeight={600}
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize="20px"
                      lineHeight="34px"
                      mt="-15px"
                      textTransform="capitalize"
                    >
                      total offers
                    </Text>
                  </Box>
                </Box>
              </GridItem>
              <GridItem w="100%">
                <Box
                  textAlign="center"
                  h={"186px"}
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={"space-evenly"}
                  bg="white"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalSold?.sold || 0}
                    </Text>
                    <Stat>
                      <StatNumber
                        color="#7B6F6F"
                        fontSize="28px"
                        lineHeight={"34px"}
                        fontFamily={"normal"}
                        fontWeight={600}
                        mt="-10px"
                        letterSpacing={"-0.02em"}
                      >
                        £{offers?.totalSold?.SoldPrice || 0}
                      </StatNumber>
                    </Stat>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      textTransform="capitalize"
                    >
                      total sold
                    </Text>
                  </Box>
                </Box>
              </GridItem>
              <GridItem w="100%">
                <Box
                  textAlign="center"
                  h={"186px"}
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={"space-evenly"}
                  bg="white"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalRedeemed?.redeemed || 0}
                    </Text>
                    <Stat>
                      <StatNumber
                        color="#7B6F6F"
                        fontSize="28px"
                        lineHeight={"34px"}
                        fontFamily={"normal"}
                        fontWeight={600}
                        mt="-10px"
                        letterSpacing={"-0.02em"}
                      >
                        £{redeemedPrice || 0}
                      </StatNumber>
                    </Stat>
                  </Box>
                  <Box>
                    <Text
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      total redemeed
                    </Text>
                  </Box>
                </Box>
              </GridItem>

              <GridItem w="100%">
                <Box
                  textAlign="center"
                  boxShadow="lg"
                  p="1"
                  rounded="lg"
                  h={"186px"}
                  bg="#FFEBEB"
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="space-evenly"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.suspendedOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      mt="-5px"
                    >
                      Suspended offers
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      bg="transparent"
                      border="1px solid #FF1A57"
                      w="140px"
                      h="33px"
                      p="1px"
                    >
                      <Flex>
                        <Image src={icon_16} />
                        <Text color="#FF1A57" fontSize="xs">
                          Filter by Suspended
                        </Text>
                      </Flex>
                    </Button>
                  </Box>
                </Box>
              </GridItem>
            </Grid>

            <Box
              display="flex"
              justifyContent="space-between"
              mt="15px"
              gap="2"
            >
              <Box
                w={{ xl: "50.5%", lg: "51%", md: "50%" }}
                ml={{ md: "1", xl: "0" }}
              >
                <FormLabel
                  color="#6B6162"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight={"21px"}
                >
                  Search offers
                </FormLabel>
                <Box display="flex" bg="white" >
                  <InputGroup w={["98%", "100%"]}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="#FF1A57" />}
                    />
                    <Input
                      type="tel"
                      pl={12}
                      _focus={{
                        outline: "none",
                        border: "none",
                      }}
                      _after={{
                        outline: "none",
                        border: "none",
                      }}
                      _active={{
                        outline: "none",
                      }}
                      bg="white"
                      shadow="lg"
                      borderRadius={5}
                      borderColor="#B1ABA9"
                      onChange={handleSearch}
                      placeholder="Search for offer name and status..."
                    />
                  </InputGroup>
                </Box>
              </Box>
              <Box>
                
              </Box>
              <Box
                w={{ xl: "24.5%",lg:"24.5%",md: "25%" }}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
                gap="2"
                mr={{lg:"0px",md:"4px"}}
              >
                <Text color="#6B6162" fontSize="14px" fontWeight="400">
                  Bulk actions<Hide below="lg">(select admins)</Hide>
                </Text>
                <Button
                  bg={"primary.100"}
                  color="white"
                  disabled={ids?.length < 1 ? true : false}
                  onClick={handlePauseOffers}
                  fontSize={{lg:"14px",md:"13px"}}
                  fontWeight="600"
                  w={{lg:"100%",md:"100%"}}
                >
                  Pause offer(s)
                </Button>
              </Box>
              <Box
                w={{ xl: "24%", lg: "24%", md: "25%" }}
                // mr={{ lg:0,md: "5", xl: "2" }}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
                ml={{lg:"0px",md:"0px"}}
              >
                <Button
                  bg={"primary.100"}
                  color="white"
                  disabled={ids?.length < 1 ? true : false}
                  onClick={handleResumeOffers}
                  fontSize={{lg:"14px",md:"13px"}}
                  fontWeight="600"
                  w={{lg:"100%",md:"95%"}}
                >
                  Resume offer(s)
                </Button>
              </Box>
              <Box
                w={{ xl: "24%", lg: "24%", md: "25%" }}
                mr={{ md: "0", xl: "2" }}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
              >
                <Button
                  bg={"primary.100"}
                  color="white"
                  disabled={ids?.length < 1 ? true : false}
                  onClick={handleDeleteOffers}
                  fontSize={{lg:"14px",md:"13px"}}
                  fontWeight="600"
                  w={{lg:"100%",md:"100%"}}
                >
                  Delete offer(s)
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt="2%" w="full">
            <OfferTable
              itemsPerPage={10}
              items={users}
              handleChange={handleChange}
              handleId={handleId}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
