import Home from "../IconComponents/Home"
import Logout from "../IconComponents/Logout";
import ManageOffers from "../IconComponents/ManageOffers";
import ManageOrders from "../IconComponents/ManageOrders";
import MyBusiness from "../IconComponents/MyBusiness";
import RedeemOffers from "../IconComponents/RedeemOffers";
import RequestedOffers from "../IconComponents/RequestedOffers";
import Subscriptions from "../IconComponents/Subscriptions";
import SupportCenter from "../IconComponents/SupportCenter";
import { BsBell } from "react-icons/bs";

export const DATA = [
  {
    img: <Home />,
    heading: "Home",
    Path: "/home",
  },
  {
    img: <ManageOffers />,
    heading: "Manage Offers",
    Path: "/manage-offers",
  },
  {
    img: <ManageOrders />,
    heading: "Manage Orders",
    Path: "/manage-orders",
  },
  {
    img: <MyBusiness />,
    heading: "My Business",
    Path: "/my-business",
  },
  {
    img: <BsBell />,
    heading: "Notification",
    Path: "/notification",
  },
  {
    img: <SupportCenter />,
    heading: "Support Centre",
    Path: "/support-centre",
  },
  {
    img: <RedeemOffers />,
    heading: "Redeem Offers",
    Path: "/redeem-offers",
  },
  // {
  //   img: <RedeemOffers />,
  //   heading: "Redeem Offers",
  //   Path: "/redeem-offers",
  // },
  {
    img: <RequestedOffers />,
    heading: "Requested Offers",
    Path: "/requested-offers",
  },
  {
    img: <Subscriptions />,
    heading: "Subscriptions",
    Path: "/your-selection",
  },
];

  export const LogoutImage = [
    {
      img : <Logout />,
     heading : "Logout"

      }
  ]