import React from "react";
import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOldPassword,
  editProfile,
  getSellerDetails,
} from "../../api/user";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import { toast } from "react-toastify";
import { useEffect } from "react";
import UpdateProfile from "../../components/UpdateProfile";
import { updatePassword } from "../../helpers/validation";
import Navbar from "../../components/Navbar";
import DeleteModal from "../../components/modals/DeleteModal";

const Profile = () => {
  const { seller } = useSelector((state) => state.seller);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePassword),
  });

  const [show, setShow] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = React.useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [show1,setShow1] = useState(false)
  const handleClose = () => setShow1(false);
 
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => setShow(!show);
  const handleClickOnNew = () => setShowNewPassword(!showNewPassword);
  const handleClickOnConfirm = () => setShowConfrimPassword(!showConfrimPassword);

  const handleChangePassword = (data) => {
    setIsLoading(true);
    dispatch(changeOldPassword(data))
      .then((res) => {
        setIsLoading(false);
        toast.success(res.message);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (seller) {
      reset({
        businessName: seller?.name?.businessName,
        contactPerson: seller?.name?.contactPerson,
      });
      setProfileImage(seller.profileImage);
    }
  }, [seller]);
  const handleProfile = (data) => {
    const apiData = {
      businessName: data.businessName,
      contactPerson: data.contactPerson,
      profileImage: profileImage,
    };
    dispatch(editProfile(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          dispatch(getSellerDetails());
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  return (
    <>
      <Layout>
        <Box w={{ xl: "100%", md: "95%" }} mx={{lg:"auto",md:"auto"}}>
          {/* <Box display="flex" justifyContent="space-between">
            <Text
              fontFamily="Rubik"
              color="#2E1A47"
              fontSize={{ xl: "34px", md: "30px" }}
              fontWeight="600"
              lineHeight="40px"
              letter="-4%"
            >
              Profile
            </Text>
            <Header />
          </Box> */}
          <Navbar />
          <UpdateProfile />
          <Box w={{ xl: "50%", md: "100%" }} mt={8}>
            <Text
              fontSize={{ xl: "34px", md: "30px" }}
              fontWeight="600"
              color="#1E0F42"
              fontFamily="Rubik"
            >
              Reset password
            </Text>
            <FormControl mt={5}>
              <FormLabel mb="0.5">
                <Text
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="400"
                  color="#6B6162"
                >
                  Old Password
                </Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  bg="white"
                  shadow="lg"
                  w="100%"
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  name="oldPassword"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  fontSize={{ xl: "14px", md: "12px" }}
                  {...register("oldPassword", { required: true })}
                />

                <InputRightElement width="4.5rem">
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClick}
                  >
                    {show ? "Hide" : "Show"}
                  </ViewIcon>
                </InputRightElement>
              </InputGroup>
              {errors?.oldPassword && (
                <Text my={1} color="red" fontSize="xs">
                  Old Password is required
                </Text>
              )}

              <FormLabel mt={8} mb="0.5">
                <Text
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="400"
                  color="#6B6162"
                >
                  New Password
                </Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  bg="white"
                  shadow="lg"
                  w="100%"
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  fontSize={{ xl: "14px", md: "12px" }}
                  name="password"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter password"
                  {...register("password")}
                />
                <InputRightElement width="4.5rem">
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnNew}
                  >
                    {showNewPassword ? "Hide" : "Show"}
                  </ViewIcon>
                </InputRightElement>
              </InputGroup>
              {errors?.password && (
                <Text my={1} color="red" fontSize="xs">
                  New Password is required
                </Text>
              )}

              <FormLabel mt={8} mb="0.5">
                <Text
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="400"
                  color="#6B6162"
                >
                  Confirm Password
                </Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  bg="white"
                  shadow="lg"
                  w={["95%", "100%"]}
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  name="confirmPassword"
                  fontSize={{ xl: "14px", md: "12px" }}
                  type={showConfrimPassword ? "text" : "password"}
                  placeholder="Enter password"
                  {...register("confirmPassword")}
                />
                <InputRightElement width="4.5rem">
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnConfirm}
                  >
                    {showConfrimPassword ? "Hide" : "Show"}
                  </ViewIcon>
                </InputRightElement>
              </InputGroup>
              {errors?.confirmPassword && (
                <Text my={1} color="red" fontSize="xs">
                  Password must match
                </Text>
              )}
            </FormControl>
            <Box mt={10} w={["95%", "100%"]}>
              <Button
               bg="#FF1A57"
               w="full"
               fontSize="14px"
               fontWeight="600"
               color="#FFFFFF"
                isLoading={isLoading}
                onClick={handleSubmit(handleChangePassword)}
              >
                <Text fontSize="14px" fontWeight="600" color="#FFFFFF">
                  Confirm changes
                </Text>
              </Button>
              
            </Box>

            <Box mt={10} w={["95%", "100%"]}>
              <Button
               bg="#FF1A57"
               w="full"
               fontSize="14px"
               fontWeight="600"
               color="#FFFFFF"
                isLoading={isLoading}
                onClick={()=>setShow1(true)}
              >
                <Text fontSize="14px" fontWeight="600" color="#FFFFFF">
                  Delete Your Account
                </Text>
              </Button>
              
            </Box>
          </Box>
        </Box>
      </Layout> 
      <DeleteModal show={show1} setShow={setShow1} handleClose={handleClose} />
    </>
  );
};

export default Profile;
