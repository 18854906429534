import {
  Box,
  Text,
  Image,
  HStack,
  VStack,
  Button,
  Stack,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  getGraphData,
  getHomepageData,
  getSellerDetails,
  getSellerUpdate,
  setPopularity,
} from "../../api/user";
import { icon_24, icon_25, icon_26, icon_27 } from "../../assets/icons";
import Charts from "../../components/Chart";
import TopCard from "../../components/Dashboard/TopCard";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import TopRow from "../../components/TopRow";
import useGraph from "../../hooks/useGraph";
import Navbar from "../../components/Navbar";
import { getFromLocalStorage } from "../../helpers";

const MainDashboard = () => {
  const [navSize, changeNavSize] = useState("large");
  const [type,setType] = useState("today")
  const dispatch = useDispatch();
  const { homepage, graphData } = useSelector((state) => state.homepage);
  const { seller } = useSelector((state) => state.seller);
  const user = getFromLocalStorage('userData');
  // const { user } = useSelector((state) => state.authUser);
  const navigate = useNavigate();
  const { handleGraphType, isLoading ,setFilter} = useGraph();


  useEffect(()=>{
    if(type){
      switch (type) {
        case "today":
          setFilter("daily");
          break;
        case "thisWeek":
          setFilter("weekly");
          break;
        case "thisMonth":
          setFilter("monthly");
          break;
        case "thisYear":
          setFilter("yearly");
          break;
        default:
          setFilter("today");
          break;
      }
    }
  },[type])

  

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      if (window.location.pathname === '/login') {
        window.history.pushState(null, document.title, window.location.href);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (user?.subscriptionPlan === "") {
      navigate("/choose-how-to-promote");
    }
  }, [user, navigate]);




  useEffect(() => {
    if(type)
    dispatch(getHomepageData(type));
  }, [dispatch,type]);

  const setPopularityData = (data) => {
    const sellerId = seller._id;
    const apiData = {
      popularity: data,
    };
    dispatch(setPopularity(sellerId, apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSellerDetails());
        }
      })
      .catch((err) => console.log(err));
  };

  const handleManage = () => {
    navigate("/manage-offers");
  };

  const handleTickets = () => {
    navigate("/support-centre");
  };
  const [loading, setLoading] = useState(false);


  return (
    <Layout>
      <Box w={{ sm: "full", md: "auto", lg: "auto", xl: "99%" }} mx="auto" h={{xl:"",lg:"",md:"auto",sm:"auto"}}>
        <Navbar  navSize={navSize} changeNavSize={changeNavSize} />
        {/* <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text
            color="#2E1A47"
            fontSize={{ md: 28, lg: 30, xl: 34 }}
            fontWeight={600}
          >
            Home
          </Text>
          <Header />
        </Box> */}
        <TopRow setPopularityData={setPopularityData} setType={setType}  type={type} loading={loading} setLoading={setLoading} />
        <Stack
          alignItems="baseline"
          direction={{ sm: "column", md: "row" }}
          justifyContent="space-between"
        >
          <TopCard
            total={homepage?.offersSold?.soldOffers}
            title="Offers sold"
            profit={homepage?.offersSold?.thisWeekSold}
            icon={icon_24}
          />
          <TopCard
            total={homepage?.income?.totalIncome}
            title="Income"
            profit={homepage?.income?.thisWeekIncome}
            icon={icon_25}
          />
          <TopCard
            total={homepage?.activeOffers?.totalActiveOffers}
            title="Active Offers"
            profit={homepage?.activeOffers?.thisWeekActive}
            icon={icon_26}
          />
        </Stack>
        <Grid
          templateRows={{ lg: "repeat(2, 1fr)", md: "repeat(1, 1fr)" }}
          templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(6, 1fr)" }}
          gap={{ xl: 3, lg: 2, md: 4 }}
          // bg="red"
          mt={{md:"10px"}}
        >
          <GridItem
            rounded="8px"
            h={{ lg: "410px", md: "405px" }}
            rowSpan={{ lg: 2, md: 2 }}
            colSpan={{ lg: 4, sm: 0, md: 6 }}
            w={{ lg: "none", md: "100%", sm: "100%" }}
            shadow={{ lg: "lg", md: "lg" }}
          >
            <Tabs
              color="#6B6162"
              onChange={(index) => handleGraphType(index)}
              w={{ lg: "0p%", md: "100%" }}
            >
              <TabList>
                <Tab
                  fontSize={{ xl: "15px", lg: "13px", md: "12px" }}
                  fontWeight={400}
                >
                  Daily Sales
                </Tab>
                <Tab
                  fontSize={{ xl: "15px", lg: "13px", md: "12px" }}
                  fontWeight={400}
                >
                  Income
                </Tab>
                <Tab
                  fontSize={{ xl: "15px", lg: "13px", md: "12px" }}
                  fontWeight={400}
                >
                  Returning Customers
                </Tab>
                <Tab
                  fontSize={{ xl: "15px", lg: "13px", md: "12px" }}
                  fontWeight={400}
                >
                  Offer Views
                </Tab>
              </TabList>
              {loading ? (
                <HStack
                  w="full"
                  h={260}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner color="primary" />
                </HStack>
              ) : (
                <TabPanels>
                  <TabPanel>
                    <Charts
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Charts
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Charts
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Charts
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                </TabPanels>
              )}
            </Tabs>
          </GridItem>

          <GridItem
            rounded="8px"
            colSpan={{ xl: 2, lg: 2, md: 0, sm: 4 }}
            bg="#FFEBEB"
            p={{xl:"",lg:"",md:"16px"}}
           
          >
            <Box spacing={-4} mt={{ lg: "0px", md: "2px" }}>
              <HStack justifyContent="center">
                <Image
                  src={icon_26}
                  w={{ xl: 8, lg: 6, md: 6 }}
                  h={{ xl: 8, lg: 6, md: 6 }}
                />
                <Text
                  color="#FF1A57"
                  fontSize={{ xl: 36, lg: 30, md: 23 }}
                  fontWeight={800}
                >
                  {homepage?.suspendedOffers || 0}
                </Text>
              </HStack>
              <VStack spacing={6}>
                <Text
                  fontSize={{ xl: 20, lg: 17, md: 16 }}
                  color="primary"
                  fontWeight={600}
                >
                  Suspended Offer
                </Text>
                <Button
                  variant="outline"
                  borderColor="#FF1A57"
                  color="#FF1A57"
                  fontSize={{ xl: "13px", lg: "11px", md: "12px" }}
                  onClick={handleManage}
                >
                  {"Manage >"}
                </Button>
              </VStack>
            </Box>
          </GridItem>
          <GridItem rounded="8px" colSpan={[4, 2]} p={[4, 0]} bg="#FFEBEB">
            <VStack spacing={{ lg: -4, md: -4 }}>
              <HStack my={4}>
                <Image
                  src={icon_27}
                  w={{ xl: 8, lg: 6, md: 6 }}
                  h={{ xl: 8, lg: 6, md: 6 }}
                />
                <Text
                  color="#FF1A57"
                  fontSize={{ xl: 36, lg: 30, md: 23 }}
                  fontWeight={800}
                >
                  {homepage?.supportTickets || 0}
                </Text>
              </HStack>
              <VStack spacing={4}>
                <Text
                  fontSize={{ xl: 20, lg: 17, md: 16 }}
                  color="primary"
                  fontWeight={600}
                >
                  Support Ticket
                </Text>
                <Button
                  variant="outline"
                  borderColor="#FF1A57"
                  color="#FF1A57"
                  fontSize={{ xl: "13px", lg: "11px", md: "12px" }}
                  onClick={handleTickets}
                >
                  {"View >"}
                </Button>
              </VStack>
            </VStack>
          </GridItem>
        </Grid>
      </Box>                                      
    </Layout>
  );
};

export default MainDashboard;
