import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSupportTickets, getAllTickets } from "../../api/user";
import { plus } from "../../assets/icons";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import TicektsTable from "../../components/modals/ticketsTable";
import Navbar from "../../components/Navbar";

const Index = () => {
  const [ids, setIds] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { tickets } = useSelector((state) => state.tickets);

  const handleNewTicket = () => {
    navigate("/new-ticket");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllTickets())
      .then((res) => {
        setIsLoading(false);
        setUsers(res.data.ticketsListing);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handleId = (val, roomId) => {
    if (val.supportTicketId.status === "pending") {
    } else if (val.supportTicketId.status === "in-process") {
      navigate(`/support-centre/${val.supportTicketId._id}`, {
        state: { roomId },
      });
    } else {
      toast.error("Please create a new ticket");
    }
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    // console.log(query, "query");
    const updatedList = users.filter((item) => {
      return (
        item.supportTicketId.issue
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1 ||
        item.supportTicketId.status
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
      );
    });
    // console.log(updatedList, "update");

    if (query === "") {
      setUsers(tickets);
    } else {
      setUsers(updatedList);
    }
  };

  const handleBulkDeleteTickets = () => {
    const selectedUsers = users?.filter((user) => user?.isChecked === true);

    // return console.log(selectedUsers, "selectedUsers");

    const selectedIds = [];

    const selectedRoomIds = [];

    for (const selectedUser of selectedUsers) {
      selectedIds.push(selectedUser.supportTicketId._id);
      selectedRoomIds.push(selectedUser._id);
    }

    const mutationData = {
      ids: selectedIds,
      roomIds: selectedRoomIds,
    };

    // console.log(mutationData, "mutationData");

    // ******** code needed to be updated once, backend has been updated ********* //

    dispatch(deleteSupportTickets(mutationData)).then(() => {
      const updatedData = users?.filter((user) => !user?.isChecked);
      setUsers(updatedData || []);
    });

    // dispatch(deleteSupportTickets({ ids: mutationData?.ids })).then(() => {
    //   const updatedData = users?.filter((user) => !user?.isChecked);
    //   setUsers(updatedData || []);
    // })
  };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box
            w={{ xl: "99%", lg: "98%", md: "96%" }}
            mx={{ xl: "auto", lg: "auto", md: "auto" }}
          >
            {/* <Box display="flex" w="full" justifyContent="space-between">
              <Text
                fontFamily="Rubik"
                color="#2E1A47"
                fontSize={{ xl: 30, lg: 26, md: 24 }}
                fontWeight="600"
                lineHeight="40px"
                letter="-4%"
              >
                Support Centre
              </Text>
              <Header />
            </Box> */}

            <Navbar />
            <Box
              display={{ xl: "flex", lg: "flex" }}
              w="full"
              justifyContent="space-between"
              mt="15px"
              gap="2"
            >
              <Box w={{ xl: "45%", lg: "38%", md: "100%" }}>
                <FormLabel
                  color="#6B6162"
                  fontSize={{ xl: "14px", md: "12px" }}
                  fontWeight="400"
                  lineHeight={"21px"}
                >
                  Search tickets
                </FormLabel>
                <Box display="flex" bg="white">
                  <InputGroup w={{ xl: "98%", lg: "96%", md: "100%" }}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="#FF1A57" />}
                    />
                    <Input
                      type="tel"
                      _focus={{
                        outline: "none",
                        border: "none",
                      }}
                      _after={{
                        outline: "none",
                        border: "none",
                      }}
                      _active={{
                        outline: "none",
                      }}
                      bg="white"
                      shadow="lg"
                      borderRadius={5}
                      borderColor="#B1ABA9"
                      onChange={handleSearch}
                      placeholder="Search for ticket name and status..."
                    />
                  </InputGroup>
                </Box>
              </Box>
              <Box
                w={{ xl: "20%", lg: "18%", md: "100%" }}
                display="flex"
                mt={"7"}
              >
                <Button
                  bg="#FF1A57"
                  color="white"
                  w="full"
                  borderRadius={"5px"}
                  onClick={handleNewTicket}
                >
                  <Image src={plus} />
                  <Text
                    fontWeight={"600px"}
                    fontSize={{ xl: "14px", lg: "12px", md: "14px" }}
                  >
                    New Support Ticket
                  </Text>
                </Button>
              </Box>
              <Box
                w={{ xl: "18%", lg: "23%", md: "100%" }}
                display="flex"
                flexDirection="column"
                gap={"2"}
                mt={{ lg: "0", md: "5" }}
              >
                <Text
                  color="#6B6162"
                  fontSize={{ xl: "14px", md: "14px" }}
                  fontWeight="400"
                >
                  Bulk actions(select tickets)
                </Text>
                <Button
                  bg={"primary.100"}
                  color="white"
                  fontSize={{ xl: "14px", md: "14px" }}
                  fontWeight="600"
                >
                  Marked as resolved
                </Button>
              </Box>
              <Box
                w={{ xl: "18%", lg: "22%", md: "100%" }}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
                mt={{ lg: "0", md: "5" }}
              >
                <Button
                  disabled={!users.some((user) => user.isChecked)}
                  onClick={handleBulkDeleteTickets}
                  bg={"primary.100"}
                  color="white"
                  fontSize={{ xl: "14px", md: "14px" }}
                  fontWeight="600"
                >
                  Delete ticket
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt="2%" w="full">
            <TicektsTable
              itemsPerPage={2}
              items={users}
              handleChange={handleChange}
              handleId={handleId}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default Index;
