import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../api/user';
import deleteImg from '../../assets/images/delete_icn.svg'
import {
    Button,
    Text,
    Spacer
  } from "@chakra-ui/react"
import { logout } from '../../api';
const DeleteModal = ({show,setShow,handleClose}) => {
const dispatch = useDispatch()


  const delteUser = () =>{
    dispatch(deleteUser()).then((res)=>{
      if(res.statusCode === 200){
        logout()
      }
       
    }).catch((err)=>{
        console.log(err)
    })
  }

  return (
    <>
    

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-dialog modal-dialog-centered">
           <div className="modal-content">
             <div className="modal-body text-center p-3">
               <div className="img-wrap mb-3 pt-1">
                 <img style={{margin:"auto"}} src={deleteImg} />
               </div>
               <h5 className="mb-3">Delete account</h5>
               <p className="mb-3">
                 Are you sure you want to delete your account? If you delete your
                 account, you will permanently lose your Profile.
             </p>
              <Button  fontSize="14px"
               fontWeight="600"
               color="#FFFFFF"
               bg="#FF1A57"
               w="full"
              onClick={delteUser}
              >
                <Text fontSize="14px" fontWeight="600" color="#FFFFFF">
                  Delete Account
                </Text>
              </Button>
              
              <Button
               w="full"
               mt={3}
               onClick={handleClose}
              >
                <Text fontSize="14px" fontWeight="600" >
                  Cancel
                </Text>
              </Button>
            </div>
          </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal