import {
  Box,
  Button,
  Flex,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Toast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { frame, updatedLogo } from "../../assets/icons";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { userLoginUsingEmail } from "../../api/user";
import { toast } from "react-toastify";
import { getFromLocalStorage } from "../../helpers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { loginValidator } from "../../helpers/validation";
import { onMessageListener, requestForToken } from "../../firebase";

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getFromLocalStorage("token");
  const [isTokenFound, setTokenFound] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [notification, setNotification] = useState({ title: "", body: "" });

  requestForToken(setTokenFound, setCurrentToken);



  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      toast.success(payload.data.title);
  
    })
    .catch((err) => console.log("failed: ", err));

 

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidator),
  });

  const handleLogin = (data) => {
    const apiData = { ...data, deviceToken: currentToken,deviceType:"web" };   
    setIsLoading(true);
    dispatch(userLoginUsingEmail(apiData))
      .then((res) => {
        if (res.data.user.subscriptionPlan === "") {
          navigate("/choose-how-to-promote");
        }
        else {
          navigate("/home");
        }
        setIsLoading(false);
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleClick = () => setShow(!show);

  return (
    <>
      <Box display={"grid"} placeItems={"center"} h={"100vh"}>
        <Box w={{ lg: "70%" }} display={"grid"} placeItems={"center"}>
          <Box>
            <VStack>
              <Image src={updatedLogo} w="170px" h="42px" />
              <Text ml={""} fontWeight="400" fontSize="14px" lineHeight="21px">
              Merchant Login
              </Text>
            </VStack>
          </Box>
          <VStack w={{ lg: "40%", sm: "90%" }}>
            <Box w="100%" pt={"3"}>
              <FormLabel
                alignSelf={"flex-start"}
                color="#6B6162"
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
                fontStyle={"normal"}
              >
                Email
              </FormLabel>
              <Input
                bg="white"
                type="email"
                placeholder="Enter email"
                fontWeight="400"
                fontSize="14px"
                lineHeight="21px"
                color="#4B4447"
                border="1px"
                borderColor={"#B1ABA9"}
                shadow="xl"
                {...register("email")}
              />

              <Text my={1} color="red" fontSize="xs">
                {errors.email?.message}
              </Text>
            </Box>
            <Box w="100%" pt={"3"}>
              <FormLabel
                alignSelf={"flex-start"}
                color="#6B6162"
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
                fontStyle={"normal"}
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  bg="white"
                  shadow="xl"
                  fontWeight="400"
                  fontSize="14px"
                  w={["100%"]}
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  name="oldPassword"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  {...register("password", { required: true })}
                />
                <InputRightElement width="4.5rem">
                  {show ? (
                    <ViewIcon
                      h="1.75rem"
                      size="lg"
                      color="#6B6162"
                      onClick={handleClick}
                    />
                  ) : (
                    <ViewOffIcon
                      h="1.75rem"
                      size="lg"
                      color="#6B6162"
                      onClick={handleClick}
                    />
                  )}
                  {/* <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClick}
                  >
                    {show ? "Hide" : "Show"}
                  </ViewIcon> */}
                </InputRightElement>
              </InputGroup>

              <Text color="red" fontSize="xs">
                {errors.password?.message}
              </Text>
            </Box>
            <Text
              alignSelf="flex-end"
              color="#FF1A57"
              fontSize="14px"
              fontWeight="400"
              lineHeight="21px"
              cursor={"pointer"}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </Text>
            <Box w="100%">
              <Button
                color="white"
                bg="#FF1A57"
                w="full"
                mt={"5"}
                fontSize="14px"
                fontWeight="600"
                lineHeight="21px"
                onClick={handleSubmit(handleLogin)}
                isLoading={isLoading}
                id="login"
              >
                Login
              </Button>
            </Box>
            <Flex>
              <Text
                color="#4B4447"
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
              >
                Don't have an account?
              </Text>
              <Text
                color="#FF1A57"
                fontSize="14px"
                ml="2px"
                fontWeight="400"
                lineHeight="21px"
                cursor={"pointer"}
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </Text>
            </Flex>
          </VStack>
          <Box w={{ lg: "40%", sm: "90%" }}>
            {/* <Text
              color="#000000"
              fontSize="14px"
              fontWeight="600"
              lineHeight="21px"
              textAlign="center"
            >
              Need Help?
            </Text> */}
            <Button
              color="#1E0F42"
              w={{ lg: "100%", sm: "100%" }}
              fontSize="12px"
              fontWeight="600"
              lineHeight="18px"
              bg="transparent"
              border="1px solid #1E0F42"
              mt="8px"
              onClick={() => navigate("/contact")}
            >
              Customer Support
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Index;
