import { setToLocalStorage } from "../helpers";
import { getCategories, getSubCategories } from "../redux/reducers/categorySlice";
import { getGraph, getHomeData } from "../redux/reducers/homeSlice";
import { getOffer, getOffers } from "../redux/reducers/offerSlice";
import { getOrder, getOrders } from "../redux/reducers/orderSlice";
import { getRequestedOffers } from "../redux/reducers/requestedOfferSlice";
import { getTickets, getTicket } from "../redux/reducers/ticketSlice";
import { getSellers } from "../redux/reducers/sellerSlice";
import { loginSuccess, signupSuccess, updateUser, updateUserBussinessDetails } from "../redux/reducers/userSlice";
import api from "./index";
import {
  getRedeemedOffer,
  getRedeemedOffers,
} from "../redux/reducers/redeemOfferSlice";
import { getNotification,clearNotification } from "../redux/reducers/notificationSlice";

//Login using email

export const userLoginUsingEmail = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/auth/login", apiData);
      // console.log(res, "resssss");
      dispatch(loginSuccess(res.data.data));
      setToLocalStorage("token", res.data.data.token);
      setToLocalStorage("userData", res.data.data.user);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

export const userSignup = (apiData) => async (dispatch) => {
  const { openingDays } = apiData;
  const arr = openingDays?.map((item) => {
    delete item.id;
    delete item.isChecked;
    return item;
  });
  const finalData = { ...apiData, openingDays: arr };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/auth/signup", finalData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

export const uploadMultipleImages = (apiData) => async (dispatch) => {
  const imageData = new FormData();
  [...apiData].forEach((image) => {
    imageData.append("image", image);
    // console.log(imageData,"imageData")
  });
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "common/file/multiple-image-upload",
        imageData
      );
      resolve(res.data.data.image);
    } catch (err) {
      reject(err);
    }
  });
};

// file upload
export const singleFileUpload = (data) => async () => {
  const formData = new FormData();
  formData.append("image", data);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/common/file/image-upload", formData);
      // console.log(response.data,"======>>>>>>")
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

//**           Change Old Password            */
export const changeOldPassword = (apiData) => async (dispatch) => {
  delete apiData.confirmPassword;
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put("/seller/auth/change-password", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

//// *******************   Forgot password ***********************

export const forgotPassword = (apiData) => async (dispatch) => {
  //  return console.log(apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/auth/forgot-password", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//// *******************   seller subscription  ***********************

export const subscription = (apiData) => async (dispatch) => {
  //  return console.log(apiData,"=========");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/subscription/initialzePayment", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//// *******************   seller subscription plus  ***********************

export const subscriptionPlus = (apiData) => async (dispatch) => {
  //  return console.log(apiData,"=========");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/subscription/initialzePaymentDPlus", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//// *******************   seller subscription pro plan ***********************

export const subscriptionProPlan = (apiData) => async (dispatch) => {
  //  return console.log(apiData,"=========");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/subscription/update_plan", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//// *******************   seller subscription upgrade plan ***********************

export const upgradeSubscription = (apiData) => async (dispatch) => {
  //  return console.log(apiData,"=========");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/subscription/upgrade_plan", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//// *******************   seller subscription pro plan ***********************

export const updateUserStripe = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put("/seller/update-seller-stripe", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//---------------otp verification-------------------

export const otpVerification = (apiData) => async (dispatch) => {
  // return console.log(apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/auth/verify-otp", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//---------------otp verification-------------------

export const resetPassword = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/auth/reset-password", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------  get category -------------------

export const getCategory = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/category/get-category");
      resolve(res.data);
      dispatch(getCategories(res.data.data.categories));
    } catch (error) {
      reject(error);
    }
  });
};
// ----------------  get sub category -------------------

export const getSubCategory = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/seller/category/get-subCategory/${id}`);
      resolve(res.data);
      dispatch(getSubCategories(res.data.data.categories[0].subCategory));
    } catch (error) {
      reject(error);
    }
  });
};

// ---------- create offer ----------------------

export const createOffer = (apiData) => async (dispatch) => {
  const { days } = apiData;
  const newObj = days
    .filter((item) => item.isChecked === true)
    .map((item) => {
      delete item.id;
      delete item.isChecked;
      return item["day"];
    });

  const newApiData = Object.assign(apiData, { days: newObj });
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/seller/offer/create-offer", newApiData);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};


// ----------------  get offer list -------------------

export const getOfferList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/offer/get-offers-listing");
      resolve(res.data);
      dispatch(getOffers(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------  get notification list -------------------

export const getNotificationList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/notification/all");
      // console.log(res.data.data,"=======")
      let count = res.data?.data?.notifications?.filter(item => item.isSeen === false).length || 0;
      let notificationData = res.data?.data?.notifications
      dispatch(getNotification({count,notificationData}));
    } catch (error) {
      reject(error);
    }
  });
};
// ----------------  mark-as-seen notification  -------------------

export const markAsSeenNotification = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/notification/mark-as-seen");
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------  mark-as-seen notification  -------------------

export const clearAlNotification = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/notification/clear-all");
      resolve(res.data);
      dispatch(clearNotification())
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------- single offer detail------------------------
export const singleOfferDetails = (offerId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/seller/offer/get-offers-details/${offerId}`);
      resolve(res.data);
      dispatch(getOffer(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

//----------update single offer---------------
export const updateOffer = (offerId, apiData) => async () => {
  const { days } = apiData;
  const newObj = days
    .filter((item) => item.isChecked === true)
    .map((item) => {
      delete item.id;
      delete item.isChecked;
      return item["day"];
    });
  const newApiData = Object.assign(apiData, { days: newObj });

  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/seller/offer/update-offer/${offerId}`,
        newApiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//---------create support ticket--------------

export const createSupportTicket = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/seller/supportCentre/create-support-ticket",
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};


//---------reply support ticket--------------

export const replyRequestOffer = (id,apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/seller/requestedOffer/reply-seller/${id}`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------  get tickets list -------------------

export const getAllTickets = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/supportCentre/get-support-tickets");
      resolve(res.data);
      dispatch(getTickets(res.data.data.ticketsListing));
    } catch (error) {
      reject(error);
    }
  });
};

export const singleTicketDetails = (ticketId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/seller/supportCentre/support-ticket-details/${ticketId}`
      );
      resolve(res.data);
      dispatch(getTicket(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

// get requested-offers //
export const getAllRequestedOffers = () => async (dispatch) => {
  //  return console.log(apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/requestedOffer/get-request-offer");
      resolve(res.data);
      dispatch(getRequestedOffers(res.data.data.requestsListing));
    } catch (error) {
      reject(error);
    }
  });
};

//--------update request offer status------------

export const statusChange = (requestId) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/v1/admin/seller/requestedOffer/update-request-offer-status/${requestId}`,
        {
          status: "accepted",
        }
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//--------update multiple offer status------------

export const statusChangeMultipleOffers = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `seller/offer/change-multiple-offers-status`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//-----------  get seller details  -------------

export const getSellerDetails = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/seller-details");
      resolve(res.data);
      dispatch(getSellers(res.data.data.user));


    } catch (error) {
      reject(error);
    }
  });
};

export const getSellerUpdate = () => async (dispatch) => {
  try {
    const res = await api.get("/seller/seller-details");
    const userData = res.data.data.user;
    setToLocalStorage("userData",userData)
     dispatch(updateUser({user:userData}));

    return res.data;
  } catch (error) {
    throw error;
  }
};

//--------delete offer-----------

export const deleteSingleOffer = (offerId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/seller/offer/delete-offer/${offerId}`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//--------------pause offer-----------------
export const pauseSingleOffer = (offerId) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`seller/offer/is-pause-offer/${offerId}`, {
        isOfferPaused: true,
      });
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// ----------------- my bussiness details------------------------
export const myBussinessDetails = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("seller/seller-details");
      dispatch(updateUserBussinessDetails(res.data.data.user));
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//----------update business details---------------
export const updateBusinessDetails = (apiData) => async () => {
  const { peak, offPeak,gallery } = apiData;
  const peakData = peak?.map((item) => {
    delete item.isChecked;
    return item;
  });

  const offPeakData = offPeak?.map((item) => {
    delete item.isChecked;
    return item;
  });
  const updatedGalle  = gallery.filter(item => item.trim() !== "");

  const finalData = { ...apiData, peak: peakData, offPeak: offPeakData,gallery:updatedGalle };


  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/update-businessDetails", finalData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//----------get homepage details---------------
export const getHomepageData = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/seller/get-home-details?type=${type}`);
      resolve(res.data);
      dispatch(getHomeData(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

//--------------set popularity-----------------
export const setPopularity = (sellerId, apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`seller/set-popularity/${sellerId}`, apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//----------get all orders---------------
export const getAllOrders =
  ({ offset, limit }) =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(
          `seller/offer/manage-orders?offset=${offset}&limit=${limit}`
        );
        resolve(res.data);
        dispatch(getOrders(res.data.data));
      } catch (error) {
        reject(error);
      }
    });
  };

//----------------edit profile-----------------

export const editProfile = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/seller/update-seller-profile", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//---------------delete multiple offers------------
export const deleteMultipleOffers = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete("/seller/offer/delete-bulk-offer", {
        data: apiData,
      });
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// get all redeemed offer //
export const getAllRedeemed = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/seller/offer/offer-order-list");
      resolve(res.data);
      dispatch(getRedeemedOffers(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

// get single redeemed offer //
export const getSingleRedeemedOffer = (redeemedId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        `/seller/offer/get-redeemed-offer/${redeemedId}`
      );
      resolve(res.data);
      dispatch(getRedeemedOffer(res.data.data.offer));
    } catch (error) {
      reject(error);
      
    }
  });
};
// update redeemed offer quantity //
export const updateRedeemOfferQuantity =
  ({ redeemId, quantity }) =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          `seller/offer/update-redeemed-offer?redeemedId=${redeemId}&quantity=${quantity}`
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

// get graph data //
export const getGraphData = (type,filter) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/seller/get-dashboard-graph-daily?type=${type}&filter=${filter}`
      );
      resolve(res.data);
      dispatch(getGraph(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

// get billing report data //
export const getBillingData = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/seller/billing/get-billing-report`
      );
      resolve(res.data);
      // dispatch(getGraph(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

//customer-suppoer //
export const createCostumerSupport = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/buyer/customerSupport/create-customer-support",
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
}

// ----------------  delete bulk tickets -------------------
export const deleteSupportTickets = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        "/seller/supportCentre/delete-bulk-tickets",
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
}


// ----------------  delete user -------------------
export const deleteUser = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(
        "/seller/delete-seller",
      );
      resolve(res.data);
    
    } catch (error) {
      reject(error);
    }
  });
}

// ----------------  delete user -------------------
export const deleteUserBusinessImg = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(
        `/seller/delete-img?imageIndex=${apiData}`,
      );
      resolve(res.data);
    
    } catch (error) {
      reject(error);
    }
  });
}