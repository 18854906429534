import React, { useEffect, useState } from "react";
import "../../Marchant_plans.css";
import { l_logo } from "../../assets/images/images";
import { getSellerUpdate, subscriptionProPlan, upgradeSubscription } from "../../api/user";
import { getFromLocalStorage } from "../../helpers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Index() {

  const [loading,setLoading] = useState(false)
  const [loading1,setLoading1] = useState(false)
  const [loading2,setLoading2] = useState(false)

  const userData = getFromLocalStorage("userData")
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(()=>{
      if(userData.subscriptionPlan === ""){
          navigate("/choose-how-to-promote")
      }else{
          navigate("/home")
      }
  },[])


  const selectPlan = (type) =>{
      if(type == "Discovery_Plan"){
      setLoading(true)
      }else{
          setLoading1(true)
      }
      const apiData = {
          plan: type
        };
      dispatch(upgradeSubscription(apiData)).then((res) => {
          if (res.statusCode === 200) {
          //   setIsDiscoveryLoadingY(false)
            dispatch(getSellerUpdate()).then((res)=>{
              setLoading(false)
              setLoading1(false)

              if(res.statusCode === 200){
                navigate("/home")
              }
            }).catch((err)=>{
              console.log(err)
            })
          }
        }).catch((err) => {
          console.log(err);
        })
  }

  const selectProPlan = (type) =>{
      setLoading2(true)
      const apiData = {
          plan: type
        };
        dispatch(subscriptionProPlan(apiData)).then((res) => {
          if (res.statusCode === 200) {
          //   setIsDiscoveryProPlanLoading(false)
            dispatch(getSellerUpdate()).then((res)=>{
              setLoading2(false)
              if(res.statusCode === 200){
                navigate("/home")
              }
            }).catch((err)=>{
              console.log(err)
            })
            
          }
        }).catch((err) => {
          console.log(err);
        })
  }

  return (
    <section className="mrh_plans">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              {/* logo */}
              <img src={l_logo} alt="" width="150px" />
            </div>
            <div className="glw_txt">
              <h2>Your Offer, Your Way: Choose How to Promote</h2>
            </div>
          </div>
          {/* plans */}
          <div className="col-md-6">
            <div className="mar_crds">
              <div className="hed">
                <h3>Showcase Your Offers</h3>
                <p>No Selling Required!</p>
              </div>
              <div className="desc">
                <p>
                  Get your business in front of eager customers – completely
                  free! For the first year, you can list unlimited offers with
                  zero fees. It's the perfect way to spotlight your best deals,
                  manage peak and off-peak promotions effortlessly, and attract
                  more foot traffic. Don’t miss the chance to boost your
                  visibility without any cost!
                </p>
              </div>
              <div className="cta">
                <button onClick={()=>selectPlan("Discovery_Plan")}>Get Started Now</button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mar_crds">
              <div className="hed">
                <h3>Start Selling</h3>
                <p>Ready to Grow and Thrive?</p>
              </div>
              <div className="desc">
                <p>
                  Kickstart your sales journey with a full year of free listings
                  on our platform! Seamlessly handle payments with just a small
                  fee of 2.9% + 30p per transaction. Best of all, you’ll have
                  complete control over your offers. It’s your business, your
                  way – from showcasing your best deals to choosing how to
                  promote them. Ready to scale? Let's make it happen together!
                </p>
              </div>
              <div className="cta">
                <button onClick={()=>selectProPlan("Discovery Pro")}>Start Selling Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
