import React from 'react'

const ManageOffers = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2.84158 11.675L8.81658 17.65C8.97137 17.805 9.15519 17.9279 9.35752 18.0118C9.55985 18.0956 9.77673 18.1388 9.99575 18.1388C10.2148 18.1388 10.4317 18.0956 10.634 18.0118C10.8363 17.9279 11.0201 17.805 11.1749 17.65L18.3333 10.5V2.16667H9.99992L2.84158 9.325C2.53117 9.63727 2.35693 10.0597 2.35693 10.5C2.35693 10.9403 2.53117 11.3627 2.84158 11.675V11.675Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.1667 6.33333H14.1584" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default ManageOffers;