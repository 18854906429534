import { Box, Spinner, Text } from "@chakra-ui/react";
import React from "react";

const Index = () => {
  return (
    <Box display={"grid"} placeItems={"center"} h={"100vh"} flexDirection={["column","row"]}>
      <Box w={["85%","60%"]} display={"grid"} placeItems={"center"} gap="40px">
        <Spinner
          thickness="6px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#FF1A57"
          size="xl"
        />
        <Text color="#3B3538" fontSize="23px" fontWeight="600">Please need to wait while your profile is verifies by the admin.</Text>
      </Box>
    </Box>
  );
};

export default Index;
