import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
  Spinner,
  Icon,
  } from '@chakra-ui/react'
  import { CheckCircleIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {  getSellerUpdate } from '../../api/user'
const Success = () => {

    const [isLoading,setIsLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch();
    const navigate = useNavigate()
   
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ''; 
    }

    const handlePopState = (event) => {
        navigate("/success", { replace: true });
    }
    const getUserUpdatedDetails = () =>{
      setIsLoading(true)
      // dispatch(getSellerUpdate()).then((res)=>{
      //   if(res.statusCode === 200){
      //     setIsLoading(false)
      //     navigate("/stripedetail")
      //     onClose()
      //   }
      //   console.log(res)
      // }).catch((err)=>{
      //   console.log(err)
      // })
    }
 
    useEffect(()=>{
        onOpen();
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        }
    }, [])

  return (
    <>
     <Modal isOpen={isOpen} 
     closeOnOverlayClick={false} 
     closeOnEsc={false} 
     >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box display="flex" alignItems="center">
              <Icon as={CheckCircleIcon} w={6} h={6} color="green.500" mr={2} />
              Payment Successful
            </Box>
          </ModalHeader>
          <ModalBody>
            <Text>Your payment has been successfully processed. Thank you for your purchase!</Text>
          </ModalBody>
          <ModalFooter>    
             <Button colorScheme="blue" mr={3} onClick={()=>getUserUpdatedDetails()}>
             {isLoading? <Spinner color='white' /> :"Done"} 
           </Button> 
          </ModalFooter>
        </ModalContent>
      </Modal>
  </>
  )
}

export default Success