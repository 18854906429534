import React from 'react'

const MyBusiness = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 6.33333H3.33341C2.41294 6.33333 1.66675 7.07952 1.66675 8V16.3333C1.66675 17.2538 2.41294 18 3.33341 18H16.6667C17.5872 18 18.3334 17.2538 18.3334 16.3333V8C18.3334 7.07952 17.5872 6.33333 16.6667 6.33333Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3334 18V4.66667C13.3334 4.22464 13.1578 3.80072 12.8453 3.48816C12.5327 3.17559 12.1088 3 11.6667 3H8.33341C7.89139 3 7.46746 3.17559 7.1549 3.48816C6.84234 3.80072 6.66675 4.22464 6.66675 4.66667V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MyBusiness;