import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { singleFileUpload } from "../api/user";
import { useRef } from "react";
import { Box, Image, Input } from "@chakra-ui/react";
import { card_1, card_4, card_5, card_6 } from "../assets/images";
import { icon_15 } from "../assets/icons";
import { useForm } from "react-hook-form";
import uploadFrame from "../assets/images/upload offer image.png"

const ChangeImages = ({ gallery, setGallery }) => {
  const imageRef = useRef();
  const imageOneRef = useRef();
  const imageTwoRef = useRef();
  const [previewOne, setPreviewOne] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewTwo, setPreviewTwo] = useState(null);
  const { register } = useForm();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const img = e.target.files[0];
    setPreview(URL.createObjectURL(img));
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };
// kya hua? ravi sir se baat kr rha tha mujhe yeh bta data offers ka aa kha rha h jisme gallery ka arr aa rha h
  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  //isse oopr vala use hora h bs.. niche vale pe mt dhyaan de
  // const handleImageOne = (e) => {
  //   const image = e.target.files[0];
  //   setPreviewOne(URL.createObjectURL(image));
  //   if (image) {
  //     dispatch(singleFileUpload(image))
  //       .then((res) => {
  //         setGallery([...gallery, res.data.image]);
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // };
  const handleEditOne = (e) => {
    imageOneRef.current.click();
  };
  const handleImageTwo = (e) => {
    const imageOne = e.target.files[0];
    setPreviewTwo(URL.createObjectURL(imageOne));
    if (imageOne) {
      dispatch(singleFileUpload(imageOne))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };
  const handleEditTwo = (e) => {
    imageTwoRef.current.click();
  };

  return (
    <Box>
      <Box
        w={{ xl: "86%", lg: "70%", md: "100%" }}
        // mx={{lg:"0px",md:"auto"}}
        display="flex"
        mt="30px"
        justifyContent="space-between"
        {...register("gallery")}
      >
        <Box w={{lg:"70%",md:"95%"}} mx={{lg:"0px",md:"auto"}} position="relative">
          <Image
            src={preview || uploadFrame}
            width="full"
            height="237px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            top="-10px"
            right="-10px"
            h="41px"
            w="41px"
            onClick={handleButtonClick}
          />
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            ref={imageRef}
            onChange={handleChange}
          ></Input>
        </Box>
        {/* <Box w="28%" position="relative" mt={["3", "0"]}>
          <Image
            src={previewOne || card_4}
            w="full"
            h="110px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            right="-10px"
            top="-10px"
            h="41px"
            w="41px"
            onClick={handleEditOne}
          />
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            ref={imageOneRef}
            onChange={handleImageOne}
          ></Input>
          <Image
            src={previewTwo || card_4}
            w="full"
            h="110px"
            mt="15px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            bottom="80px"
            right="-10px"
            h="41px"
            w="41px"
            onClick={handleEditTwo}
          />
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            ref={imageTwoRef}
            onChange={handleImageTwo}
          ></Input>
        </Box> */}
      </Box>
    </Box>
  );
};

export default ChangeImages;
