import React from "react";
import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, getSellerDetails, singleFileUpload } from "../api/user";
import { icon_10 } from "../assets/icons";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { profilee } from "../assets/images";

const UpdateProfile = () => {
  const [preview, setPreview] = useState(null);
  const imageRef = useRef();
  const { seller } = useSelector((state) => state.seller);

  const {
    register,
    handleSubmit,
    reset,
    control,
formState: { errors },
  } = useForm();

  const [profileImage, setProfileImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const img = e.target.files[0];

    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => {
          setProfileImage(res.data.image);
          setPreview(URL.createObjectURL(img));
        })
        .catch((error) => console.log(error));
    }
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (seller) {
      reset({
        businessName: seller?.name?.businessName,
        contactPerson: seller?.name?.contactPerson,
      });
      setProfileImage(seller.profileImage);
    }
  }, [seller]);

  const handleProfile = (data) => {
    setIsLoading(true);
    const apiData = {
      businessName: data.businessName,
      contactPerson: data.contactPerson,
      profileImage: profileImage,
    };
    dispatch(editProfile(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          setIsLoading(false);
          toast.success(res.message);
          dispatch(getSellerDetails());
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box position="relative" bg="">
          <AspectRatio
            rounded="full"
            w="120px"
            h="120px"
            mt={4}
            cursor="pointer"
            onClick={handleButtonClick}
          >
            <Image
              rounded="full"
              src={ profileImage  || profilee}
              objectFit={"cover"}
            />
          </AspectRatio>
          {/* <input
            type="file"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={handleChange}
          /> */}
          <Box
            pt={2}
            position="absolute"
            bg="#1E0F42"
            top={4}
            right={{lg:1,md:1}}
            w="36px"
            h="36px"
            align="center"
            rounded="full"
            onClick={handleButtonClick}
          >
            <AspectRatio w="22px" h="22px" cursor="pointer">
              <Image src={icon_10} />
            </AspectRatio>
          </Box>
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg"
            ref={imageRef}
            onChange={handleChange}
            bg="white"
          ></Input>
        </Box>
        <Box ml={5}>
          <Text
            fontFamily="Rubik"
            fontStyle="normal"
            fontWeight="600"
            fontSize={["28px", "34px"]}
            lineHeight="40px"
            color="#3B3538"
          >
            {seller?.name?.businessName} 
          </Text>
          <Text
            fontStyle="normal"
            fontWeight="600"
            fontSize="23px"
            lineHeight="28px"
            color="#FF1A57"
          >
            {seller?.role}
          </Text>
        </Box>
      </Box>
      <Box w={{lg:"50%",md:"100%"}} mt={8}>
        <Text
          fontSize="34px"
          fontWeight="600"
          color="#1E0F42"
          fontFamily="Rubik"
        >
          Edit Profile
        </Text>
        <FormControl mt="10px">
          <HStack>
            <Box w="100%">
              <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                Business Name
              </FormLabel>
              <Input
                bg="white"
                shadow="lg"
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="confirmPassword"
                fontSize={{ xl: "14px", md: "12px" }}
                {...register("businessName")}
              />
            </Box>
            <Box w="100%">
              <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                Contact Person
              </FormLabel>
              <Input
                bg="white"
                shadow="lg"
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="confirmPassword"
                fontSize={{ xl: "14px", md: "12px" }}
                {...register("contactPerson")}
              />
            </Box>
          </HStack>
        </FormControl>
        <Box mt={5} w={["95%", "100%"]}>
          <Button
            bg="#FF1A57"
            w="full"
            shadow="lg"

            fontSize="14px"
            fontWeight="600"
            color="#FFFFFF"
            onClick={handleSubmit(handleProfile)}
            isLoading={isLoading}
          >
            Confirm changes
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UpdateProfile;
