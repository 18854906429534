import SecureLS from "secure-ls";

let ls = new SecureLS();

export function setToLocalStorage(key, data) {
    ls.set(key, data);
}

export function getFromLocalStorage(key) {
    try {
        let getData = ls.get(key);
        return getData;
    } catch (error) {
        console.error(`Error retrieving key "${key}" from localStorage:`, error);
        return null; // or handle error appropriately
    }
}

export function removeFromLocalStorage(key) {
    ls.remove(key);
}

export function removeAllFromLocalStorage() {
    ls.removeAll();
}

export const convertFileIntoUrl = async (fileObj, setState, type, dataState, id) => {
    let URL = window.URL || window.webkitURL;
    if (type === 'video') {
        let urlBlob = URL.createObjectURL(fileObj);
        let urlArr = [...dataState];
        urlArr.push({ url: urlBlob, id: id });
        setState(urlArr);
    } else {
        let urlBlob = await URL.createObjectURL(fileObj);
        setState(urlBlob);
    }
};
