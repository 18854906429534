import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
// import { getOfferList } from "../api/user";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(url).then((res) => {
      setIsLoading(false);
      setData(res);
    });
  }, []);
  return { data, isLoading };
};

export default useFetch;
