import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
  Stack,
  Hide,
  StatNumber,
  Stat,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import avatar from "../../assets/images/profilee.png";

const TableHead = [
  { name: "Offer Name" },
  { name: "Status" },
  { name: "Quantity" },
  { name: "Sold" },
  { name: "redeemed" },
  { name: "price" },
  { name: "sales" },
  { name: "created" },
  { name: "action" },
];

function Items({ currentItems, handleChange, handleId }) {
  // console.log(currentItems, "currentItems");
  return (
    <>
      <Box mt="2%" w={{ lg: "98%", md: "95%" }} mx={{ lg: "none", md: "auto" }}>
        <TableContainer rounded="md" class="table-responsive">
          {currentItems?.length > 0 ?
          
        (  <Table size={{ xl: "xs", lg: "xs", md: "xs" }}>
            <Thead bg="gray.100">
              <Tr>
                <Th>
                  <Checkbox
                    p={{ lg: 4, md: 3 }}
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter((user) => user?.isChecked !== true)
                        .length < 1 || false
                    }
                    name="allChecked"
                    onChange={handleChange}
                  ></Checkbox>
                </Th>
                {TableHead.map((value) => (
                  <Th key={value.name} alignItems={"center"} pl={1}>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "9.5px" }}
                      whiteSpace="nowrap"
                    >
                      {value.name}
                    </Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {currentItems?.map((value) => (
                <Tr key={value._id}>
                  <Td p={{ lg: 4, md: 3 }}>
                    <Checkbox
                      colorScheme="red"
                      isChecked={value.isChecked || false}
                      name={value._id}
                      onChange={(e) => handleChange(e, value)}
                    />
                  </Td>
                  <Td>
                    <Flex
                      gap={{ lg: "2", md: "1" }}
                      w="full"
                      alignItems={"center"}
                    >
                      <Image
                        mt={{ lg: "0", md: "2" }}
                        borderRadius="full"
                        boxSize="40px"
                        w={{ xl: "32px", lg: "30px", md: "30px" }}
                        h={{ xl: "32px", lg: "30px", md: "30px" }}
                        src={value?.gallery[0] || avatar}
                        // alt="Dan Abramov"
                      />
                      <Box>
                        <Text
                          fontFamily="Commissioner"
                          fontWeight={700}
                          fontSize={{ xl: "14px", lg: "13px", md: "13px" }}
                          color="#3B3538"
                          mb={{ xl: 0.5, md: -0.5 }}
                          whiteSpace="break-spaces"
                        >
                          {value?.offerName}
                        </Text>
                        <Text
                          fontWeight={600}
                          color={" #7B6F6F"}
                          fontSize={{ xl: "12px", lg: "11px", md: "11px" }}
                          whiteSpace="break-spaces"
                          display={{ xl: "block", lg: "block", md: "none" }}
                        >
                          {value.sellerDetails.name.businessName}|
                          {value.sellerDetails.address.address.slice(0, 45)}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td w={{ xl: 100, lg: 65 }}>
                    <Box
                      bg={
                        value.status === "Sold"
                          ? "#CCC9C7"
                          : value.status === "Suspended"
                          ? "#FFA3AC"
                          : value.status === "active"
                          ? "#B5D779"
                          : value.status === "Paused"
                          ? "#FDE68B"
                          : "orange"
                      }
                      borderRadius="20px"
                      p={{ xl: 2, lg: 0.5, md: 1 }}
                    >
                      <Text
                        fontSize={{ md: "12px", xl: "14px" }}
                        textAlign="center"
                        fontWeight={{ xl: 600, lg: 600, md: 600 }}
                      >
                        {value.status}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Text
                      fontWeight={600}
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      textAlign={"center"}
                    >
                      {value.totalQuantity}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      textAlign={"center"}
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      fontWeight={600}
                    >
                      {value.sales.sold}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      textAlign={"center"}
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      fontWeight={600}
                    >
                      {value.sales.redeemed}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      textAlign={"center"}
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      fontWeight={600}
                    >
                      £ {value?.price?.discountedPrice}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      textAlign={"center"}
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      fontWeight={600}
                    >
                     £ {(value.sales.sold) * (value?.price?.discountedPrice)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      textAlign="center"
                      fontSize={{ xl: "14px", md: "12px" }}
                      lineHeight="21px"
                      color="#7B6F6F"
                      fontWeight={600}
                    >
                      {value.time}
                      {moment(value.createdAt).format("ll")}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      p={{ xl: 5, md: 3 }}
                      colorScheme="white"
                      color="#FF1A57"
                      border="1px solid #FF1A57"
                      h={{ xl: "25px", md: "14px" }}
                      fontSize="xs"
                      fontWeight={600}
                      borderRadius="5px"
                      textAlign="center"
                      onClick={() => handleId(value._id)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>)
     : <Box display="flex" justifyContent={"center"} alignItems={"center"} height={"50vh"}>
      <Text fontFamily={"bold"}  fontSize={"25px"}> No Data Found </Text> 
     </Box>}
        </TableContainer>
      </Box>
    </>
  );
}

function OfferTable({ itemsPerPage, items, handleChange, handleId }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
      />
      <Box
        bg="white"
        w={{ lg: "98%", md: "93%" }}
        mx={{ lg: "auto", md: "auto" }}
        ml={{ lg: "0%", md: "5%" }}
      >
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default OfferTable;
