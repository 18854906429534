import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSupportTicket } from '../../api/user';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Navbar from '../../components/Navbar';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { newTicketValidator } from '../../helpers/validation';

const Index = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newTicketValidator),
  });

  const dispatch = useDispatch()
  const onSubmit = (apiData)=>{
    dispatch(createSupportTicket(apiData)).then((res)=>{
      toast.success(res?.message)
      navigate('/support-centre')
    }).catch((err)=>{
      toast.error(err?.message)
    })  
  }
  return (
    <Layout>
      <Box  w={{xl:"98%",lg:"100%",md:"96%"}} mx="auto" h="100vh" >
      {/* <Box display="flex" justifyContent="space-between" w={{md:"90%"}}>
        <Text
          fontFamily="Rubik"
          color="#2E1A47"
          fontSize={{xl:"34px",md:"30px"}}
          fontWeight="600"
          lineHeight="40px">
          New Ticket
        </Text>
      <Header/>
      </Box> */}
      <Navbar />
      <Box>
        <Box w={{lg:'60%',md:"90%"}} mt="20px">
          <FormControl isRequired>
          <FormLabel color="#6B6162" fontSize="14px" fontWeight="400px">
            Issue
          </FormLabel>
          <Input
           bg="white"
           shadow="lg"
            type="text"
            placeholder="Enter issue"
            color="#4B4447"
            fontSize="14px"
            fontWeight="400px"
            {...register("issue")}
            >
            </Input>
            <Text my={1} color="red" fontSize="xs">
                {errors.issue?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
          <FormLabel
            mt="10px"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400px">
            Message
          </FormLabel>
          <Textarea
           bg="white"
           shadow="lg"
            minHeight="250px"
            color="#4B4447"
            fontSize="14px"
            fontWeight="400px"
            {...register("message")}
            placeholder="Enter message"
          />
          <Text my={1} color="red" fontSize="xs">
                {errors.message?.message}
              </Text>
          </FormControl>
        </Box>
      </Box>
      <Box flexDirection={['column', 'row']}>
        <Box w={{lg:"60%",md:"90%"}} mt="20px">
          <Button
            color="white"
            bg="#FF1A57"
            w="full"
            fontSize={{lg:"18px",md:"14px"}}
            fontWeight={{lg:"600px",md:"800px"}}
            onClick ={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </Box>
      </Box>
      </Box>
      </Layout>
  );
};

export default Index;
