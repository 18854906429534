import { Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Hide, Image, Input, Text } from '@chakra-ui/react'
import React from 'react'
import { frame, icon, icon_9 } from '../../assets/icons'
import { DATA } from '../../helpers/menu'
import NavItem from '../NavItem'
import { removeAllFromLocalStorage } from '../../helpers'
import { useNavigate } from 'react-router-dom'

const SidebarDrawer = ({ isOpen, onClose, btnRef,
    navSize }) => {
    const navigate = useNavigate();

  const handleLogout = () => {
    removeAllFromLocalStorage();
    navigate("/login");
  };

  const handleNewOffer = () => {
    navigate("/new-offer");
  };
    return (
        <Box>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={{ base: "xs" }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody >
                        <Flex
                            // w={{ md: "23%", lg: "13%" }}
                            shadow="xl"
                            bg="white"
                            pos={{ lg: "fixed", md: "fixed" }}
                            h={{ md: "95%" }}
                            boxShadow={{ lg: "0 1px 1px 0 rgba(0,0,0,0.5)", md: "" }}
                            flexDirection={{  md: "column" }}
                            bottom={0}
                            top={0}
                            justifyContent={["space-between"]}
                        >
                            <Box mt={{ xl: 4 }}>
                                <Box mt={{ lg: "0px", md: "10px" }}>
                                    <Hide below="sm" >
                                        <Box display="flex" w="80%" mx="auto" >
                                            <Image src={frame} />
                                        </Box>
                                        <Center>
                                            <Text fontSize={{ lg: "xs", md: "sm" }}>Merchant Dashboard V1.0</Text>
                                        </Center>
                                    </Hide>
                                </Box>
                                <Center>
                                    <Button

                                        bg="#1E0F42"
                                        w={{ lg: "85%", md: "90%", sm: "50%" }}
                                        mt={{ lg: 5, md: 5, sm: 0 }}
                                        mb={{ lg: 0, md: 0, sm: 3 }}
                                        p={{ lg: 4, md: 4, sm: 8 }}
                                        h={{ xl: 50, md: 45, sm: 55 }}
                                        onClick={handleNewOffer}
                                    >
                                        <Image src={icon} w="20px" h="20px" color="#1E0F42" />
                                        <Hide below="sm">
                                            <Text ml={{ lg: "8px", md: "10px", sm: "8px" }} color="white">
                                                New Offer
                                            </Text>
                                        </Hide>
                                    </Button>
                                </Center>
                            </Box>

                            <Flex
                                mt={{ md: "-360px", lg: "2px" }}
                                flexDirection="column"
                                as="nav"
                            >
                                {DATA.map((value, index) => (
                                    <NavItem
                                        key={index}
                                        navSize={navSize}
                                        icon={value.img}
                                        title={value.heading}
                                        path={value.Path}
                                    />
                                ))}
                            </Flex>
                            <Flex
                                flexDirection="column"
                                w="100%"
                            >
                                <Flex align="center">
                                    <Button variant="ghost" w="full" onClick={handleLogout}>
                                        <Image src={icon_9} />
                                        <Flex ml={4} display={["none", "none", "block", "block"]}>
                                            <Text fontSize={{ lg: "sm", md: "md" }} color="#FF1A57">
                                                Logout
                                            </Text>
                                        </Flex>
                                    </Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default SidebarDrawer