import { Box, Show, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import NewSideBar from "./NewSideBar";
import { Hide } from '@chakra-ui/react';
import { BsBell } from "react-icons/bs";

const Layout = ({ children }) => {
  const [navSize, changeNavSize] = useState("large");

  return (
    <Box w="100%" h={{lg:"100vh",md:"100vh"}}  display="flex">
      <Hide below="lg">
      <Box w={{xl:'12%', md:"80px" }}>
      <NewSideBar navSize={navSize} changeNavSize={changeNavSize}/>
      </Box>
      </Hide>

      <Box position={"relative"} w="full" ml={{xl:'2%', sm:"5%"}} mx={{lg:"",md:"",sm:"auto"}} p={4} bg="#F8F8F8">
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
