import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import { rowes } from "../assets/images";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useDispatch } from "react-redux";


const TableItem = ({ currentItems, handleChange, handleId, type }) => {

  return (
    <Box mt="2%" w={{xl:"100%",lg:"98%",md:"97%"}} mx={{xl:"auto",lg:"none",md:"auto"}}>
      <TableContainer rounded="md" border="1px" borderColor="gray.300">
        <Table size={{"2xl":"xs" ,xl:"xs", lg: "xs", md: "xs" }}>
       
            <Thead bg="gray.100">
              <Tr>
                {/* <Th p={4}>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter(
                        (ticket) => ticket?.isChecked !== true
                      ).length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                  ></Checkbox>
                </Th> */}
                <Th>
                  <Text fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}} fontWeight="600" color="#6B6162" mx={4} my={2}>
                    Buyer Name
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    Offer Name
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    Seller Payout
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    Offer Sold
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    OFFERS REDEEMED
                  </Text>
                </Th>
                <Th>
                  <Text
                   fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    OFFER EXPIRED
                  </Text>
                </Th>
                <Th>
                  <Text
                   fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    OFFERS SOLD(£)
                  </Text>
                </Th>
                <Th>
                  <Text
                  fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                  fontWeight="600"
                  color="#6B6162"
                  textAlign="center" mx={4} my={2}
                  >OFFERS REDEEMED (£)</Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    OFFERS EXPIRED (£)
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    PHF (OFFERS SOLD)
                  </Text>
                </Th>
                <Th>
                  <Text
                   fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    PHF (REDEEMED OFFERS)
                  </Text>
                </Th>
                <Th>
                  <Text
                   fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162"
                    textAlign="center" mx={4} my={2}
                  >
                    	
                 VAT (PHF REDEEMED OFFERS)
                  </Text>
                </Th>
                <Th>
                  <Text
                   fontSize={{"2xl":"12px",xl:"12px",lg:"12px",md:"10px"}}
                    fontWeight="600"
                    color="#6B6162" 
                    textAlign="center" mx={4} my={2}
                  >
                    REFUNDS
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody bg="white" w="auto">
              {currentItems?.map((value) => (
                <Tr key={value._id} my={4} >
                  {/* <Td p={4} mt={4}>
                    <Checkbox
                      isChecked={value.isChecked || false}
                      colorScheme="red"
                      // name={value._id}
                      // onChange={handleChange}
                    />
                  </Td> */}
                  <Td px={4} py={2}>
                        <Text fontSize="14px"fontFamily="Commissioner"textAlign="center">
                          {value?.buyerName}
                        </Text>
                  </Td>

                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {value?.offerName}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {(value?.sellerPayout.toFixed(2))}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {value?.offerSold}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {value?.offersRedeemed}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" textAlign="center">
                      {value?.offersExpired}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {(value?.offerSold * value?.offerDetails?.price?.discountedPrice).toFixed(2)}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                    {(value?.offersRedeemed * value?.offerDetails?.price?.discountedPrice).toFixed(2)}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                      {(value?.offersExpired).toFixed(2)}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                    00
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                     00
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                    {((value.sellerPayout*20)/100).toFixed(2)}
                    </Text>
                  </Td>
                  <Td px={4} py={2}>
                    <Text fontSize="14px" textAlign="center">
                  --
                    </Text>
                  </Td>
                  
                </Tr>
              ))}
            </Tbody>
          
        </Table>
      </TableContainer>
    </Box>
  );
};

const BillingTable = ({
  itemsPerPage,
  items,
  handleChange,
  handleId,
  type,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableItem
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
        type={type}
      />
      <Box bg="white" >
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
};

export default BillingTable;
