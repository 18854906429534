import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 categories:[],
 subCategories:[]
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    getSubCategories : (state,action) => {
      state.subCategories = action.payload
    }
  },
});

export const { getCategories,getSubCategories } = categorySlice.actions;

export default categorySlice.reducer;
