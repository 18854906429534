import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getFromLocalStorage } from "../helpers";
import { useDispatch } from "react-redux";
import { getNotificationList } from "../api/user";

const PrivateRoutes = () => {
  const token = getFromLocalStorage("token");
  const user = getFromLocalStorage("userData");
  const dispatch = useDispatch()
  useEffect(()=>{
    // dispatch(getNotificationList())
    console.log("first")
  },[])
  return user || Object.keys(getFromLocalStorage("userData")).length ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
