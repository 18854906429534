import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { stripeDetailsValidator } from '../../helpers/validation';
import { Spinner, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { updateUserStripe } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar';
import { updatedLogo } from '../../assets/icons';

const StripeDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading,setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(stripeDetailsValidator),
  });

  const updateSellerStripeDetails = (data) => {
    setLoading(true)
    const [year, month, day] = data.dob.split('-');
    let accountNumber = String(data.accountNumber)
    let apiData = {...data};
    apiData.dob = `${day}/${month}/${year}`
    apiData.accountNumber = accountNumber
    dispatch(updateUserStripe(apiData)).then((res)=>{
      setLoading(false)
      if(res.statusCode === 200){
        navigate("/home")
      }
      // console.log(res);
    }).catch((err)=>{
       if (err.data == "Country UK not supported (you should use the 2-letter country code, e.g. US).") {
        toast.error("The country code for the United Kingdom has been updated from UK to GB. Please use GB.")
      } 
      else{
        toast.error(err.data)
      }
      setLoading(false)
    })
    
  }
  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }
  return (
    <>
    {/* <Navbar/> */}

    <div className='min-vh-100 stripe_div'>
      
      <div className='container stripe_card '>
        <div className="m-auto" style={{width:130}}>
          <img src={updatedLogo} style={{height:90,width:130}} alt="" />
        </div>
        <h4 className='text-center font-bold mt-3'> Please Enter Your Detail</h4>
        <form className='stripe_form'>
          <div className='row mt-4'>
            <div class="mb-3 col-6">
              <label for="exampleInputEmail1" class="form-label"> First Name</label>
              <input type="name" {...register("name")} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              onKeyDown={handleSpacebarPress} 
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.name?.message}
          </Text>
            </div>
            <div class="mb-3  col-6">
              <label for="exampleInputPassword1" class="form-label"> Last Name </label>
              <input type="name" {...register("surName")}  class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}/>
              <Text my={1} color="red" fontSize="xs">
            {errors.surName?.message}
          </Text>
            </div>
            <div class="mb-3  col-12 ">
              <label for="exampleInputPassword1" class="form-label"> Date Of Birth </label>
              <input type="date" class="form-control" {...register("dob")} id="exampleInputPassword1" 
               max={new Date(Date.now() - 568024668000).toISOString().split('T')[0]} 
               />
              <Text my={1} color="red" fontSize="xs">
            {errors.dob?.message}
          </Text>
            </div>

            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> Account Number </label>
              <input type="number" {...register("accountNumber")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.accountNumber?.message}
          </Text>
            </div>

            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> Routing Number </label>
              <input type="number" {...register("routingNumber")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress} />
              <Text my={1} color="red" fontSize="xs">
            {errors.routingNumber?.message}
          </Text>
            </div>

            <div class="mb-3  col-12 ">
              <label for="exampleInputPassword1" class="form-label"> Business Url </label>
              <input type="url" {...register("businessUrl")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.businessUrl?.message}
          </Text>
            </div>

            <div class="mb-3  col-12 ">
              <label for="exampleInputPassword1" class="form-label"> Address 1 </label>
              <input type="text" {...register("line1")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.line1?.message}
          </Text>
            </div>

            <div class="mb-3  col-12 ">
              <label for="exampleInputPassword1" class="form-label"> Address 2 </label>
              <input type="text" {...register("line2")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.line2?.message}
          </Text>
            </div>


            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> Postal Code </label>
              <input type="text" {...register("postalCode")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.postalCode?.message}
          </Text>
            </div>

            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> State </label>
              <input type="name" {...register("state")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.state?.message}
          </Text>
            </div>
            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> City </label>
              <input type="name" {...register("city")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.city?.message}
          </Text>
            </div>
            <div class="mb-3  col-6 ">
              <label for="exampleInputPassword1" class="form-label"> Currency </label>
              <input type="text" {...register("currency")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.currency?.message}
          </Text>
            </div>
            <div class="mb-3  col-12 ">
              <label for="exampleInputPassword1" class="form-label"> Country  </label>
              <input type="name" {...register("country")} class="form-control" id="exampleInputPassword1"
              onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
            {errors.country?.message}
          </Text>
            </div>
          </div>
          <div className='text-center ' style={{ color: "#FF1A57" }}>
            <button class="btn btn-primary btn_primary mx-auto"
             style={{ backgroundColor: "#FF1A57", borderColor: "#FF1A57" }}onClick={handleSubmit(updateSellerStripeDetails)}
              >
                {loading? <Spinner color='white' /> :"Submit"}
                </button>

          </div>

        </form>

      </div>

    </div>

    </>

  )
}

export default StripeDetail