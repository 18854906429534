import axios from 'axios';

// helpers
import { getFromLocalStorage, removeAllFromLocalStorage } from '../helpers';

const baseURL = process.env.REACT_APP_BASE_URL;

export const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
/**
 * handle config for get/post
 */
const handleConfig = (config) => {
  const token = getFromLocalStorage('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};
// handle request interceptor 
instance.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error),
);

// logout on getting status 401 from any api
export const logout = () => {
  removeAllFromLocalStorage();
  window.location.replace('/login');
};

// handle response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  // function (error) {
  //   if (error.response.status === 401 || error.response.status === 400 ) {
  //     logout();
  //   } else {
  //     return Promise.reject(error.response);
  //   }
  // },
  function (error) {
    const status = error.response.status;
  
    if (status === 401) {
      logout(); // Logout on unauthorized access
    } else if (status === 400) {
      // Check if the error message indicates a specific condition
      if (error.response.data && error.response.data.message && 
          error.response.data.message.includes("You have already Purchased your plan")) {
        window.location.href = '/login'; // Redirect to login if specific condition is met
      }
  
      // Handle other bad request errors
      return Promise.reject(error.response); 
    } else {
      return Promise.reject(error.response); // Handle all other errors
    }
  }
  
  
);
export default instance;