import {Button, Flex, Text} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

const NavItem = ({navSize, title, icon, active, path,changeNavSize}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate()
  
  return (
      <Flex
      onClick={() => navigate(path)}
        mb={2}
        cursor="pointer"
        flexDirection="row"
        justifyContent={navSize === 'small' && "medium" || window.innerWidth < 1024 && 'left'}
        w="100%"
        textColor="white"
        bg={pathname === path && "#FF1A57"}
        _hover={{
          bg: '#FF1A57',
          color: 'white',
      
        }}
        color={pathname === path ? 'white' : '#7B6F6F'}
        // alignItems={'center'}
        p={{xl:2.5,lg:3,md:3}}
        
        >
        {/* <Image src={icon} h="20px" color={active ? '#FF1A57' : '#7B6F6F'} /> */}
        {icon}
        <Text
          display={navSize === 'small' || window.innerWidth < 1024 ? 'flex' : 'flex'}
          color={pathname === path ? 'white' : '#7B6F6F'}
          _hover={{
            color:'white'
          }}
          fontSize={{xl:"14px",lg:"14px",md:"15px"}}
          fontWeight={600}
          ml={4}>
          {title}
          
        </Text>

      
      </Flex>
  );
};

export default NavItem
