import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
  Stack,
  Hide,
  StatNumber,
  Stat,
} from '@chakra-ui/react';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';

function Items({currentItems, handleChange, handleId}) {
  return (
    <>
      <Box mt="2%" w={{lg:"full",md:"97%"}} ml={{md:"8px",lg:"0px"}} >
        <TableContainer rounded="md">
          <Table size="xs">
            <Thead bg="gray.100">
              <Tr>
                <Th>
                  <Checkbox
                    p={{xl: 4, md: 3}}
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter(user => user?.isChecked !== true)
                        .length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                    ></Checkbox>
                </Th>
                <Th>
                  <Text fontSize={{xl: '12px', md: '11px'}}>Ticket Name</Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{xl: '12px', md: '11px'}}
                    textAlign={'center'}>
                    STATUS
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{xl: '12px', md: '11px'}}
                    textAlign={'center'}>
                    Message
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{xl: '12px', md: '11px'}}
                    textAlign={'center'}>
                    Sent
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{xl: '12px', md: '11px'}}
                    textAlign={'center'}>
                    last update
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontSize={{xl: '12px', md: '11px'}}
                    textAlign={'center'}></Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems?.map(value => (
                <Tr key={value._id}>
                  <Td py={4} mt={4}>
                    <Checkbox
                      p={{xl: 4, md: 3}}
                      colorScheme="red"
                      isChecked={value.isChecked || false}
                      name={value._id}
                      onChange={e => handleChange(e, value)}
                    />
                  </Td>
                  <Td p={3}>
                    <Flex>
                      <Text
                        fontWeight={600}
                        fontSize={{xl: 14, md: 12}}
                        whiteSpace="break-spaces">
                        {value?.supportTicketId?.issue}
                        <br />
                        <Text
                          fontSize={{xl: '12px', md: '11px'}}
                          fontWeight={400}
                          color="#7B6F6F">
                          {value?.supportTicketId?.ticketId}
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td textAlign={'center'}>
                    <Box
                      bg={
                        value?.status === 'Sold'
                          ? '#CCC9C7'
                          : value?.status === 'Suspended'
                          ? '#FFA3AC'
                          : value?.status === 'active'
                          ? '#B5D779'
                          : value?.status === 'Paused'
                          ? '#FDE68B'
                          : 'orange'
                      }
                      fontSize={{xl: '14px', md: '12px'}}
                      borderRadius="20px"
                      p={{xl: 1, md: 1}}>
                      <Text textAlign="center" fontWeight={600}>
                        {value?.supportTicketId?.status}
                      </Text>
                    </Box>
                  </Td>
                  <Td textAlign={'center'}>
                    <Text fontSize="xs" fontWeight={600}>
                      {value?.supportTicketId?.messageCount}
                    </Text>
                  </Td>
                  <Td textAlign={'center'}>
                    <Text fontSize="xs" fontWeight={600}>
                      {moment(value?.supportTicketId?.firstSent).format('lll')}
                    </Text>
                  </Td>

                  <Td textAlign={'center'}>
                    <Text fontSize="xs" fontWeight={600}>
                      {moment(value?.supportTicketId?.updatedAt).format('lll')}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="white"
                      color="#FF1A57"
                      isDisabled={value?.supportTicketId?.status === "pending"}
                      border="1px"
                      borderColor="#FF1A57"
                      fontSize={{xl: '12px', md: '11px'}}
                      borderRadius="5px"
                      onClick={() => {
                        handleId(value, value?.roomId);
                      }}>
                      Reply
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function TicektsTable({itemsPerPage, items, handleChange, handleId}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
      />
      <Box bg="white" w="98%" mx="auto">
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default TicektsTable;
