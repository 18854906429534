import React from 'react'

const Subscriptions = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8019_16778)">
    <path d="M10 0.5C15.5 0.5 20 5 20 10.5C20 16 15.5 20.5 10 20.5C4.5 20.5 0 16 0 10.5C0 5 4.5 0.5 10 0.5ZM10 18.8333C14.5833 18.8333 18.3333 15.0833 18.3333 10.5C18.3333 5.91667 14.5833 2.16667 10 2.16667C5.41667 2.16667 1.66667 5.91667 1.66667 10.5C1.66667 15.0833 5.41667 18.8333 10 18.8333Z" fill="currentColor"/>
    <path d="M9.99992 9.66667C11.5833 9.66667 12.9166 10.6667 13.2499 12C13.3333 12.1667 13.3333 12.3333 13.3333 12.5833C13.3333 14.1667 11.8333 15.5 9.99992 15.5C8.16659 15.5 6.66659 14.1667 6.66659 12.5833C6.66659 12.3333 6.66659 12.1667 6.74992 12C6.83325 11.5833 7.33325 11.25 7.74992 11.4167C8.16659 11.5 8.49992 12 8.33325 12.4167C8.33325 12.5 8.33325 12.5833 8.33325 12.6667C8.33325 13.25 9.08325 13.8333 9.99992 13.8333C10.9166 13.8333 11.6666 13.25 11.6666 12.5833C11.6666 12.5 11.6666 12.4167 11.6666 12.3333C11.4999 11.75 10.8333 11.3333 9.99992 11.3333C9.49992 11.3333 9.16659 11 9.16659 10.5C9.16659 10 9.49992 9.66667 9.99992 9.66667Z" fill="currentColor"/>
    <path d="M9.99992 5.5C11.8333 5.5 13.3333 6.83333 13.3333 8.41667C13.3333 8.66667 13.3333 8.83333 13.2499 9C13.1666 9.41667 12.6666 9.75 12.2499 9.58333C11.8333 9.5 11.4999 9 11.6666 8.58333C11.6666 8.5 11.6666 8.41667 11.6666 8.33333C11.6666 7.66667 10.9166 7.08333 9.99992 7.08333C9.08325 7.08333 8.33325 7.66667 8.33325 8.33333C8.33325 8.41667 8.33325 8.5 8.33325 8.58333C8.49992 9.16667 9.16659 9.58333 9.99992 9.58333C10.4999 9.58333 10.8333 9.91667 10.8333 10.4167C10.8333 10.9167 10.4999 11.25 9.99992 11.25C8.41659 11.25 7.08325 10.25 6.74992 8.91667C6.66659 8.83333 6.66659 8.66667 6.66659 8.41667C6.66659 6.83333 8.16659 5.5 9.99992 5.5Z" fill="currentColor"/>
    <path d="M9.99992 3.83334C10.4999 3.83334 10.8333 4.16667 10.8333 4.66667V6.33334C10.8333 6.83334 10.4999 7.16667 9.99992 7.16667C9.49992 7.16667 9.16659 6.83334 9.16659 6.33334V4.66667C9.16659 4.16667 9.49992 3.83334 9.99992 3.83334Z" fill="currentColor"/>
    <path d="M9.99992 13.8333C10.4999 13.8333 10.8333 14.1667 10.8333 14.6667V16.3333C10.8333 16.8333 10.4999 17.1667 9.99992 17.1667C9.49992 17.1667 9.16659 16.8333 9.16659 16.3333V14.6667C9.16659 14.1667 9.49992 13.8333 9.99992 13.8333Z" fill="currentColor"/>
    <path d="M7.49992 11.3333C7.74992 11.3333 7.91658 11.4167 8.08325 11.5833C8.24992 11.75 8.33325 11.9167 8.33325 12.1667C8.33325 12.4167 8.24992 12.5833 8.08325 12.75C7.74992 13.0833 7.24992 13.0833 6.91659 12.75C6.74992 12.5833 6.66658 12.4167 6.66658 12.1667C6.66658 11.9167 6.74992 11.75 6.91659 11.5833C7.08325 11.4167 7.24992 11.3333 7.49992 11.3333Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_8019_16778">
    <rect width="20" height="20" fill="currentColor" transform="translate(20 20.5) rotate(-180)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default Subscriptions;