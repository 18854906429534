import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const TopRow = ({ setPopularityData,setType ,type,loading,setLoading}) => {
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);

  


  const handleChange = (e) => {
    setLoading(true); 
    const selectedValue = e.target.value;
    setType(selectedValue);
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  };

  const { seller } = useSelector((state) => state.seller);

  return (
    <Box
      display={{ md: "", lg: "flex" }}
      justifyContent="space-between"
      gap={5}
      mt={5}
    >
      <Box
        w={{ xl: "100%" }}
        display="flex"
        justifyContent={{ sm: "space-around", lg: "flex-start",md:"space-evenly" }}
        gap={{ xl: 4, lg: 3, md: 3 }}
      >
        <Button
        w={{lg:"10%",md:"32%"}}
          bg="white"
          border="1px"
          fontSize="sm"
          borderColor="#1E0F42"
          _focus={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          isActive={seller.popularity === "peak"}
          _active={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          onClick={() => setPopularityData("peak")}
        >
          Peak
        </Button>
        <Button
         w={{lg:"13%",md:"32%"}}
          bg="white"
          border="1px"
          fontSize="sm"
          borderColor="#1E0F42"
          isActive={seller.popularity === "offPeak"}
          _active={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          _focus={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          onClick={() => setPopularityData("offPeak")}
        >
          Off Peak
        </Button>
        <Button
         w={{lg:"13%",md:"32%"}}
          bg="white"
          border="1px"
          fontSize="sm"
          borderColor="#1E0F42"
          isActive={seller.popularity === "anyTime"}
          _active={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          _focus={{
            bg: "#1E0F42",
            color: "white",
            border: "none",
          }}
          onClick={() => setPopularityData("anyTime")}
        >
          Any Time
        </Button>
      </Box>
      <Box
        w={{ xl: "20%", lg: "12%", md: "auto" }}
        my={{ sm: 5, lg: 0 }}
        shadow={{ md: "lg", sm: "lg" }}
      >
        <Select
          fontSize={{ xl: "13px", lg: "11px", md: "13px" }}
          fontWeight={"600"}
          // placeholder="Select Duration"
          value={type}
          bg="white"
          onChange={handleChange}
        >
          <option value={"today"}>Today</option>
          <option value={"thisWeek"}>This Week</option>
          <option value={"thisMonth"}>This Month</option>
          <option value={"thisYear"}>This Year</option>
        </Select>
      </Box>
      <Box w={{ xl: "30%", lg: "12%", md: "100%" }} bg="white" shadow={{md:"lg"}}>
        <RangeDatepicker
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
        />
      </Box>
    </Box>
  );
};

export default TopRow;
