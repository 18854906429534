import {
  Box,
  Text,
  Image,
  Select,
  Flex,
  Button,
  Input,
} from "@chakra-ui/react";

import React, { useEffect, useRef, useState } from "react";
import { attach, bg1 } from "../../assets/images";
import { useLocation, useParams } from "react-router-dom";
import { singleFileUpload, singleTicketDetails } from "../../api/user";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import socketIO from "socket.io-client";
import moment from "moment";
import _ from "lodash";
import { getFromLocalStorage } from "../../helpers";
const token = getFromLocalStorage("token");

const socket = socketIO.connect(`https://api.primooffers.com?token=${token}`);
const SupportTicket = () => {
  const params = useParams();
  const { state } = useLocation();
  const profileImageRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage, setprofileImage] = useState("");
  const imageRef = useRef();
  const { ticket } = useSelector((state) => state.tickets);
  const { token } = useSelector((state) => state.authUser);
  const [messages, setMessages] = useState([]);
  const [writemsg, setWritemsg] = useState("");

  const ticketId = params.id;
  const adminId = ticket?.adminDetails?._id;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singleTicketDetails(ticketId));
    if (token) {
      socket.emit("enterARoom", { room: state.roomId });
    }
  }, [token, socket]);

  useEffect(() => {
    getAllMessages();
  }, [messages]);

  const getAllMessages = () => {
    socket.on("allmessages", (data) => {
      const sortedArray = _.orderBy(
        data.messages.messages,
        [(obj) => new Date(obj.createdAt)],
        ["asc"]
      );
      setMessages(sortedArray);
    });
  };

  useEffect(() => {
    socket.on("receivedMessage", (data) => {
      // console.log("data", data.message);
      setMessages([...messages, data.message]);
    });
  }, [messages]);

  const profileOnClick = (e) => {
    profileImageRef.current.click();
  };
  const profileImageHandleChange = (e) => {
    const profileImg = e.target.files[0];
    if (profileImg) {
      dispatch(singleFileUpload(profileImg))
        .then((res) => setprofileImage(res.data.image))
        .catch((error) => console.log(error));
    }
    setImagePreview(URL.createObjectURL(profileImg));
  };

  const sendMsg = () => {
    socket.emit("sendMessage", {
      receiverId: adminId,
      receiverRole: "admin",
      messageContent: writemsg,
      type: "text",
      roomId: state.roomId,
    });
    setWritemsg("");
  };

  console.log(messages,"messages")

  return (
    <>
      <Layout>
        <Box>
          <Box w="100%" display="flex">
            <Box w="98%" display="flex" justifyContent="space-between">
              <Text color="#2E1A47" fontSize="34px" fontWeight="bold">
                Support Tickets
              </Text>
              <Header />
            </Box>
          </Box>
          <Box
            w="98%"
            display="flex"
            justifyContent={"space-between"}
            mt="20px"
            shadow="xl"
            bg="white"
            p="15px"
            borderRadius="8px"
            border="1px solid #DCDCDC"
          >
            <Box w="98%">
              <Text fontSize="20px" fontWeight="600" color="#2E1A47">
                {ticket?.ticketsDetails?.issue}
              </Text>
              <Text color="#7B6F6F" fontSize="16px" fontWeight="400">
                {ticket?.ticketsDetails?.ticketId}
                <br />
              </Text>
            </Box>
            
             <Box w="15%" mt={4}>
              <Text
                fontWeight={600}
                textTransform="capitalize"
                color={
                  ticket?.ticketsDetails?.status === "resolved"
                    ? "green.400"
                    : "yellow.600"
                }
                fontSize="14px"
              >
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Status
                </span>{" "}
                : {ticket?.ticketsDetails?.status}
              </Text>
            </Box>
          </Box>
          <Box
            shadow="2xl"
            bg="white"
            borderRadius="8px"
            p="15px"
            w="98%"
            mt="20px"
            mb="3%"
            border="1px solid	#DCDCDC"
            maxH={"60vh"}
            overflowY="scroll"
            position={"relative"}
          >
            {messages.map((message) => (
              <>
                {message?.roles?.sender === "admin" ? (
                  <Box w="97%" display="flex" mx="auto" mt="20px" key={message._id}>
                    <Box w={8} h={8} mt="1%">
                      <Image
                        rounded="full"
                        w="full"
                        h="full"
                        objectFit="cover"
                        src={message.admin.profileImage || bg1}
                      />
                    </Box>
                    <Box ml={2} mb={2} w="full">
                      <Flex>
                        <Text color="#000000" fontSize="14px" fontWeight="600">
                          Super Admin
                        </Text>
                        <Text
                          ml="10px"
                          color="#7B6F6F"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          {moment(message.createdAt).format("lll")}
                        </Text>
                      </Flex>
                      <Box
                        w="88%"
                        bg="#FF1957"
                        color="white"
                        borderRadius="0px 16px 16px 16px"
                        p="15px"
                        mt="7px"
                      >
                        <Text fontSize="14px" fontWeight="500">
                          {message.message}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box key={message._id}>
                    <Box
                      w="65%"
                      display="flex"
                      justifyContent="flex-end"
                      ml="34%"
                      mt="20px"
                    >
                      <Text
                        ml="5px"
                        color="#7B6F6F"
                        fontSize="14px"
                        fontWeight="400"
                      >
                        {moment(message.createdAt).format("lll")}
                      </Text>
                    </Box>
                    <Box
                      w="79%"
                      bg="#F3F3F3"
                      color="black"
                      borderRadius="0px 16px 16px 16px"
                      p="15px"
                      my="7px"
                      ml="20%"
                    >
                      <Text fontSize="14px" fontWeight="500">
                        {message.message}
                      </Text>
                    </Box>
                  </Box>
                )}
              </>
            ))}

            <hr />
            <Box
              w="97%"
              mx="auto"
              display="flex"
              pt="20px"
              pb="10px"
              justifyContent="space-between"
            >
              <Box w="70%" mt="5px">
                <Input
                  placeholder="Write your text here..."
                  p={4}
                  border="none"
                  onChange={(e) => setWritemsg(e.target.value)}
                  value={writemsg}
                />
              </Box>
              <Box
                w="28%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* <Button
                  bg="#E8E6E3"
                  borderRadius="5px"
                  border="1px solid #978D8C"
                  color="3B3538"
                  fontSize="14px"
                  fontWeight="600"
                  w="46%"
                  onClick={profileOnClick}
                >
                  <Text>Attach</Text>
                  <Image src={attach} ml="15px" />
                </Button>
                <Input
                  type="file"
                  display="none"
                  accept="image/png, image/gif, image/jpeg"
                  ref={profileImageRef}
                  onChange={profileImageHandleChange}
                ></Input> */}
                <Button
                  bg="#72992E"
                  borderRadius="5px"
                  color="white"
                  w="46%"
                  p={4}
                  fontSize="14px"
                  fontWeight="600"
                  onClick={sendMsg}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default SupportTicket;
