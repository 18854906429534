import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  redeemOffers: [],
  redeemOffer: {},
};

const redeemOfferSlice = createSlice({
  name: "redeemOffers",
  initialState,
  reducers: {
    getRedeemedOffers: (state, action) => {
      state.redeemOffers = action.payload;
      
    },
    getRedeemedOffer: (state, action) => {
        state.redeemOffer = action.payload;
        
      },
  },
});

export const { getRedeemedOffers, getRedeemedOffer } = redeemOfferSlice.actions;

export default redeemOfferSlice.reducer;
