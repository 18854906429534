import React from 'react'
import Layout from '../../components/Layout'
import "../../Marchant_plans.css";
import { l_logo } from "../../assets/images/images";
import { getFromLocalStorage } from '../../helpers';
const NewSelectedPlan = () => {
    const userData = getFromLocalStorage("userData")
  return (
    <Layout>
         <section className="mrh_plans">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              {/* logo */}
              <img src={l_logo} alt="" width="150px" />
            </div>
            <div className="glw_txt">
              <h2>Your Offer, Your Way: Choose How to Promote</h2>
            </div>
          </div>
          {/* plans */}
          <div className="col-md-6">
            <div className={`mar_crds ${userData?.subscriptionPlan === "Discovery_Plan" ? "active" : null } `}>
              <div className="hed">
                <h3>Showcase Your Offers</h3>
                <p>No Selling Required!</p>
              </div>
              <div className="desc">
                <p>
                  Get your business in front of eager customers – completely
                  free! For the first year, you can list unlimited offers with
                  zero fees. It's the perfect way to spotlight your best deals,
                  manage peak and off-peak promotions effortlessly, and attract
                  more foot traffic. Don’t miss the chance to boost your
                  visibility without any cost!
                </p>
              </div>
              <div className="cta">
                {/* <button onClick={()=>selectPlan("Discovery_Plan")}>Get Started Now</button> */}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`mar_crds ${userData?.subscriptionPlan === "Discovery Pro" ? "active" : null } `}>
              <div className="hed">
                <h3>Start Selling</h3>
                <p>Ready to Grow and Thrive?</p>
              </div>
              <div className="desc">
                <p>
                  Kickstart your sales journey with a full year of free listings
                  on our platform! Seamlessly handle payments with just a small
                  fee of 2.9% + 30p per transaction. Best of all, you’ll have
                  complete control over your offers. It’s your business, your
                  way – from showcasing your best deals to choosing how to
                  promote them. Ready to scale? Let's make it happen together!
                </p>
              </div>
              <div className="cta">
                {/* <button onClick={()=>selectProPlan("Discovery Pro")}>Start Selling Now</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </Layout>
  )
}

export default NewSelectedPlan