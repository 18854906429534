import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import { Box } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import profileImage from '../../assets/images/profilee.png'
import { clearAlNotification, getNotificationList, markAsSeenNotification } from '../../api/user'

const Notification = () => {
    const dispatch = useDispatch()
    const {notifications} = useSelector(state=>state.notification);

    const clearAllNotification = () =>{
        dispatch(clearAlNotification()).then((res)=>{
            console.log(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const markAsSeen = () =>{
        dispatch(markAsSeenNotification()).then((res)=>{
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    };

    useEffect(()=>{
        markAsSeen();

        return ()=>{
            dispatch(getNotificationList())
        }
    },[])
  return (
    <Layout>
        <Navbar />
       
        <Box w="98%"  mt="12"> 
            {notifications && notifications.length > 0 ? 
        
        <div className='clearNotification'>
            <button onClick={clearAllNotification} >Clear All</button>
        </div> : null
        }
        
        <div class="notification-ui_dd-content">
        {notifications && notifications.length > 0 ?  notifications?.map((item,i) => {
        return (
            <div key={i} class="notification-list notification-list--unread align-items-center">
             
                <div class="notification-list_content">
                <div class="notification-list_img">
                 <img src={item?.sellerId?.profileImage || profileImage } alt="user"/>
                  </div>
                    <div class="notification-list_detail">
                   <p><b>{item?.type}</b> </p>
                   <h5 class="text-muted">{item?.title}</h5>
                  <p class="text-muted mt-2"><small>{moment(item?.updatedAt).format("DD-MM-YYYY")} | {moment(item?.updatedAt).format("HH-MM")} </small></p>
               </div>
              </div> 
              </div> )
               }) : (
                <div>

                </div>
               )
                }

                
             
            
        </div>
 </Box>
    </Layout>
  )
}

export default Notification