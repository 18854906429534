import {
  Box,
  Button,
  Center,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  getCategory,
  getSubCategory,
  singleFileUpload,
  userSignup,
} from "../../api/user";
import { frame, icon_15, updatedLogo } from "../../assets/icons";
import { getFromLocalStorage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { profilee } from "../../assets/images";
import { DAYS } from "../../constants";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { signupUpValidator } from "../../helpers/validation";
import { onMessageListener, requestForToken } from "../../firebase";
import { FaMapMarkerAlt } from "react-icons/fa";

const Signup = () => {
  
  const [show, setShow] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const imageRef = useRef();
  const handleClick = () => setShow(!show);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const { categories } = useSelector((state) => state.categories);
  const { subCategories } = useSelector((state) => state.categories);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [preview, setPreview] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [profile, setProfile] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isCommission, SetIsCommission] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [notification, setNotification] = useState({ title: "", body: "" });
  requestForToken(setTokenFound, setCurrentToken);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      toast.success(payload.data.title);
    })
    .catch((err) => console.log("failed: ", err));
    const CustomDropdownIndicator = () => {
      return <FaMapMarkerAlt
      onClick={fetchCurrentLocation}
      style={{ color: 'grey', marginRight: '10px',cursor: 'pointer' }} />;
    };
    
    const fetchCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const geocoder = new window.google.maps.Geocoder();
            const latlng = { lat: latitude, lng: longitude };
    
            geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  setValue({
                    label: results[0].formatted_address,
                    value: results[0].place_id,
                  }); 
                } else {
                  console.log('No results found');
                }
              } else {
                console.log('Geocoder failed due to: ' + status);
              }
            });
          },
          (error) => {
            console.log('Error fetching the current location: ', error);
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    };
    
  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setLocation({
            lat,
            lng,
          })
        );
    }
  }, [value]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    dispatch(getCategory());
  };
 const [subCatId,setSubCatId] = useState("")

  const handleSubCategoryId = (id) => {
    setSubCatId(id)
  }
 
  useEffect(() => {
   dispatch(getSubCategory(subCatId))
   .then((res) => {
   }).catch((err) => {
    console.log(err)
   })
  },[subCatId])

  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => {
          if (res.statusCode === 200) {
            setProfile(res.data.image);
          }
        })
        .catch((error) => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
    });
    setImageURLs(newImageUrls);
  }, [images]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getFromLocalStorage("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signupUpValidator),
  });

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token]);

  const handleSignup = (data) => {
    if(!location.lat || !location.lng){
      toast.error("please enter your address")
    }
    else  {
        const { category,subCategory } = data;
        const newCate = JSON.parse(category);
        const newSUbCate = JSON.parse(subCategory);
        const cate = {
          id: newCate._id,
          name: newCate.mainCategory,
        };
        const subCat = {
          id:newSUbCate._id,
          name:newSUbCate.name
        }
        const allData = {
          ...data,
          gallery: "",
          profileImage: profile,
          latitude: location.lat || coordinates.latitude,
          longitude: location.lng || coordinates.longitude,
          openingDays: [],
          address: value?.label || "testing dummi",
          category: cate,
          subCategory : subCat,
        };
        const apiData = { ...allData, deviceToken: currentToken };
        setIsLoading(true);
        dispatch(userSignup(apiData))
          .then((res) => {
            if (res.statusCode === 200) {
              setIsLoading(false);
              toast.success(res.message);
              navigate("/login");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err.message);
          });
      
    }
  };


  const deleteImage = (img) => {
    const newImgURL = imageURLS.filter((image) => image !== img);
    setImageURLs(newImgURL);
  };


  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const cordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      setCoordinates(cordinates);
    });
  }, []);


  useEffect(() => {
    if (!isSubscribed) {
      reset({
        amount: "",
        subscriptionPlan: "",
      });
    }
  }, [isSubscribed]);

  const handleSubscription = (e) => {
    if (e.target.checked) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
      reset({
        amount: "",
        subscriptionPlan: "",
      });
    }
  };

  const handleCommission = (e) => {
    if (e.target.checked) {
      SetIsCommission(true);
    } else {
      SetIsCommission(false);
      reset({
        commission: "",
        subscriptionPlan: "",
      });
    }
  };

  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }

  const handleAddress = () => {
    if (!value) {
      alert("Please select an address.");
    } else {
      console.log("Address selected:", value);
    }
  };
  return (
    <Box
      display={"grid"}
      placeItems={"center"}
      flexDirection={["column", "row"]}
      w="full"
      mt={8}
    >
      <Box w={["95%", "",]} display={"grid"} placeItems={"center"} gap="40px">
        <VStack>
          <Image src={updatedLogo} />
          <Text fontSize="xs">Merchant Signup</Text>
          <Text fontSize="23px" fontWeight="600" color="#3B3538">
            Sign Up
          </Text>
          <Text fontSize="14px" fontWeight="500" color="#3B3538">
            Fill the details and create your account.
          </Text>
        </VStack>
        <Center mt={4}>
          <Box position="relative" w={"70px"}>
            <Image
              src={preview || profilee}
              width="70px"
              height="70px"
              objectFit={"cover"}
              borderRadius="full"
              {...register("profileImage")}
            />
            <Image
              src={icon_15}
              bg="#1E0F42"
              p="5px"
              top={-0.5}
              right={-1}
              borderRadius="100%"
              position="absolute"
              h="25px"
              w="25px"
              onClick={handleButtonClick}
            />
          </Box>
        </Center>
        <Input
          type="file"
          display="none"
          accept="image/png, image/jpeg, image/jpg"
          ref={imageRef}
          onChange={handleChange}
        />
        <Box mt="10px" mb="30px" maxWidth={"350px"}>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mb="0.5"
          >
            Business Name
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("businessName")}
            onKeyDown={handleSpacebarPress} 
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.businessName?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Contact Person
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("contactPerson")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.contactPerson?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Contact Email
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="email"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("email")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.email?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"

            mt={["10px", "20px"]}
            mb="0.5"
          >
            Password
          </FormLabel>
          <InputGroup>
            <Input
              bg="white"
              shadow="lg"
              minLength={"8"}
              maxLength={"16"}
              pr="4.5rem"
              type={showPassword ? "text" : "password"}
              outline="none"
              {...register("password")}
              onKeyDown={handleSpacebarPress}
            />
            <InputRightElement width="4.5rem">
              {showPassword ? <ViewIcon 
                  h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleShowPassword}
              /> : <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleShowPassword}
            />}
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs" width="350px" >
            {errors.password?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Confirm Password
          </FormLabel>

          <InputGroup>
            <Input
              bg="white"
              shadow="lg"
              pr="4.5rem"
              minLength={"8"}
              maxLength={"16"}
              type={show ? "text" : "password"}
              {...register("confirmPassword")}
              onKeyDown={handleSpacebarPress}
            />
            <InputRightElement width="4.5rem">
            {show ? <ViewIcon 
              h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleClick}
              /> : <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleClick}
            />}
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs">
            {errors.confirmPassword?.message}
          </Text>

          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Address
          </FormLabel>
          <div className="add_frm">
            <GooglePlacesAutocomplete
              className="form-control border_primary c_sel has-search "
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              selectProps={{
                value,
                onChange: setValue,
                components: {
                  DropdownIndicator: (props) => <CustomDropdownIndicator fetchCurrentLocation={fetchCurrentLocation} {...props} />,
                  IndicatorSeparator: () => null, 
                
                }
              }}
            />
            </div>      
          
          <Box>
           
          </Box>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Category
          </FormLabel>
          <Select
            bg="white"
            shadow="lg"
            placeholder="Select category"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("category")}
            onKeyDown={handleSpacebarPress}
            onChange={(e) => {
              const selectedCategory = JSON.parse(e.target.value);
              handleSubCategoryId(selectedCategory._id);
            }}
          >
            {categories?.length > 0 ?
              categories?.map((value) => (
                <option key={value?._id} value={JSON?.stringify(value)}>
                  {value?.mainCategory}
                </option>
              )) : (
                <option value="" disabled>
                  No category available
                </option>
              )}
          </Select>
          <Text my={1} color="red" fontSize="xs">
            {errors.category?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
          Sub Category
          </FormLabel>
          <Select
            bg="white"
            shadow="lg"
            placeholder="Select Sub Category"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("subCategory")}
            onKeyDown={handleSpacebarPress}
          >
            {subCategories?.length > 0 ?
              subCategories?.map((value) => (
                <option key={value?._id} value={JSON?.stringify(value)}>
                  {value?.name}
                </option>
              ))
               : (
                <option value="" disabled>
                  No sub category available
                </option>
              )}
          </Select>
          <Text my={1} color="red" fontSize="xs">
            {errors.subCategory?.message}
          </Text>
          <div className="mb-lg-2 mb-1 col-sm">
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
             Phone number
          </FormLabel>
                    <div className="input-group mb-1">
                      <span className="input-group-text" id="basic-addon3">
                        +44
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter your phone number"
                        {...register("phone")}
                        
                        onKeyDown={(e) => {
                          handleSpacebarPress(e)
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        maxLength={10}
                      />
                    </div>
                      <Text my={1} color="red" fontSize="xs">
                        {errors.phone?.message}
                       </Text>
                   </div>
        

          <Button
            color="white"
            bg="#FF1A57"
            w="full"
            mt="20px"
            onClick={handleSubmit(handleSignup)}
            isLoading={isLoading}
          >
            Sign Up
          </Button>
          <Text>
          <div className="mt-2 ">
                  <p className="text-center">
                    By registering, you agree to  Primo offers's{" "} <br />
                    <Link to="/termsofservice" style={{color:"#FF1A57"}}>Terms of service </Link>
                
                  </p>
                </div>
          </Text>
          <Text
            color="#FF1A57"
            fontSize="14px"
            my={2}
            textAlign="center"
            fontWeight="400"
            lineHeight="21px"
            cursor={"pointer"}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Signup;
