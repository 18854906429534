import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Center,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { QR } from "../../assets/images";
import { card_10 } from "../../assets/images";
import { icon_21, icon_28 } from "../../assets/icons";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getSingleRedeemedOffer,
  updateRedeemOfferQuantity,
} from "../../api/user";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";

const Index = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reset, handleSubmit, register } = useForm();
  const { id } = useParams();
  const [data, setData] = useState("");
  const [qty, setQty] = useState(null);

  useEffect(() => {
    if (id) {
      reset({
        redeemId: id,
      });
    }
  }, [id]);

  const onSubmit = (data) => {
    if (!data.quantity || data.quantity === "0" || data.quantity === 0 || data.quantity === -0 || data.quantity < 0) {
      return toast.error("Please enter a valid quantity.");
    }
    if (qty < Number(data.quantity)) {
      return toast.error(`you have only ${qty} quantity`);
    } else {
      dispatch(updateRedeemOfferQuantity(data))
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.message);
            navigate('/redeem-offers')
          } else {
            // console.log(res);
            toast.error("");
          }
        })
        .catch((err) => {
          toast.error(err.data.message);
        });
    }
  };

  const getOfferDetails = () => {
    if (id) {
      dispatch(getSingleRedeemedOffer(id))
        .then((res) => {
          setData(res.data.offer);
          setQty(res?.data?.offer?.offer?.offers[0]?.quantity);
        })
        .catch((err) => {
          console.log(err);
          if (err.data.statusCode === 400) {
            toast.error(err.data.message)
            navigate("/redeem-offers");
           
          }
        });
    }
  };



  useEffect(() => {
    getOfferDetails();
  }, [id]);
  return (
    <>
      <Layout>
        <Box w="99%" h="100vh">
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Text
                fontFamily="Rubik"
                color="#2E1A47"
                fontSize={{ xl: "34px", md: "30px" }}
                fontWeight="600"
                lineHeight="40px"
                letter="-4%"
              >
                Offer Details
              </Text>
            </Box>
            <Header />
          </Box>
          <Box>
            <Box w="60%" mt={8}>
              <HStack>
                <Box w="40%">
                  <Text fontSize="14px" color="gray.500" fontWeight={400}>
                    Enter the Unique Code
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontSize="14px" color="gray.500" fontWeight={400}>
                    Quantity
                  </Text>
                </Box>
              </HStack>
              <Box w="100%" display="flex">
                <Input
                  w="30%"
                  type="tel"
                  fontSize="xs"
                  bg="white"
                  shadow="lg"
                  placeholder="00087573884934394"
                  {...register("redeemId")}
                />
                <Input
                  w=" 30%"
                  type="number"
                  min="1"   
                  fontSize="xs"
                  bg="white"
                  shadow="lg"
                  ml={4}
                  {...register("quantity",{ required: true })}
                />
                <Box w="50%" display="flex" justifyContent="space-around">
                  <Button
                    variant="solid"
                    bg="#1E0F42"
                    w="42%"
                    fontSize={{ xl: "14px", md: "12px" }}
                    fontWeight="600"
                    color="white"
                    ml="5%"
                    onClick={onOpen}
                  >
                    Scan QR
                  </Button>
                  <Modal isOpen={isOpen} size="sm" onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <Center>
                          <Text mt="2">QR Code</Text>
                        </Center>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6}>
                        <Text
                          fontSize="18px"
                          fontWeight="500"
                          textAlign={"center"}
                        >
                          Scan at the checkout.
                        </Text>

                        <Center>
                          <Image src={QR} mt="10px" />
                        </Center>
                        <Text
                          textAlign={"center"}
                          mt="30px"
                          fontSize="14px"
                          fontWeight="600"
                          color="#3B3538"
                        >
                          Not scanning?Use this code
                        </Text>
                        <Text
                          textAlign={"center"}
                          mt="10px"
                          color="#3B3538"
                          fontSize="23px"
                          fontWeight="600"
                        >
                          9864-9484-6352
                        </Text>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          bg="#FF1A57"
                          w="full"
                          color="white"
                          fontSize="14px"
                        >
                          Done
                          <Image src={icon_28} ml="5px" />
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Button
                    bg="#FF1A57"
                    variant="outline"
                    w="60%"
                    fontSize={{ xl: "14px", md: "12px" }}
                    fontWeight="600"
                    color="white"
                    border="none"
                    ml={4}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box w="60%" mt="20px">
              <Image
                w="full"
                h={200}
                objectFit="cover"
                src={data?.offerData?.gallery[0]}
              />
            </Box>
          </Box>
          <Box>
            <Box w="60%" mt="20px" display="flex">
              <Box w="94%">
                <Text
                  color="#1E0F42"
                  fontSize={{ xl: "32px", md: "28px" }}
                  fontWeight="600"
                >
                  {data?.offerData?.offerName}
                </Text>
                <Flex>
                  <Text color="#3B3538" fontWeight="400" fontSize="14px">
                    Category:
                  </Text>
                  <Text color="#3B3538" fontWeight="700" fontSize="14px">
                    {data?.offer?.seller?.category?.name}
                  </Text>
                  <Text
                    color="#FF1A57"
                    fontWeight="600"
                    fontSize="14px"
                    ml="5px"
                  >
                    |
                  </Text>
                  <Text
                    color="#3B3538"
                    fontWeight="400"
                    fontSize="14px"
                    ml="5px"
                  >
                    Quantity: {data?.offer?.offers[0]?.quantity}
                  </Text>
                </Flex>
                <Flex>
                  <Text color="#3B3538" fontWeight="400" fontSize="14px">
                    Discounted Price:
                  </Text>
                  <Text color="#3B3538" fontWeight="700" fontSize="14px">
                    £{data?.offerData?.price?.discountedPrice}
                  </Text>
                  <Text
                    color="#FF1A57"
                    fontWeight="600"
                    fontSize="14px"
                    ml="5px"
                  >
                    |
                  </Text>
                  <Text
                    color="#3B3538"
                    fontWeight="400"
                    fontSize="14px"
                    ml="5px"
                  >
                    Total Payment:
                  </Text>
                  <Text color="#3B3538" fontWeight="700" fontSize="14px">
                    £{data?.offer?.totalAmount}
                  </Text>
                </Flex>
              </Box>
              <Box w="5%" mt="15px">
                {/* <Image src={icon_21} /> */}
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12 14.6667L16 18.6667L29.3333 5.33337" stroke="#FF1A57" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                <path d="M28 16V25.3333C28 26.0406 27.7191 26.7189 27.219 27.219C26.7189 27.7191 26.0406 28 25.3333 28H6.66667C5.95942 28 5.28115 27.7191 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V6.66667C4 5.95942 4.28095 5.28115 4.78105 4.78105C5.28115 4.28095 5.95942 4 6.66667 4H21.3333" stroke="#FF1A57" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default Index;
