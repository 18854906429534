import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { frame, updatedLogo } from "../../assets/icons";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../api/user";
import { toast } from "react-toastify";
import { resetPasswordSchema } from "../../helpers/validation";



const Index = () => {
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleOnClick = () => setConfirmShow(!confirmShow);

  const { state } = useLocation();
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const handleResetPassword = (data) => {
    const { password, confirmPassword } = data;
    const apiData = {
      password,
      confirmPassword,
      token: state.token,
    };
    dispatch(resetPassword(apiData)).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.message);
        naviagte("/login");
      }
    });
  };

  return (
    <Box
      display={"grid"}
      placeItems={"center"}
      h={"100vh"}
      flexDirection={["column", "row"]}
    >
      <Box
        w={["100%", "60%"]}
        display={"grid"}
        placeItems={"center"}
        gap="40px"
        flexDirection={["column", "row"]}
      >
        <Box>
          <VStack>
            <Image src={updatedLogo} />
            <Text fontSize="xs" ml={""}>
            Merchant Dashboard V1.0
            </Text>
          </VStack>
          <Text
            textAlign={"center"}
            fontSize="20px"
            color="#3B3538"
            fontWeight="600"
            mt="20px"
          >
            Reset Password
          </Text>
        </Box>
        <Box w={["70%", "40%"]}>
          <FormLabel
            alignSelf={"flex-start"}
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
          >
            Password
          </FormLabel>
          <InputGroup>
            <Input
              bg="white"
              shadow="xl"
              border="1px"
              borderColor="gray.400"
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="*******"
              name="password"
              {...register("password")}
            />

            <InputRightElement width="4.5rem">
              {show ? <ViewIcon
                h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleClick}
              /> :  <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleClick}
            />}
              
                {/* {show ? "Hide" : "Show"} */}
             
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs">
            {errors.password?.message}
          </Text>
          <FormLabel
            alignSelf={"flex-start"}
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            mt="12px"
          >
            Confirm Password
          </FormLabel>
          <InputGroup>
            <Input
              bg="white"
              shadow="xl"
              border="1px"
              borderColor="gray.400"
              pr="4.5rem"
              type={confirmShow ? "text" : "password"}
              placeholder="*******"
              name="confirmPassword"
              {...register("confirmPassword")}
            />

            <InputRightElement width="4.5rem">
              {confirmShow ? <ViewIcon
                h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleOnClick}
              /> : <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleOnClick}
            />}
              
                {/* {confirmShow ? "Hide" : "Show"} */}
             
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs">
            {errors.confirmPassword?.message}
          </Text>
        </Box>
        <Button
          color="white"
          bg="#FF1A57"
          w={["70%", "40%"]}
          onClick={handleSubmit(handleResetPassword)}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Index;
