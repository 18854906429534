import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getGraphData } from '../api/user';

const useGraph = () => {
    const [graphType, setGraphType] = useState("dailySales");
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState("daily");

    const dispatch = useDispatch();

    const getGraph = (type,filter) => {
        setIsLoading(true)
        dispatch(getGraphData(type,filter))
          .then((res) => {
            if (res.statusCode === 200) {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      };
    
      useEffect(() => {
        getGraph(graphType,filter);
      }, [graphType,filter]);
    
      const handleGraphType = (index) => {
        switch (index) {
          case 0:
            setGraphType("dailySales");
            break;
          case 1:
            setGraphType("income");
            break;
          case 2:
            setGraphType("returningCustomers");
            break;
          case 3:
            setGraphType("offerViews");
            break;
          default:
            setGraphType("buyer");
            break;
        }
      };
  return {
    isLoading,
    handleGraphType,
    setFilter

  }
}

export default useGraph