import React from 'react'
import { updatedLogo } from '../../assets/icons'
import { Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const CookiesPolicy = () => {
  return (
   <>
    <div class="faq_container p-4">
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",direction:"-moz-initial"}}>
            <div>
                <div>
                <Image src={updatedLogo} />
                </div>
             <Text style={{fontSize:"12px"}}>Merchant Dashboard V1.0</Text>
            </div>
        </div>
        <h1 class="text_dark  mb-5">
          <b> COOKIE POLICY</b>
        </h1>
        <p class="list_item">
          PRIMOOFFERS LIMITED (<strong>we</strong> or <strong>us</strong> or{" "}
          <strong>our</strong>) uses cookies when you visit our website,{" "}
          <Link to="/" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com, 
          </Link>{" "}
          (the <strong>Website</strong>) to help customise the Website and improve your
          experience using the Website.
        </p>
        <p class="list_item">
          This policy applies between you, the user of this Website, and
          us,PRIMOOFFERS LIMITED, the owner and provider of this Website.
        </p>
        <p class="list_item">
          When you visit the Website, and before your Website places cookies on
          your computer, you will be presented with a message bar requesting
          your consent to set those cookies. By giving your consent to the
          placing of cookies, you are enabling us to provide a better experience
          and service. You may, if you wish, deny consent to the placing of
          these cookies; however, certain features of the Website may not
          function fully or as intended.
        </p>
        <p class="list_item">
          This cookie policy should be read alongside, and in addition to, our
          Privacy Policy, which can be found at:
          <Link to="/privacypolicy" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/privacy-policy.
          </Link>
        </p>
        <h4>
          <strong>What are cookies?</strong>
        </h4>
        <p class="list_item">
          A cookie is a small text file placed on your computer by this Website
          when you visit certain parts of the Website and /or when you use
          certain features of the Website.
        </p>
        <p class="list_item">
          This Website may place and access certain cookies on your computer. We
          use these cookies to improve your experience of using the Website and
          to improve our range of products and services.
        </p>
        <p class="list_item">
          Cookies do not usually contain any information that personally
          identifies you, the Website user. However, personal information that
          we store about you may be linked to the information obtained from and
          stored in cookies. For more information on how such personal
          information is handled and stored, refer to our Privacy Policy which
          is available online at:{" "}
          <Link to="/privacypolicy" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/privacypolicy.
          </Link>
        </p>
        <h4>
          <strong>Types of cookies</strong>
        </h4>
        <p class="list_item">This Website uses the following cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Type of cookie</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Strictly necessary cookies</td>
              <td>
                These are cookies that are required for the operation of the
                Website. They include, for example, cookies that enable you to
                log into secure areas of the Website, use a shopping cart or
                make use of e-billing services.
              </td>
            </tr>
            <tr>
              <td>Analytical/performance cookies</td>
              <td>
                These cookies allow us to recognise and count the number of
                visitors and to see how visitors move around our Website when
                they are using it. This helps us to improve the way our Website
                works, for example, by ensuring that users are finding what they
                are looking for easily.
              </td>
            </tr>
            <tr>
              <td>Functionality cookies</td>
              <td>
                These are used to recognise you when you return to our Website.
                This enables us to personalise our content for you, greet you by
                name and remember your preferences (for example, your choice of
                language or region). By using the Website, you agree to our
                placement of functionality cookies.
              </td>
            </tr>
            <tr>
              <td>Targeting cookies</td>
              <td>
                These cookies record your visit to our Website, the pages you
                have visited and the links you have followed. We will use this
                information to make our Website and the advertising displayed on
                it more relevant to your interests. We may also share this
                information with third parties for this purpose.
              </td>
            </tr>
          </tbody>
        </table>
        <p class="list_item">
          You can find a list of the cookies that we use in the attached Cookie
          Schedule.
        </p>
        <p class="list_item">
          We have carefully chosen these cookies and have taken steps to ensure
          that your privacy is protected and respected at all times.
        </p>
        <h4>
          <strong>How to control your cookies</strong>
        </h4>
        <p class="list_item">
          You can choose to enable or disable cookies in your internet browser.
          By default, most internet browsers accept cookies but this can be
          changed. For further details, please see the help menu in your
          internet browser.
        </p>
        <p class="list_item">
          You can switch off cookies at any time, however, you may lose
          information that enables you to access the Website more quickly and
          efficiently.
        </p>
        <p class="list_item">
          It is recommended that you ensure that your internet browser is
          up-to-date and that you consult the help and guidance provided by the
          developer of your internet browser if you are unsure about adjusting
          your privacy settings.
        </p>
        <p class="list_item">
          For more information generally on cookies, including how to disable
          them, please refer to aboutcookies.org. You will also find details on
          how to delete cookies from your computer.
        </p>
        <h4>
          <strong>Changes to this policy</strong>
        </h4>
        <p class="list_item">
          PRIMOOFFERS LIMITED reserves the right to change this cookie policy as we
          may deem necessary from time to time or as may be required by law. Any
          changes will be immediately posted on the Website and you are deemed
          to have accepted the terms of the cookie policy on your first use of
          the Website following the alterations.
        </p>
        <h4>
          <strong>Contact details</strong>
        </h4>
        <p class="list_item">
          The Website is owned by PRIMOOFFERS LIMITED incorporated in England and
          Wales with registered number 15835192 whose registered office is at
          20-22 WENLOCK ROAD, LONDON, N1 7GU, England.
        </p>
        <p class="list_item">You may contact us:</p>
        <ul class="list_style ul_spc">
          <li>by email at ask@primooffers.com.</li>
          <li>using the contact form on the Website.</li>
        </ul>
        <p class="list_item">This policy was updated on 23 July 2024.</p>
        <h4>
          <strong>COOKIE SCHEDULE</strong>
        </h4>
        <p>
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </p>
        <p>Cookies that we use on the Website:</p>
        <h4>
          <strong>Strictly necessary cookies</strong>
        </h4>
        <p class="list_item">
          We use the following strictly necessary cookies:
        </p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Name of cookie</th>
              <th scope="col">Purpose of cookie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Operations based Cookies</td>
              <td>
                These cookies are essential for the operation of our Site. They
                make it possible for you to browse and use our Site. If you
                disable these cookies, the Site will not function correctly.
              </td>
            </tr>
          </tbody>
        </table>
        <h4>
          <strong>Functionality cookies</strong>
        </h4>
        <p class="list_item">We use the following functionality cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Name of cookie</th>
              <th scope="col">Purpose of cookie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Functionality based Cookies</td>
              <td>
                These cookies are used to recognize and remember your
                preferences. For example, functionality cookies may use
                information, such as your location, to make sure that you arrive
                at a version of our Site that is specific to your city or
                region.
              </td>
            </tr>
          </tbody>
        </table>
        <h4>
          <strong>Analytical/performance cookies</strong>
        </h4>
        <p class="list_item">
          We use the following analytical/performance cookies:
        </p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Name of cookie</th>
              <th scope="col">Purpose of cookie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Performance based Cookies</td>
              <td>
                These cookies gather information about how visitors use the Site
                and monitor Site performance. For example, performance cookies
                tell us which pages are popular, monitor traffic to our Site and
                compile anonymous analytic information. Performance cookies can
                also be used to identify and remedy operational problems with
                the Site.
              </td>
            </tr>
          </tbody>
        </table>
        <h4>
          <strong>Targeting cookies</strong>
        </h4>
        <p class="list_item">We use the following targeting cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Name of cookie</th>
              <th scope="col">Purpose of cookie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Advertising Preferences based Cookies</td>
              <td>
                We offer you choices about receiving interest-based ads from us.
                For this, we allow third parties to deliver personalized
                advertising content on our Site and/ or on third party websites
                and applications. These third parties use cookies to understand
                your interest in the advertisements and content delivered to
                you, as well as to serve you with more relevant advertisements
                based on your activities on our Site. For example, third-party
                cookies could record information about whether you have clicked
                on certain content or advertisements on our Site or other
                websites, or placed an item on our Site in your shopping cart.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
   </>
  )
}

export default CookiesPolicy