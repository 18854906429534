import { Box, Hide, Text, useDisclosure } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import SidebarDrawer from './modals/SidebarDrawer';

const navData = [
    {
        title: "Home",
        path: "/"
    },
    {
        title: "Manage Offers",
        path: "/manage-offers"
    },
    {
        title: "Manage Orders",
        path: "/manage-orders"
    },
    {
        title: "My Business",
        path: "/my-business"
    },
    {
        title: "Support Centre",
        path: "/support-centre"
    },
    {
        title: "Redeem Offers",
        path: "/redeem-offers"
    },
    {
        title: "Requested Offers",
        path: "/requested-offers"
    },
    {
        title: "New Ticket",
        path: "/new-ticket"
    },
    {
        title: "Profile",
        path: "/profile"
    },
    {
        title: "New Offer",
        path: "/new-offer"
    },
    {
        title: "Subscriptions",
        path: "/choose-how-to-promote"
    },
    {
        title: "Payment",
        path: "/payment"
    },
    {
        title: "Notifications",
        path: "/notification"
    },
    {
        title: "Billing Report",
        path: "/billing-report"
    },
    {
        title: "Select Your Plan",
        path: "/your-selection"
    },
]

const Navbar = () => {
    const btnRef = useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { pathname } = useLocation()
    const activeNavItem = navData?.find((item) => item?.path === pathname);



    return (
        <>
        <Box display="flex"  justifyContent="space-between">
            <Box  display="flex">
                <Box ref={btnRef} colorScheme='pink' onClick={onOpen}>
                <Hide above="lg">
                   <HamburgerIcon w={8} h={8} color="#2E1A47" mr={"8px"} mt={"5px"} 
                   
                   />
                 </Hide>
                </Box>
            
                <Box>
                {activeNavItem && (
                    <Text
                        color="#2E1A47"
                        fontSize={{ md: 28, lg: 30, xl: 34 }}
                        fontWeight={600}
                    >
                        {activeNavItem?.title}
                    </Text>
                )}
                </Box>
                
                
            </Box>
            <Box>
                <Header />
            </Box>

           
        </Box>
        <SidebarDrawer onClose={onClose} onOpen={onOpen} isOpen={isOpen}
         btnRef={btnRef}  />
        </>
    )
}

export default Navbar