import React from 'react'

const RedeemOffers = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 4.71053H15.815C15.925 4.37895 16 4.03158 16 3.65789C16 1.91579 14.655 0.5 13 0.5C11.955 0.5 11.035 1.06316 10.5 1.91579L10 2.63158L9.5 1.91579C8.965 1.06316 8.045 0.5 7 0.5C5.345 0.5 4 1.91579 4 3.65789C4 4.03158 4.07 4.37895 4.185 4.71053H2C0.895 4.71053 0.00999999 5.65263 0.00999999 6.81579L0 18.3947C0 19.5579 0.895 20.5 2 20.5H18C19.105 20.5 20 19.5579 20 18.3947V6.81579C20 5.65263 19.105 4.71053 18 4.71053ZM13 2.60526C13.55 2.60526 14 3.07895 14 3.65789C14 4.23684 13.55 4.71053 13 4.71053C12.45 4.71053 12 4.23684 12 3.65789C12 3.07895 12.45 2.60526 13 2.60526ZM7 2.60526C7.55 2.60526 8 3.07895 8 3.65789C8 4.23684 7.55 4.71053 7 4.71053C6.45 4.71053 6 4.23684 6 3.65789C6 3.07895 6.45 2.60526 7 2.60526ZM18 18.3947H2V16.2895H18V18.3947ZM18 13.1316H2V6.81579H7.08L5 9.8L6.625 11.0263L9 7.62105L10 6.18947L11 7.62105L13.375 11.0263L15 9.8L12.92 6.81579H18V13.1316Z" fill="currentColor"/>
    </svg>
    
  )
}

export default RedeemOffers;