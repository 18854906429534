import React from 'react'

const ManageOrders = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M17.0234 16.6682L16.1749 5.97644C16.122 5.28762 15.5395 4.74801 14.8487 4.74801H13.1727V4.67268C13.1727 2.64755 11.5252 1 9.50002 1C7.47489 1 5.82735 2.64755 5.82735 4.67268V4.74801H4.15141C3.46055 4.74801 2.87797 5.28758 2.82527 5.97499L1.97651 16.6697C1.91082 17.526 2.20788 18.3785 2.79147 19.0085C3.37505 19.6386 4.20233 20 5.06115 20H13.9389C14.7976 20 15.625 19.6386 16.2086 19.0085C16.7922 18.3785 17.0892 17.526 17.0234 16.6682ZM6.94026 4.67268C6.94026 3.26122 8.08861 2.11291 9.50002 2.11291C10.9114 2.11291 12.0598 3.26126 12.0598 4.67268V4.74801H6.94026V4.67268ZM15.3921 18.2523C15.0129 18.6617 14.4968 18.8871 13.9389 18.8871H5.06119C4.50321 18.8871 3.98717 18.6617 3.60798 18.2523C3.22884 17.843 3.04351 17.3111 3.08611 16.7563L3.93481 6.06153C3.94342 5.94902 4.03856 5.86092 4.15141 5.86092H5.82735V7.23155C5.82735 7.53886 6.0765 7.78801 6.3838 7.78801C6.6911 7.78801 6.94026 7.53886 6.94026 7.23155V5.86092H12.0598V7.23155C12.0598 7.53886 12.3089 7.78801 12.6162 7.78801C12.9235 7.78801 13.1727 7.53886 13.1727 7.23155V5.86092H14.8487C14.9615 5.86092 15.0566 5.94905 15.0654 6.06302L15.9139 16.7548C15.9566 17.3111 15.7712 17.8429 15.3921 18.2523Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
<path d="M12.007 10.4364C11.7897 10.219 11.4374 10.219 11.2201 10.4364L8.73542 12.9211L7.78 11.9656C7.56273 11.7483 7.21038 11.7483 6.99306 11.9656C6.77575 12.183 6.77575 12.5353 6.99306 12.7526L8.34195 14.1015C8.45061 14.2101 8.59303 14.2645 8.73538 14.2645C8.87774 14.2645 9.0202 14.2102 9.12882 14.1015L12.0069 11.2233C12.2243 11.006 12.2243 10.6537 12.007 10.4364Z" fill="currentColor"/>
</svg>
  )
}

export default ManageOrders;