import React from 'react'
import { boost, free, insights, l_banner_logo, l_logo, offer_img, real_time, store, support,offer_img2 } from '../assets/images/images'
// import { l_logo, offer_img2 } from './assets/images/images'
import "./landingPage.css" 
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

function LandingPage() {
    const navigate = useNavigate();
    const {token} = useSelector(state=>state.authUser)
return (
    <>
    <header className=' landing_header bg-white shadow-sm px-0 px-md-4'> 

<nav className=" landing_nav navbar navbar-expand-lg bg-white">
    <div className="container-fluid">
    <a className="navbar-brand" href="#">
        <img src={l_logo} alt="logo" />
    </a>
    <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
        aria-controls="navbarScroll"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg>
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
        <ul
        className="navbar-nav mx-auto my-2 gap-2 my-lg-0 navbar-nav-scroll landing_menu "
        style={{ "--bs-scroll-height": "100px" }}
        >
        <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">
            Home
            </a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#howItWork">
            How it Works
            </a>
        </li>
        <li className="nav-item">
            <a className="nav-link " href="#features" tabindex="-1" aria-disabled="true">
            Features
            </a>
        </li>
        <li className="nav-item">
            <Link className="nav-link " to="/contact" tabindex="-1" aria-disabled="true">
            Contact
            </Link>
        </li>

        <li className="nav-item">
            <a className="nav-link " href="#lifeStyle" tabindex="-1" aria-disabled="true">
            Lifestyle
            </a>
        </li>

        </ul>
    

        <button className="landing_btn "  onClick={()=>{token? navigate("/home"): navigate("/login")}}>
        Join for Free
        </button>
    
    </div>
    </div>
</nav>
</header>

{/* banner section  */}
<section className='landing_banner px-0 px-md-4'>


<div className='banner_img d-flex align-items-center justify-content-end'>

<div className='connect_with'>
<img src={l_banner_logo} alt="banner logo" />

<h4 className='my-3'>Connect with customers in real time. </h4>
<p >Show them what's hot and happening right now! Get Ready To Skyrocket Your Sales.</p>
<button className="landing_btn mt-4 "  onClick={()=>{token? navigate("/home"): navigate("/login")}}>
        Join for Free
        </button> 
</div>


</div>

</section>


{/* Launch section  */}
<section className='launch_offer mt-4 px-0 px-lg-5 '> 
<div className='container'>

<div className='row'>

<div className='col-12 col-lg-6 col-sm-6 '>

<div className='offer_img '> <img className='h-100 w-100' src={offer_img} alt="" /> </div>
</div>

<div className='col-12 col-lg-6 col-sm-6 '> 

<div className='offer_text text-white text-center'> 

<h5>Exclusive Launch Offer!</h5>

<h4> 
Maximize your reach without any cost

</h4>


<h6 className='mt-3'>Limited time only! </h6>

<p>Seize this opportunity and sign up with Primo Offers today FOR FREE. No subscription. No Commission.</p>

<button className="landing_btn mt-4 bg-white  text-dark  px-5"  onClick={()=>{token? navigate("/home"): navigate("/login")}} >
Get Started for Free
        </button> 

</div>


</div>

</div>

</div>

</section>

{/* Partner section  */}

<section className='why_partner my-5'> 

<div className='container'>

<h4 className='text-center '>Why Partner with Primo Offers?</h4>


<div className='all_offers mt-5'>

<div className='offers text-center'>
<div className='offer_icon'> <img src={store} alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'>Flood Your Store with </h6>
<p> <b> Shoppers:</b>Exclusive deals that bring customers rushing in</p>

</div>

</div>

<div className='offers text-center'>
<div className='offer_icon'> <img src={real_time} alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'>Real-Time Offers:  </h6>
<p> Alert your audience instantly about your latest deals </p>

</div>

</div>


<div className='offers text-center'>
<div className='offer_icon'> <img src={support} alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'>Expert Marketing Support: </h6>
<p> Amplify your reach with our marketing wizards. </p>

</div>

</div>

<div className='offers text-center'>
<div className='offer_icon'> <img src={boost} alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'> Boost Your Sales Instantly:</h6>
<p>  Experience a surge in sales with our unbeatable offers.  </p>

</div>

</div>

<div className='offers text-center'>
<div className='offer_icon'> <img src={insights}  alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'>Deep Customer Insights: </h6>
<p> Understand your customers like never before </p>

</div>

</div>
<div className='offers text-center'>
<div className='offer_icon'> <img src={free} alt="" />  </div>
<div className='offer_text mt-3 '> <h6 className='mb-0'> No Strings Attached:  </h6>
<p>Join for free—no subscription, no commission!</p>

</div>

</div>






</div>
<div className='text-center mt-3'> <button className="landing_btn mt-4  "  onClick={()=>{token? navigate("/home"): navigate("/login")}}>
        Join for Free
        </button>  </div>



</div>


</section>



    {/* how it works */}

    <section className='hw_it_wrks' id='howItWork' >
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center mb-5">
                <h2>How it works</h2>
                <h5>Get started in 4 easy steps</h5>
            </div>
            <div className="col-md-3">
                <div className="text-center hw_crd">
                <span className="icn mb-3">
                    1
                </span>
                <h3 className='text-center fw-bold'>Sign Up:</h3>
                <p className='text-center'>Create your merchant account in a snap.</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="text-center hw_crd">
                    <span className="icn mb-3">
                        2
                    </span>
                    <h3 className='text-center fw-bold'>Craft Irresistible Offers:</h3>
                    <p className='text-center'>Design offers that your customers can't resist.</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="text-center hw_crd">
                    <span className="icn mb-3">
                        3
                    </span>
                    <h3 className='text-center fw-bold'>Go Live:</h3>
                    <p className='text-center'>Launch and start attracting customers instantly with real-time notifications.</p>
                </div>
            </div>
            <div className="col-md-3">
            <div className="text-center hw_crd">
                <span className="icn mb-3">
                    4
                </span>
                <h3 className='text-center fw-bold'>Track & Optimize: </h3>
                <p className='text-center'>Keep an eye on performance and fine-tune for success.</p>
                </div>
            </div>
        </div>
    </div>
    </section>

    {/* primro offers */}

    <section className='roff_offrs' id='features'>
    <div className="container">
        <div className="row">
        <div className="col-md-6">
            <h2 className='mb-4'>Unleash the Power of Primo Offers</h2>
            <div className="itm_hd">
                <p className='fw-bold mb-3'>Features Grid:</p>
                <ul className='p-0'>
                    <li className='list-style  mb-3'><b>Hyper-Local Magic:</b> Engage your local customers right when they need it.</li>
                    <li className='list-style  mb-3'><b>Pinpoint Promotions:</b> Hit the bullseye with targeted promotions.</li>
                    <li className='list-style  mb-3'><b>Real-Time Tracking:</b> Stay ahead with real-time analytics and insights.</li>
                    <li className='list-style  mb-3'><b>Customizable Deals:</b> Tailor offers to fit your unique business.</li>
                    <li className=' list-style mb-3'><b>24/7 Support:</b> Our team is always here to help you succeed.</li> 
                </ul>
            </div>
        </div>
        <div className="col-md-6">
                <div className="img">
                    <img src={offer_img2} alt="" />
                </div>
        </div>
        </div>
    </div>
    </section>

    {/* prime Community */}
    <section className='prime_blk'>
        <div className="container">
            <div className="crd">
                <div className="desc my-4">
                <h3 className='mb-4 fw-bold'>Join the Primo Community Today!</h3>
                <h6 className='mb-4'>Become a part of the Primo Community and enjoy all these benefits for free. Connect with customers in real time and prepare for future success!</h6>
                </div>
            <div className="btn_grp">
                <a href='#' className='lrn_mor'>Learn More</a>
                <Link to="/login" className='landing_btn'>Join for Free Now</Link>
            </div>
            </div>
        </div>
    </section>

    {/* newsletter */}

    <section className='landing_newsletter'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <div className="nws_blk my-4">
                    <h2 className='mb-3'>Newsletter Sign-Up</h2>
                    <p className='mb-3'>Stay in the loop! Subscribe to our newsletter for the latest updates and exclusive offers.</p>
                </div>
                <div className="nws_frm mb-4">
                    <form action="">
                        <input type="email" placeholder='Enter your email address' />
                        <button>Subscribe</button>
                    </form>
                </div>
            
                </div>
            </div>
        </div>
    </section>

    {/* footer */}
    <footer>
    <div className="container">
        <hr />
        <div className="row">
            <div className="col-md-8">
                <div className="d-flex align-items-center flex-wrap">
                    <div className="fut_logo">
                    <img src={l_logo} alt="" />
                    </div>
                    <div className="fut_lst">
                        <ul className='d-flex'>
                            <li className='list-style'><a href="https://www.primooffers.com/about">About Us</a></li>
                            <li className='list-style'><Link to="/termsofservice">Terms of Service</Link></li>
                            <li className='list-style'><Link to="/privacypolicy">Privacy Policy</Link></li>
                            <li className='list-style'><Link to="/contact">Contact </Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <ul className='d-flex align-items-center justify-content-end p-0 m-0 h-100 gap-4 list_fut'>
                    <li className='list-style'>
                        <a href="https://www.instagram.com/primooffers/">
                            <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 2.09668H7C4.23858 2.09668 2 4.33526 2 7.09668V17.0967C2 19.8581 4.23858 22.0967 7 22.0967H17C19.7614 22.0967 22 19.8581 22 17.0967V7.09668C22 4.33526 19.7614 2.09668 17 2.09668Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.0002 11.4667C16.1236 12.299 15.9815 13.149 15.594 13.8958C15.2065 14.6426 14.5933 15.2481 13.8418 15.6264C13.0903 16.0047 12.2386 16.1363 11.408 16.0027C10.5773 15.869 9.80996 15.4768 9.21503 14.8819C8.62011 14.287 8.22793 13.5196 8.09426 12.689C7.9606 11.8583 8.09226 11.0066 8.47052 10.2551C8.84878 9.50359 9.45438 8.89048 10.2012 8.50298C10.948 8.11548 11.7979 7.97333 12.6302 8.09674C13.4791 8.22262 14.265 8.6182 14.8719 9.22504C15.4787 9.83189 15.8743 10.6178 16.0002 11.4667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 6.59668H17.51" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg></span>
                        </a>
                    </li>
                    <li className='list-style'>
                    <a href="https://www.linkedin.com/company/primooffers/">
                        <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 8.09668C17.5913 8.09668 19.1174 8.72882 20.2426 9.85404C21.3679 10.9793 22 12.5054 22 14.0967V21.0967H18V14.0967C18 13.5662 17.7893 13.0575 17.4142 12.6825C17.0391 12.3074 16.5304 12.0967 16 12.0967C15.4696 12.0967 14.9609 12.3074 14.5858 12.6825C14.2107 13.0575 14 13.5662 14 14.0967V21.0967H10V14.0967C10 12.5054 10.6321 10.9793 11.7574 9.85404C12.8826 8.72882 14.4087 8.09668 16 8.09668Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 9.09668H2V21.0967H6V9.09668Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4 6.09668C5.10457 6.09668 6 5.20125 6 4.09668C6 2.99211 5.10457 2.09668 4 2.09668C2.89543 2.09668 2 2.99211 2 4.09668C2 5.20125 2.89543 6.09668 4 6.09668Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></span>
                    </a>
                    </li>
                    <li className='list-style'>
                    <a href="https://www.facebook.com/PrimoOffers/" >
                        <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 2.09668H15C13.6739 2.09668 12.4021 2.62346 11.4645 3.56115C10.5268 4.49883 10 5.7706 10 7.09668V10.0967H7V14.0967H10V22.0967H14V14.0967H17L18 10.0967H14V7.09668C14 6.83146 14.1054 6.57711 14.2929 6.38957C14.4804 6.20204 14.7348 6.09668 15 6.09668H18V2.09668Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></span>
                    </a>
                    </li>
                    {/* <li className='list-style'>
                    <a href="">
                        <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23 3.09673C22.0424 3.7722 20.9821 4.28884 19.86 4.62673C19.2577 3.93424 18.4573 3.44342 17.567 3.22065C16.6767 2.99789 15.7395 3.05392 14.8821 3.38118C14.0247 3.70844 13.2884 4.29113 12.773 5.05044C12.2575 5.80976 11.9877 6.70906 12 7.62673V8.62673C10.2426 8.67229 8.50127 8.28254 6.93101 7.49217C5.36074 6.7018 4.01032 5.53536 3 4.09673C3 4.09673 -1 13.0967 8 17.0967C5.94053 18.4947 3.48716 19.1957 1 19.0967C10 24.0967 21 19.0967 21 7.59673C20.9991 7.31818 20.9723 7.04032 20.92 6.76673C21.9406 5.76022 22.6608 4.48944 23 3.09673Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></span>
                    </a>
                    </li> */}
                </ul>

            </div>
        </div>
    </div>
    </footer>
    </>
)
}

export default LandingPage