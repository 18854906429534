import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestedOffers: [],
  requestedOffer: {},
};

const requestedOfferSlice = createSlice({
  name: "requestedOffers",
  initialState,
  reducers: {
    getRequestedOffers: (state, action) => {
      state.requestedOffers = action.payload;
      
    },
    getRequestedOffer: (state, action) => {
        state.requestedOffer = action.payload;
        
      },
  },
});

export const { getRequestedOffers, getRequestedOffer } = requestedOfferSlice.actions;

export default requestedOfferSlice.reducer;
