import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./routes/Navigation";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import {persistor, store} from "./redux/store";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { onMessageListener } from "./firebase";
import { PersistGate } from "redux-persist/integration/react";


function App() {
  const [notification, setNotification] = useState(null);
  const toastOptions = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
  };
  useEffect(() => {
    const handleNotification = async () => {
      const payload = await onMessageListener();
      toast.success("New Notification Received")
      setNotification(payload);
      // You can customize the notification handling here, e.g., show a notification using a library like react-toastify or any other notification library
    };

    handleNotification();
  }, []);
 

  return (
       <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <SkeletonTheme baseColor="grey" highlightColor="white">
        <Router>
          <ToastContainer {...toastOptions} />
          <Navigation />
        </Router>
      </SkeletonTheme>
    </Provider>
      </PersistGate>
  );
}

export default App;
