import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { icon_28 } from "../../assets/icons";
import { QR } from "../../assets/images";
import Layout from "../../components/Layout";
import { table } from "./table";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import {
  getAllRedeemed,
  getAllRedeemedOffers,
  getSingleRedeemedOffer,
} from "../../api/user";
import { useState } from "react";
import { useDispatch } from "react-redux";
import RedeemedOfferTable from "../../components/modals/redeemOfferTable";
import { useForm } from "react-hook-form";
import useFetch from "../../customHooks/useFetch";
import  QrReader  from "react-qr-reader";
import Test from "../../components/Test";
import Navbar from "../../components/Navbar";
import { toast } from "react-toastify";

const Index = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState([]);
  const [redeemedId, setRedeemedId] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const but = document.getElementById("but");
  const video = document.getElementById("vid");
  const mediaDevices = navigator.mediaDevices;
  //         video.muted = true;

  const handleId = (id) => {
    navigate(`/edit-offers/${id}`);
  };

  const { data, isLoading } = useFetch(getAllRedeemed());

  useEffect(() => {
    if (data) {
      setUsers(data?.data?.orderList);
    }
  }, [data]);

  const { handleSubmit, reset, register } = useForm(
    {
      mode: "onChange",
      defaultValues: {
        redeemId: ""
      }
    }
  );

  const verifyRedeemedOffer = (data) => {
    // dispatch(getSingleRedeemedOffer(data.redeemId)).then((res) => )
    if(data === ""){
    toast.error("Please enter your unique code")
    } else {
      navigate(`/offer-details/${data}`);
    }
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };
   
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [dataa, setDataa] = useState("");

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    // console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setDataa(scanData);
      // if(dataa !== ""){
        setRedeemedId(scanData)
      // }
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  console.log(redeemedId,"===================>")

  return (
    <>
      <Layout>
        {isLoading ? (
          <HStack w="full" h="100vh" justifyContent="center">
            <Spinner size="lg" color="red" />
          </HStack>
        ) : (
          <Box w={{ xl: "99%", lg: "98%", md: "96%" }} mx={{xl:"auto",lg:"auto",md:"auto"}}>
            {/* <Box display="flex" justifyContent="space-between">
              <Box w={{ xl: "40%" }}>
                <Text
                  fontFamily="Rubik"
                  color="#2E1A47"
                  fontSize={{ xl: "34px", lg: "30px", md: "28px" }}
                  fontWeight="600"
                  lineHeight="40px"
                  letter="-4%"
                >
                  Redeem offers
                </Text>
              </Box>
              <Header />
            </Box> */}
            <Navbar />
            <Box mt="15px" justifyContent="space-between">
              <Box
                w={{ xl: "72%", md: "72%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Text
                  fontSize={{ xl: "14px", lg: "13px", md: "13px" }}
                  color="gray.500"
                >
                  Enter the Unique Code
                </Text>
              </Box>
              <Box w="100%" display="flex" justifyContent="space-between">
                <Box w={{ xl: "68%", lg: "66%", md: "64%" }}>
                  <Input
                    mt={1}
                    bg="white"
                    shadow="lg"
                    type="tel"
                    value={dataa?dataa : redeemedId}
                    fontSize={{ xl: "16px", lg: "14px", md: "13px" }}
                    placeholder="00087573884934394"
                    // {...register("redeemId")}
                    onChange={(e)=>setRedeemedId(e.target.value)}
                  />
                </Box>

                <Box
                  w={{ xl: "31%", lg: "32%", md: "40%" }}
                  display="flex"
                  justifyContent="space-around"
                >
                  <Button
                    variant="solid"
                    bg="#1E0F42"
                    w={{ xl: "30%", md: "28%" }}
                    fontSize={{ xl: "14px", lg: "13px", md: "12px" }}
                    fontWeight="600"
                    color="white"
                    mt={{ xl: "1%", lg: "1%", md: "1.5%" }}
                    // id="but"
                    onClick={() => {
                      setStartScan(!startScan);
                      onOpen();
                    }}
                  >
                    Scan QR
                  </Button>

                  {startScan && (
                    <>
                      <select onChange={(e) => setSelected(e.target.value)}>
                        <option value={"environment"}>Back Camera</option>
                        <option value={"user"}>Front Camera</option>
                      </select>
                      <Modal
                        isOpen={isOpen}
                        size={{ xl: "sm", lg: "xs" }}
                        onClose={onClose}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            <Center>
                              <Text mt="2">QR Code</Text>
                            </Center>
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody pb={6}>
                            <Text
                              fontSize="18px"
                              fontWeight="500"
                              textAlign={"center"}
                            >
                              Scan at the checkout.
                            </Text>
                            <QrReader
                              constraints={{
                                facingMode: selected,
                              }}
                              delay={1000}
                              onError={handleError}
                              onScan={handleScan}
                              // chooseDeviceId={()=>selected}
                              style={{ width: "300px" }}
                            />
                            <Text
                              textAlign={"center"}
                              mt="30px"
                              fontSize="14px"
                              fontWeight="600"
                              color="#3B3538"
                            >
                              Not scanning? Use the redeem point
                            </Text>
                            <Text
                              textAlign={"center"}
                              mt="10px"
                              color="#3B3538"
                              fontSize="23px"
                              fontWeight="600"
                            >
                              9864-9484-6352
                            </Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              bg="#FF1A57"
                              w="full"
                              color="white"
                              fontSize="14px"
                            >
                              Done
                              <Image src={icon_28} ml="5px" />
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </>
                  )}
                
                  {/* {loadingScan && <p>Loading</p>}
                  {dataa !== "" && <p>{dataa}</p>} */}

                  <Button
                    bg="#FF1A57"
                    variant="outline"
                    w={{ xl: "60%", md: "55%" }}
                    fontSize={{ xl: "14px", md: "13px" }}
                    fontWeight="600"
                    color="white"
                    border="none"
                    mt="1%"
                    onClick={()=>verifyRedeemedOffer(redeemedId)}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box mt="20px" w={{ xl: "95%", md: "94%" }}>
                <Text
                  fontFamily="Rubik"
                  color="#2E1A47"
                  fontSize={{ xl: "34px", lg: "30px", md: "26px" }}
                  fontWeight="600"
                  lineHeight="40px"
                  letter="-4%"
                >
                  Offers History
                </Text>
              </Box>
            </Box>
            <Box mt="2%" w="full">
              <RedeemedOfferTable
                itemsPerPage={10}
                items={users}
                handleChange={handleChange}
                handleId={handleId}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        )}
      </Layout>
    </>
  );
};

export default Index;
