import React from "react";
import Layout from "../../components/Layout";
import {
  Box,
  Text,
  FormLabel,
  Center,
  Textarea,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAllRequestedOffers, replyRequestOffer } from "../../api/user";
import { SearchIcon } from "@chakra-ui/icons";
import Header from "../../components/Header";
import RequestedOfferTable from "../../components/modals/requestedOfferTable";
import useFetch from "../../customHooks/useFetch";
import Navbar from "../../components/Navbar";
import RequestOfferInfo from "../../components/modals/RequestOfferInfo";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const schema = yup
.object({
  description: yup.string().required("Description is Required*"),

})
.required();

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const params = useParams();
  const { requestedOffers } = useSelector((state) => state.requestedOffers);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ids, setIds] = useState([]);
  const [users, setUsers] = useState([]);

  const [information, setInformation] = useState(null);
  const [show, setShow] = useState(false);
  const [descriptionVal, setDescriptionVal] = useState("");
 
  const [replyId, setReplyId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const handleId = (id) => {
    navigate(`/edit-offers/${id}`);
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };

  const { data, isLoading } = useFetch(getAllRequestedOffers());
  // console.log(data, "data");

  useEffect(() => {
    if (data) {
      setUsers(data?.data?.requestsListing);
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch(getAllRequestedOffers())
  //     .then((res) => {
  //       setUsers(res.data.requestsListing);
  //       // setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       // setIsLoading(false);
  //       console.log(err);
  //     });
  // }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    const updatedList = users.filter((item) => {
      return item.eventType.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    if (query === "") {
      setUsers(requestedOffers);
    } else {
      setUsers(updatedList);
    }
  };
  // console.log(users, "users");
  const handleDescriptionData = (data) => {
    // console.log(data, "=====");
    setInformation(data);
    handleShow();
  };

  const getId = (id) => {
    setReplyId(id);
  };

  const sendReply = (data) => {
    const apiData = {
      description: data?.description,
    };
    dispatch(replyRequestOffer(replyId, apiData))
      .then((res) => {
        // console.log(res);
        toast.success(res?.message)
        setDescriptionVal("");
        onClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message)
      });
  };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box
            w={{ xl: "99%", lg: "99%", md: "96%" }}
            mx={{ xl: "auto", lg: "auto", md: "auto" }}
          >
            {/* <Box  display="flex" justifyContent="space-between">
              <Box w={{ xl: "50%", lg: "70%",md:"100%" }} mx={{lg:"0px",md:"auto"}}>
                <Text
                  fontFamily="Rubik"
                  color="#2E1A47"
                  fontSize={{ xl: "34px", lg: "30px", md: "28px" }}
                  fontWeight="600"
                  lineHeight="40px"
                  letter="-4%"
                >
                  Requested offer
                </Text>
              </Box>
              <Header />
            </Box> */}
            <Navbar />
            <Box
              display={{ lg: "flex" }}
              justifyContent="space-between"
              mt="15px"
              gap={3}
            >
              <Box
                w={{ xl: "62%", lg: "55%", md: "100%" }}
                mx={{ lg: "0px", md: "auto" }}
              >
                <FormLabel
                  color="#6B6162"
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="400"
                  lineHeight={"21px"}
                >
                  Search offers
                </FormLabel>
                <Box display="flex">
                  <InputGroup w={{ xl: "98%", lg: "98%", md: "100%" }}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="#FF1A57" />}
                    />
                    <Input
                      type="tel"
                      _focus={{
                        outline: "none",
                        border: "none",
                      }}
                      _after={{
                        outline: "none",
                        border: "none",
                      }}
                      _active={{
                        outline: "none",
                      }}
                      bg="white"
                      shadow="lg"
                      borderRadius={5}
                      borderColor="#B1ABA9"
                      onChange={handleSearch}
                      placeholder="Search for event type..."
                    />
                  </InputGroup>
                </Box>
              </Box>

              <Box
                w={{ xl: "19%", lg: "25%", md: "100%" }}
                mx={{ lg: "0px", md: "auto" }}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
                gap="2"
              >
                <Text
                  color="#6B6162"
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="400"
                  mt={{ lg: "0px", md: "7px" }}
                >
                  Bulk actions(Requested Offer)
                </Text>
                <Button
                  bg={"primary.100"}
                  color="white"
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="600"
                  w={{ lg: "100%", md: "100%" }}
                >
                  Stop offer(s)
                </Button>
              </Box>
              <Box
                w={{ xl: "19%", md: "100%" }}
                display="flex"
                mx={{ lg: "0px", md: "auto" }}
                justifyContent={"flex-end"}
                flexDirection="column"
                mt={{ lg: "0px", md: "8px" }}
              >
                <Button
                  bg={"primary.100"}
                  color="white"
                  fontSize={{ xl: "14px", md: "13px" }}
                  fontWeight="600"
                  lineHeight
                  w={{ lg: "100%", md: "100%" }}
                >
                  Delete offer(s)
                </Button>
              </Box>
            </Box>
            <Modal isOpen={isOpen} size="sm" onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <Center>
                    <Text
                      mt="2"
                      fontSize="20px"
                      fontWeight="600"
                      fontFamily="Rubik"
                    >
                      Send Offers
                    </Text>
                  </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={3}>
                  <Textarea
                    p={2}
                    minHeight={"100px"}
                    placeholder="Enter your Offer's Description"
                   {...register("description")}
                  />
                   <Text my={1} color="red" fontSize="xs">
                  {errors.description?.message}
          </Text>
                </ModalBody>
                <ModalFooter pb="40px">
                  <VStack w="100%">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <Button
                        onClick={handleSubmit(sendReply)}
                        bg="#FF1A57"
                        w="full"
                        color="white"
                        fontSize="14px"
                      >
                        Send Offer
                      </Button>
                    )}
                    <Button
                      color="#FF1A57"
                      w="full"
                      border="1px solid #FF1A57"
                      bg="transparent"
                      fontSize="14px"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </VStack>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
          <Box mt="2%" w="full">
            <RequestedOfferTable
              itemsPerPage={2}
              items={users}
              handleChange={handleChange}
              handleId={handleId}
              onOpen={onOpen}
              getId={getId}
              handleDescriptionData={handleDescriptionData}
              sendReply={sendReply}
            />
          </Box>
          <RequestOfferInfo
            handleClose={handleClose}
            show={show}
            information={information}
          />
        </>
      )}
    </Layout>
  );
};

export default Index;
