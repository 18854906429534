import { Box, Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TopCard = ({total, title, profit, icon}) => {
  const navigate = useNavigate()
  const handleManage = ()=>{
    navigate('/manage-offers')
  }

  return (
    <Box
      key=""
      rounded="8px"
      w={{lg:'32%',md:"32%",sm:"98%"}}
      bg="white"
      shadow="lg"
      py={2}
      my={4}>
      <VStack>
        <HStack justifyContent="center">
          <Image src={icon} w={{xl:8,lg:6,md:6}} h={{xl:8,lg:6,md:6}} mt={{xl:"4px",lg:"4px",md:"4px"}}/>
          <Text fontSize={{xl:40,lg:30,md:23}} color="#FF1A57" fontWeight={800}>
            {total || 0}
          </Text>
        </HStack>
        <Text fontSize={{xl:20,lg:17,md:16}} fontWeight={600} >
          {title || 0}
        </Text>
        <Text fontSize={{xl:14,lg:13,md:12}} color="#72992E" textTransform="capitalize">
          {profit || 0} this week
        </Text>
        <Button
          variant="outline"
          borderColor="#FF1A57"
          color="#FF1A57"
          fontSize={{xl:"13px",lg:"12px",md:"12px"}}
          onClick={handleManage}>
          {'Manage >'}
        </Button>
      </VStack>
    </Box>
  );
};

export default TopCard;
