import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { singleFileUpload } from "../api/user";
import { useRef } from "react";
import { Box, Image, Input } from "@chakra-ui/react";
import { card_1, card_4, card_5, card_6 } from "../assets/images";
import { icon_15 } from "../assets/icons";
import { useForm } from "react-hook-form";

const EditChangeImages = ({ gallery, setGallery }) => {
  const imageRef = useRef();
  const imageOneRef = useRef();
  const imageTwoRef = useRef();
  const [previewOne, setPreviewOne] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewTwo, setPreviewTwo] = useState(null);
  const { register } = useForm();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const img = e.target.files[0];
    setPreview(URL.createObjectURL(img));
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setGallery([res.data.image]))
        .catch((error) => console.log(error));
    }
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };
  ///same code h iska b pehle ke jese
  const handleImageOne = (e) => {
    const image = e.target.files[0];
    setPreviewOne(URL.createObjectURL(image));
    if (image) {
      dispatch(singleFileUpload(image))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };
  const handleEditOne = (e) => {
    imageOneRef.current.click();
  };
  const handleImageTwo = (e) => {
    const imageOne = e.target.files[0];
    setPreviewTwo(URL.createObjectURL(imageOne));
    if (imageOne) {
      dispatch(singleFileUpload(imageOne))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };
  const handleEditTwo = (e) => {
    imageTwoRef.current.click();
  };

  const lastImage = gallery.slice(-1)[0];

  return (
    <Box>
      <Box
        w={{ xl: "85%", lg: "70%", md:"100%" }}
        display="flex"
        mt="30px"
        justifyContent="space-between"
        {...register("gallery")}
      >
        <Box w={{lg:"70%",md:"98%"}} position="relative">
          <Image
            src={preview || gallery[gallery.length - 1] || card_4}
            width="full"
            height="237px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          {/* <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            top="-10px"
            right="-10px"
            h="41px"
            w="41px"
            onClick={handleButtonClick}
          /> */}
          <div style={{backgroundColor:"#1E0F42",padding:"10px",
          borderRadius:"100%",position:"absolute",top:"-10px",right:"-10px",height:"41px",width:"41px"}}
           onClick={handleButtonClick}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0835 3.66663H3.66683C3.1806 3.66663 2.71428 3.85978 2.37047 4.2036C2.02665 4.54741 1.8335 5.01373 1.8335 5.49996V18.3333C1.8335 18.8195 2.02665 19.2858 2.37047 19.6297C2.71428 19.9735 3.1806 20.1666 3.66683 20.1666H16.5002C16.9864 20.1666 17.4527 19.9735 17.7965 19.6297C18.1403 19.2858 18.3335 18.8195 18.3335 18.3333V11.9166" stroke="#F8F8F8" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M16.9585 2.29171C17.3232 1.92704 17.8178 1.72217 18.3335 1.72217C18.8492 1.72217 19.3438 1.92704 19.7085 2.29171C20.0732 2.65638 20.278 3.15099 20.278 3.66671C20.278 4.18244 20.0732 4.67704 19.7085 5.04171L11.0002 13.75L7.3335 14.6667L8.25016 11L16.9585 2.29171Z" stroke="#F8F8F8" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
</svg>
          </div>
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            ref={imageRef}
            onChange={handleChange}
          ></Input>
        </Box>
        {/* <Box w="28%" position="relative" mt={["3", "0"]}>
          <Image
            src={previewOne || secondLastImage || card_4}
            w="full"
            h="110px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            right="-10px"
            top="-10px"
            h="41px"
            w="41px"
            onClick={handleEditOne}
          />
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            ref={imageOneRef}
            onChange={handleImageOne}
          ></Input>
          <Image
            src={previewTwo || lastImage || card_4}
            w="full"
            h="110px"
            mt="15px"
            objectFit={"cover"}
            borderRadius="lg"
          />
          <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            bottom="80px"
            right="-10px"
            h="41px"
            w="41px"
            onClick={handleEditTwo}
          />
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            ref={imageTwoRef}
            onChange={handleImageTwo}
          ></Input>
        </Box> */}
      </Box>
    </Box>
  );
};

export default EditChangeImages;
