import React, { useEffect } from 'react'
import { updatedLogo } from '../../assets/icons'
import { Image, Text } from '@chakra-ui/react'
import "./privacy.css";
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
   <>
     <div class="faq_container p-4">
     <div style={{display:"flex",justifyContent:"center",alignItems:"center",direction:"-moz-initial"}}>
            <div>
                <div>
                <Image src={updatedLogo} />
                </div>
             <Text style={{fontSize:"12px"}}>Merchant Dashboard V1.0</Text>
            </div>
        </div>
        <h1 class="text_dark  mb-5">
          <b> PRIVACY POLICY</b>
        </h1>
        <p>
          This privacy policy applies between you, the User of this Website, and
          PRIMOOFFERS LIMITED, the owner and provider of this Website.
          PRIMOOFFERS LIMITED takes the privacy of your information very
          seriously. This privacy policy applies to our use of any and all Data
          collected by us or provided by you in relation to your use of the
          Website.
        </p>
        <p>
          This privacy policy should be read alongside, and in addition to, our
          Terms and Conditions, which can be found at:
          <Link to="/termsofservice" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/termsofservice.
          </Link>
        </p>
        <h5>
          <strong>Please read this privacy policy carefully.</strong>
        </h5>
        <h4>
          <strong>Definitions and interpretation</strong>
        </h4>
        <p class="list_item">
          In this privacy policy, the following definitions are used:
        </p>

        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <tbody>
            <tr>
              <td>Data</td>
              <td>
                collectively all information that you submit to PRIMOOFFERS
                LIMITED via the Website. This definition incorporates, where
                applicable, the definitions provided in the Data Protection
                Laws;
              </td>
            </tr>
            <tr>
              <td>Cookies</td>
              <td>
                a small text file placed on your computer by this Website when
                you visit certain parts of the Website and/or when you use
                certain features of the Website. Details of the cookies used by
                this Website are set out in the clause below (
                <strong>Cookies</strong>);
              </td>
            </tr>
            <tr>
              <td>Data Protection Laws</td>
              <td>
                any applicable law relating to the processing of personal Data,
                including but not limited to the GDPR, Protection and any
                national implementing and supplementary laws, regulations and
                secondary legislation;
              </td>
            </tr>
            <tr>
              <td>GDPR</td>
              <td>the UK General Data Protection Regulation;</td>
            </tr>
            <tr>
              <td>Primooffers LTD,we or us</td>
              <td>
                PRIMOOFFERS LIMITED, a company incorporated in England and Wales
                with registered number 15835192 whose registered office is at
                20-22 WENLOCK ROAD, LONDON, N1 7GU;
              </td>
            </tr>
            <tr>
              <td>UK and EU Cookie Law</td>
              <td>
                the Privacy and Electronic Communications (EC Directive)
                Regulations 2003 as amended by the EU Cookie Privacy and
                Electronic Communications (EC Directive) (Amendment) Regulations
                2011 & the Privacy Law and Electronic Communications (EC
                Directive) (Amendment) Regulations 2018;
              </td>
            </tr>
            <tr>
              <td>User or you</td>
              <td>
                any third party that accesses the Website and is not either (i)
                employed by Primooffers Limited and acting in the course of
                their employment or (ii) engaged as a consultant or otherwise
                providing services to Primooffers Limited and accessing the
                Website in connection with the provision of such services; and
              </td>
            </tr>
            <tr>
              <td>Website</td>
              <td>
                the website that you are currently using,
                https://www.primooffers.com/, and any sub-domains of this site
                unless expressly excluded by their own terms and conditions.
              </td>
            </tr>
          </tbody>
        </table>
        <p class="list_item">
          In this privacy policy, unless the context requires a different
          interpretation:
        </p>
        <ol class="list_style ul_spc">
          <li>The singular includes the plural and vice versa;</li>
          <li>
            References to sub-clauses, clauses, schedules or appendices are to
            sub-clauses, clauses, schedules or appendices of this privacy
            policy;
          </li>
          <li>
            A reference to a person includes firms, companies, government
            entities, trusts and partnerships;
          </li>
          <li>
            "including" is understood to mean "including without limitation";
          </li>
          <li>
            Reference to any statutory provision includes any modification or
            amendment of it;
          </li>
          <li>
            The headings and sub-headings do not form part of this privacy
            policy.
          </li>
        </ol>

        <h4>
          <strong>Scope of this privacy policy</strong>
        </h4>
        <p class="list_item">
          This privacy policy applies only to the actions of PRIMOOFFERS LIMITED
          and Users with respect to this Website. It does not extend to any
          websites that can be accessed from this Website including, but not
          limited to, any links we may provide to social media websites.
        </p>
        <p class="list_item">
          For purposes of the applicable Data Protection Laws, PRIMOOFFERS
          LIMITED is the "data controller". This means that PRIMOOFFERS LIMITED
          determines the purposes for which, and the manner in which, your Data
          is processed.
        </p>
        <h4>
          <strong>Data collected</strong>
        </h4>
        <p class="list_item">
          We may collect the following Data, which includes personal Data, from
          you:
        </p>
        <ul class="list_style ul_spc">
          <li>Name</li>
          <li>date of birth;</li>
          <li>gender;</li>
          <li>
            contact Information such as email addresses and telephone numbers;
          </li>
          <li>
            demographic information such as postcode, preferences and interests;
          </li>
          <li>IP address (automatically collected);</li>
          <li>web browser type and version (automatically collected);</li>
          <li>operating system (automatically collected);</li>
          <li>
            a list of URLs starting with a referring site, your activity on this
            Website, and the site you exit to (automatically collected);
          </li>
        </ul>
        <p>in each case, in accordance with this privacy policy.</p>
        <h4>
          <strong>How we collect Data</strong>
        </h4>
        <p class="list_item">We collect Data in the following ways:</p>
        <ul class="list_style ul_spc">
          <li>Data is given to us by you; and</li>
          <li>Data is collected automatically.</li>
        </ul>
        <h4>
          <strong>Data that is given to us by you</strong>
        </h4>
        <p class="list_item">
          PRIMOOFFERS LIMITED will collect your Data in a number of ways, for
          example:
        </p>
        <ul class="list_style ul_spc">
          <li>
            when you contact us through the Website, by telephone, post, e-mail
            or through any other means;
          </li>
          <li>
            when you register with us and set up an account to receive our
            products/services;
          </li>
          <li>
            when you complete surveys that we use for research purposes
            (although you are not obliged to respond to them);
          </li>
          <li>
            when you enter a competition or promotion through a social media
            channel;
          </li>
          <li>
            when you make payments to us, through this Website or otherwise;
          </li>
          <li>when you elect to receive marketing communications from us;</li>
          <li>when you use our services;</li>
        </ul>
        <p>in each case, in accordance with this privacy policy.</p>
        <h4>
          <strong>Data that is collected automatically</strong>
        </h4>
        <p class="list_item">
          To the extent that you access the Website, we will collect your Data
          automatically, for example:
        </p>
        <ul class="list_style ul_spc">
          <li>
            we automatically collect some information about your visit to the
            Website. This information helps us to make improvements to Website
            content and navigation, and includes your IP address, the date,
            times and frequency with which you access the Website and the way
            you use and interact with its content.
          </li>
          <li>
            we will collect your Data automatically via cookies, in line with
            the cookie settings on your browser. For more information about
            cookies, and how we use them on the Website, see the section below,
            headed "Cookies".
          </li>
        </ul>
        <h4>
          <strong>Our use of Data</strong>
        </h4>
        <p class="list_item">
          Any or all of the above Data may be required by us from time to time
          in order to provide you with the best possible service and experience
          when using our Website. Specifically, Data may be used by us for the
          following reasons:
        </p>
        <ul class="list_style ul_spc">
          <li>internal record keeping;</li>
          <li>improvement of our products / services;</li>
          <li>
            transmission by email of marketing materials that may be of interest
            to you;
          </li>
          <li>
            contact for market research purposes which may be done using email,
            telephone, fax or mail. Such information may be used to customise or
            update the Website;
          </li>
        </ul>
        <p>in each case, in accordance with this privacy policy.</p>
        <p class="list_item">
          We may use your Data for the above purposes if we deem it necessary to
          do so for our legitimate interests. If you are not satisfied with
          this, you have the right to object in certain circumstances (see the
          section headed "Your rights" below).
        </p>
        <p class="list_item">
          For the delivery of direct marketing to you via e-mail, we'll need
          your consent, whether via an opt-in or soft-opt-in:
        </p>
        <ul class="list_style ul_spc">
          <li>
            soft opt-in consent is a specific type of consent which applies when
            you have previously engaged with us (for example, you contact us to
            ask us for more details about a particular product/service, and we
            are marketing similar products/services). Under "soft opt-in"
            consent, we will take your consent as given unless you opt-out.
          </li>
          <li>
            for other types of e-marketing, we are required to obtain your
            explicit consent; that is, you need to take positive and affirmative
            action when consenting by, for example, checking a tick box that
            we'll provide.
          </li>
          <li>
            if you are not satisfied with our approach to marketing, you have
            the right to withdraw consent at any time. To find out how to
            withdraw your consent, see the section headed "Your rights" below.
          </li>
        </ul>
        <p class="list_item">
          {" "}
          When you register with us and set up an account to receive our
          services, the legal basis for this processing is the performance of a
          contract between you and us and/or taking steps, at your request, to
          enter into such a contract.
        </p>
        <p class="list_item">
          We may use your Data to show you PRIMOOFFERS LIMITED adverts and other
          content on other websites. If you do not want us to use your data to
          show you PRIMOOFFERS LIMITED adverts and other content on other
          websites, please turn off the relevant cookies (please refer to the
          section headed "Cookies" below).
        </p>
        <h4>
          <strong>Who we share Data with</strong>
        </h4>
        <p class="list_item">
          We may share your Data with the following groups of people for the
          following reasons:
        </p>
        <ul class="list_style ul_spc">
          <li>
            any of our group companies or affiliates - to ensure the proper
            administration of your website and business;{" "}
          </li>
          <li>
            our employees, agents and/or professional advisors - to obtain
            advice from professional advisers;{" "}
          </li>
          <li>
            third party service providers who provide services to us which
            require the processing of personal data - to help third party
            service providers in receipt of any shared data to perform functions
            on our behalf to help ensure the website runs smoothly;{" "}
          </li>
          <li>
            third party payment providers who process payments made over the
            Website - to enable third party payment providers to process user
            payments and refunds;{" "}
          </li>
          <li>
            relevant authorities - to facilitate the detection of crime or the
            collection of taxes or duties;{" "}
          </li>
        </ul>
        <p>in each case, in accordance with this privacy policy.</p>
        <h4>
          <strong>Keeping Data secure</strong>
        </h4>
        <p class="list_item">
          We will use technical and organisational measures to safeguard your
          Data, for example:
        </p>
        <ul class="list_style ul_spc">
          <li>
            access to your account is controlled by a password and a user name
            that is unique to you.
          </li>
          <li>we store your Data on secure servers.</li>
        </ul>
        <p class="list_item">
          We are certified to PCI DSS. This family of standards helps us manage
          your Data and keep it secure.
        </p>
        <p class="list_item">
          Technical and organisational measures include measures to deal with
          any suspected data breach. If you suspect any misuse or loss or
          unauthorised access to your Data, please let us know immediately by
          contacting us via this e-mail address: ask@primooffers.com.
        </p>
        <p class="list_item">
          If you want detailed information from Get Safe Online on how to
          protect your information and your computers and devices against fraud,
          identity theft, viruses and many other online problems, please visit
          www.getsafeonline.org. Get Safe Online is supported by HM Government
          and leading businesses.
        </p>
        <h4>
          <strong>Data retention</strong>
        </h4>
        <p class="list_item">
          Unless a longer retention period is required or permitted by law, we
          will only hold your Data on our systems for the period necessary to
          fulfil the purposes outlined in this privacy policy or until you
          request that the Data be deleted.
        </p>
        <p class="list_item">
          Even if we delete your Data, it may persist on backup or archival
          media for legal, tax or regulatory purposes.
        </p>
        <h4>
          <strong>Your rights</strong>
        </h4>
        <p class="list_item">
          You have the following rights in relation to your Data:
        </p>
        <ul class="list_style ul_spc">
          <li>
            <strong>Right to access -</strong> the right to request (I) copies
            of the information we hold about you at any time, or (II) that we
            modify, update or delete such information. If we provide you with
            access to the information we hold about you, we will not charge you
            for this, unless your request is "manifestly unfounded or
            excessive." Where we are legally permitted to do so, we may refuse
            your request. If we refuse your request, we will tell you the
            reasons why.
          </li>
          <li>
            <strong>Right to correct -</strong> the right to have your Data
            rectified if it is inaccurate or incomplete.
          </li>
          <li>
            <strong>Right to erase -</strong> the right to request that we
            delete or remove your Data from our systems.
          </li>
          <li>
            <strong>Right to restrict our use of your Data -</strong> the right
            to "block" us from using your Data or limit the way in which we can
            use it.
          </li>
          <li>
            <strong>Right to data portability -</strong> the right to request
            that we move, copy or transfer your Data.
          </li>
          <li>
            <strong>Right to object -</strong> the right to object to our use of
            your Data including where we use it for our legitimate interests.
          </li>
        </ul>
        <p class="list_item">
          To make enquiries, exercise any of your rights set out above, or
          withdraw your consent to the processing of your Data (where consent is
          our legal basis for processing your Data), please contact us via this
          e-mail address: ask@primooffers.com.
        </p>
        <p class="list_item">
          If you are not satisfied with the way a complaint you make in relation
          to your Data is handled by us, you may be able to refer your complaint
          to the relevant data protection authority. For the UK, this is the
          Information Commissioner's Office (ICO). The ICO's contact details can
          be found on their website at https://ico.org.uk/.
        </p>
        <p class="list_item">
          It is important that the Data we hold about you is accurate and
          current. Please keep us informed if your Data changes during the
          period for which we hold it.
        </p>
        <h4>
          <strong>Links to other websites</strong>
        </h4>
        <p class="list_item">
          This Website may, from time to time, provide links to other websites.
          We have no control over such websites and are not responsible for the
          content of these websites. This privacy policy does not extend to your
          use of such websites. You are advised to read the privacy policy or
          statement of other websites prior to using them.
        </p>
        <h4>
          <strong>Changes of business ownership and control</strong>
        </h4>
        <p class="list_item">
          PRIMOOFFERS LIMITED may, from time to time, expand or reduce our
          business and this may involve the sale and/or the transfer of control
          of all or part of PRIMOOFFERS LIMITED. Data provided by Users will,
          where it is relevant to any part of our business so transferred, be
          transferred along with that part and the new owner or newly
          controlling party will, under the terms of this privacy policy, be
          permitted to use the Data for the purposes for which it was originally
          supplied to us.
        </p>
        <p class="list_item">
          We may also disclose Data to a prospective purchaser of our business
          or any part of it.
        </p>
        <p class="list_item">
          In the above instances, we will take steps with the aim of ensuring
          your privacy is protected.
        </p>
        <h4>
          <strong>Cookies</strong>
        </h4>
        <p class="list_item">
          This Website may place and access certain Cookies on your computer.
          PRIMOOFFERS LIMITED uses Cookies to improve your experience of using
          the Website and to improve our range of products and services.
          PRIMOOFFERS LIMITED has carefully chosen these Cookies and has taken
          steps to ensure that your privacy is protected and respected at all
          times.
        </p>
        <p class="list_item">
          All Cookies used by this Website are used in accordance with current
          UK and EU Cookie Law.
        </p>
        <p class="list_item">
          Before the Website places Cookies on your computer, you will be
          presented with a message bar requesting your consent to set those
          Cookies. By giving your consent to the placing of Cookies, you are
          enabling PRIMOOFFERS LIMITED to provide a better experience and
          service to you. You may, if you wish, deny consent to the placing of
          Cookies; however certain features of the Website may not function
          fully or as intended.
        </p>
        <p class="list_item">This Website may place the following Cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Type of Cookie</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Strictly necessary cookies</td>
              <td>
                These are cookies that are required for the operation of our
                website. They include, for example, cookies that enable you to
                log into secure areas of our website, use a shopping cart or
                make use of e-billing services.
              </td>
            </tr>
            <tr>
              <td>Analytical/performance cookies</td>
              <td>
                They allow us to recognise and count the number of visitors and
                to see how visitors move around our website when they are using
                it. This helps us to improve the way our website works, for
                example, by ensuring that users are finding what they are
                looking for easily.
              </td>
            </tr>
            <tr>
              <td>Functionality cookies</td>
              <td>
                These are used to recognise you when you return to our website.
                This enables us to personalise our content for you, greet you by
                name and remember your preferences (for example, your choice of
                language or region). By using the Website, you agree to our
                placement of functionality cookie.
              </td>
            </tr>
            <tr>
              <td>Targeting cookies</td>
              <td>
                These cookies record your visit to our website, the pages you
                have visited and the links you have followed. We will use this
                information to make our website and the advertising displayed on
                it more relevant to your interests. We may also share this
                information with third parties for this purpose.
              </td>
            </tr>
          </tbody>
        </table>
        <p class="list_item">
          You can find a list of Cookies that we use in the Cookies Schedule.
        </p>
        <p class="list_item">
          You can choose to enable or disable Cookies in your internet browser.
          By default, most internet browsers accept Cookies but this can be
          changed. For further details, please see the help menu in your
          internet browser. You can switch off Cookies at any time, however, you
          may lose any information that enables you to access the Website more
          quickly and efficiently.
        </p>
        <p class="list_item">
          You can choose to delete Cookies at any time; however, you may lose
          any information that enables you to access the Website more quickly
          and efficiently including, but not limited to, personalisation
          settings.
        </p>
        <p class="list_item">
          It is recommended that you ensure that your internet browser is
          up-to-date and that you consult the help and guidance provided by the
          developer of your internet browser if you are unsure about adjusting
          your privacy settings.
        </p>
        <p class="list_item">
          For more information generally on cookies, including how to disable
          them, please refer to aboutcookies.org. You will also find details on
          how to delete cookies from your computer.{" "}
        </p>
        <h4>
          <strong>General</strong>
        </h4>
        <p class="list_item">
          You may not transfer any of your rights under this privacy policy to
          any other person. We may transfer our rights under this privacy policy
          where we reasonably believe your rights will not be affected.
        </p>
        <p class="list_item">
          If any court or competent authority finds that any provision of this
          privacy policy (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of this privacy policy will not be affected.
        </p>
        <p class="list_item">
          Unless otherwise agreed, no delay, act or omission by a party in
          exercising any right or remedy will be deemed a waiver of that, or any
          other, right or remedy.
        </p>
        <p class="list_item">
          This Agreement will be governed by and interpreted according to the
          law of England and Wales. All disputes arising under the Agreement
          will be subject to the exclusive jurisdiction of the English and Welsh
          courts.
        </p>
        <h4>
          <strong>Changes to this privacy policy</strong>
        </h4>
        <p class="list_item">
          {" "}
          PRIMOOFFERS LIMITED reserves the right to change this privacy policy
          as we may deem necessary from time to time or as may be required by
          law. Any changes will be immediately posted on the Website and you are
          deemed to have accepted the terms of the privacy policy on your first
          use of the Website following the alterations.
        </p>
        <p>
          You may contact PRIMOOFFERS LIMITED by email at ask@primooffers.com.
        </p>
        <p>
          This privacy policy was updated on <strong>23 July 2024. </strong>
        </p>
        <h4>
          <strong>Cookies</strong>
        </h4>
        <p>
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </p>
        <h5>
          {" "}
          <strong>Strictly necessary</strong>
        </h5>
        <p>We use the following strictly necessary cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Operations based Cookies</td>
              <td>
                These cookies are essential for the operation of our Site. They
                make it possible for you to browse and use our Site. If you
                disable these cookies, the Site will not function correctly.
              </td>
            </tr>
          </tbody>
        </table>
        <h5>
          {" "}
          <strong>Functionality</strong>
        </h5>
        <p>We use the following functionality cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Functionality based Cookies</td>
              <td>
                These cookies are used to recognize and remember your
                preferences. For example, functionality cookies may use
                information, such as your location, to make sure that you arrive
                at a version of our Site that is specific to your city or
                region.
              </td>
            </tr>
          </tbody>
        </table>
        <h5>
          {" "}
          <strong>Analytical/performance</strong>
        </h5>
        <p>We use the following analytical/performance cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Performance based Cookies</td>
              <td>
                These cookies gather information about how visitors use the Site
                and monitor Site performance. For example, performance cookies
                tell us which pages are popular, monitor traffic to our Site and
                compile anonymous analytic information. Performance cookies can
                also be used to identify and remedy operational problems with
                the Site.
              </td>
            </tr>
          </tbody>
        </table>
        <h5>
          {" "}
          <strong>Targeting</strong>
        </h5>
        <p>We use the following targeting cookies:</p>
        <table class="table table-bordered" colspan="0" aria-rowspan="2">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Advertising Preferences based Cookies</td>
              <td>
                We offer you choices about receiving interest-based ads from us.
                For this, we allow third parties to deliver personalized
                advertising content on our Site and/ or on third party websites
                and applications. These third parties use cookies to understand
                your interest in the advertisements and content delivered to
                you, as well as to serve you with more relevant advertisements
                based on your activities.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
   </>
  )
}

export default PrivacyPolicy