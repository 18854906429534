import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 notifications:[],
 count:0
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotification: (state, action) => {
      state.notifications = action.payload.notificationData;
      state.count = action.payload.count;

    },
    clearNotification : (state) => {
      state.notifications = []
      state.count = 0
    }
  },
});

export const { getNotification,clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;