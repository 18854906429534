import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null
};

const authSlice = createSlice({
    name: "authUser",
    initialState,

    reducers:{
        loginSuccess: (state,action)=>{
            state.user = action.payload.user;
            state.token = action.payload.user.token || action.payload.token;

        },
        socialloginSuccess: (state,action)=>{
            state.user = action.payload;
            state.token= action.payload.token
        },
        logout: (state)=>{
            state.user= null;
            state.token = null;
        },
        signupSuccess: (state,action)=>{
            state.user = action.payload.user;
            state.token = action.payload.user.token || action.payload.token;

        },
        updateUser: (state,action)=>{
            state.user = action.payload.user;

        },
        updateUserBussinessDetails:(state,action)=>{
            state.user = action.payload
        }
    }
});

export const {
    loginSuccess,
    socialloginSuccess,
    logout,
    signupSuccess,
    updateUser,
    updateUserBussinessDetails
}= authSlice.actions;

export default authSlice.reducer;