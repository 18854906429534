import {
  Box,
  Text,
  TabPanels,
  TabPanel,
  Tabs,
  Select,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import BillingTable from "../../components/BillingTable";
import Navbar from "../../components/Navbar";
import { getBillingData } from "../../api/user";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const dummyData = [
  {
    offerName: "Offer 1",
    sellerName: "Seller A",
    status: "Completed",
    amount: "$100",
  },
  {
    offerName: "Offer 2",
    sellerName: "Seller B",
    status: "Pending",
    amount: "$200",
  },
  {
    offerName: "Offer 3",
    sellerName: "Seller C",
    status: "Completed",
    amount: "$150",
  },
  {
    offerName: "Offer 4",
    sellerName: "Seller D",
    status: "Cancelled",
    amount: "$250",
  },
  {
    offerName: "Offer 5",
    sellerName: "Seller E",
    status: "Pending",
    amount: "$300",
  },
];



const Index = () => {
  const [getBillData,setGetBillData] = useState()
  const [total,setTotal] = useState(null)
const dispatch = useDispatch()

const getBillingReport = async() =>{
  dispatch(getBillingData()).then((res)=>{
    setTotal(res.data.grandTotal)
    setGetBillData(res.data.data)
  }).catch((err)=>{
    console.log(err.data.message)
  }) 
}
useEffect(()=>{
getBillingReport()
},[])

  return (
    <Layout>
      
      <Box position={"absolute"} left={2}
        w={{ "2xl":"97%",xl: "97%", lg: "97%", md: "99%" }}
        mx={{ "2xl":"auto",xl:"auto",lg: "auto", md:"auto" }}
        ml={{"2xl":"1%",xl:"1%",lg:"1%"}}
        // display={"flex"}
        // justifyContent={"space-between"}
      >
          <Navbar />
          <Box
            w={{ xl: "100%", lg: "96%", md: "100%" }}
            mx={{ xl:"auto",lg: "auto", md: "auto" }}
            display="flex"
            justifyContent="space-between"
          >
            {/* <div>Hello</div> */}
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              w={{ "2xl":"100%",xl: "100%", lg: "96%", md: "100%" }}
              mx={{ "2xl":"auto%",xl:"auto",lg: "auto", md: "auto" }}
            >
              <TabPanels>
                <TabPanel  mt={"2"}  w={{ xl: "100%", lg: "96%", md: "100%" }}
                mx={{ xl:"auto",lg: "auto", md: "auto" }}
                >
                  <Box flexDirection={["column", "row"]}>
                    <Text>Total Sold Offers Amount :- {total}</Text>
                    <Box
                     w={{ xl:"100%", lg: "96%", md: "100%" }}
                      mx={{ xl:"auto",lg: "auto", md: "auto" }}
                      mt="15px"
                      flexDirection={["column", "row"]}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400" color="#6B6162" mb={2}>
                          Search Offers
                        </Text>
                        <Text
                          fontSize="14px"
                          color="#6B6162"
                          fontWeight="400"
                          // mr={["0", "7"]}
                        ></Text>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={["column", "row"]}
                        gap={3}
                      >
                        <InputGroup w={["full", "100%"]} mt={["2", "0"]}>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="#FF1A57" />}
                          />
                          <Input
                            w={{ xl: "100%", lg: "96%", md: "100%" }}
                            mx={{ xl:"auto",lg: "auto", md: "auto" }}
                            type="tel"
                            bg="white"
                            shadow="lg"
                            pl={10}
                            _focus={{
                              outline: "none",
                              border: "none",
                            }}
                            _after={{
                              outline: "none",
                              border: "none",
                            }}
                            _active={{
                              outline: "none",
                            }}
                            borderRadius={5}
                            borderColor="#B1ABA9"
                            placeholder="Search for offer name and seller name ..."
                          />
                        </InputGroup>
                        <Box w={["18%", "16%"]}>
                          <Button
                            bg="#ff1a57"
                            variant="outline"
                            w={["20%", "90%"]}
                            border="none"
                            mt={["2", "0"]}
                          >
                            <Text fontSize="xs" color="white">
                              Search
                            </Text>
                          </Button>
                        </Box>
                        {/* <Box w={["18%", "16%"]}>
                        <Select
          fontSize={{ xl: "13px", lg: "11px", md: "13px" }}
          fontWeight={"600"}
          // placeholder="Select Duration"
          bg="white"
          // onChange={(e)=>setType(e.target.value)}
        >
          <option value={"daily"}>Daily</option>
          <option value={"weekly"}>Weekly</option>
          <option value={"monthly"}>Monthly</option>
          <option value={"yearly"}>Yearly</option>
        </Select>
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                  {getBillData?.length > 0 ? 
                  <Box w={"auto"}>
                  <BillingTable itemsPerPage={10} items={getBillData} />
                </Box>
                  :
                  <Box display={"flex"} justifyContent={"center"} alignContent={"center"} mt={"50px"}>
                    <Text fontSize={"20px"} fontWeight={"bold"}>No Report Found</Text>
                  </Box> }
                </TabPanel>
              
              </TabPanels>
            </Tabs>
          </Box>
      </Box>
     
    </Layout>
  );
};

export default Index;
