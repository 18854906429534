import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react"
import '@fontsource/rubik'
// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      100: "#FF1A57",
      900: "#FF1A57",
    },
    secodary: {
      100: "#1E0F42",
    },
    fonts: {
      heading: `'Rubik', sans-serif`,
      body: `'Rubik', sans-serif`,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);

