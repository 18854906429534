import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ManageOffers from '../pages/ManageOffers';
import ManageOrders from '../pages/ManageOrders';
import EditOffers from '../pages/EditOffers';
import MyBusiness from '../pages/MyBusiness';
import BillingReport from "../pages/BillingReport";
import SupportTicket from '../pages/SupportTicket';
import SupportCentre from '../pages/SupportCentre';
import NewTicket from '../pages/NewTicket';
import RequestedOffer from '../pages/RequestedOffer';
import RedeemOffers from '../pages/RedeemOffers';
import OfferDetails from '../pages/OfferDetails';
import Payment from '../pages/Payment';
import ForgotPassword from '../pages/ForgotPassword';
import OtpVerification from '../pages/OtpVerification';
import ResetPassword from '../pages/ResetPassword';
import PasswordChanged from '../pages/PasswordChanged';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import Spinner from '../pages/Spinner';
import MainDashboard from '../pages/MainDashboard';
import Subscription from '../pages/Subscription';
import NewOffer from '../pages/NewOffer';
import {getFromLocalStorage} from '../helpers';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {loginSuccess} from '../redux/reducers/userSlice';
import Profile from '../pages/Profile';
import PrivateRoutes from './privateRoutes';
import StripeDetail from '../pages/StripeDetail/StripeDetail';
import Success from '../components/modals/Success';
import InnerSubscription from '../pages/InnerSubscription';
import UpdatedSubscription from '../pages/SubscriptionUpdated';
import NotFound from '../pages/NotFound';
import ContactUs from '../pages/ContactUs';
import TermsOfService from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CookiesPolicy from '../pages/CookiesPolicy';
import LandingPage from '../components/LandingPage';
import Notification from '../pages/Notification';
import MerchantPlans from "../pages/MerchantPlans"
import NewSelectedPlan from '../pages/NewSelectedPlan';

const Navigation = () => {
  const dispatch = useDispatch();
  const token = getFromLocalStorage('token');
  const user = getFromLocalStorage('userData');

  useEffect(() => {
    if (Object.keys(getFromLocalStorage('userData')).length) {
      dispatch(
        loginSuccess({
          token,
          user,
        }),
      );
    }
    
  }, [token]);
  
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/home" element={<MainDashboard />} />
        <Route path="/new-offer" element={<NewOffer />} />
         {/* <Route path="/subscription" element={<Subscription />} /> */}
        {/* <Route path="/purchase-subscription" element={<InnerSubscription />} /> */}
        <Route path="/manage-offers" element={<ManageOffers />} />
        <Route path="/manage-orders" element={<ManageOrders />} />
        <Route path="/support-centre/:id" element={<SupportTicket />} />
        <Route path="/edit-offers/:id" element={<EditOffers />} />
        <Route path="/my-business" element={<MyBusiness />} />
        <Route path="/billing-report" element={<BillingReport />} />
        <Route path="/support-centre" element={<SupportCentre />} />
        {/* <Route path="/support-ticket" element={<SupportTicket />} /> */}
        <Route path="/new-ticket" element={<NewTicket />} />
        <Route path="/requested-offers" element={<RequestedOffer />} />
        <Route path="/redeem-offers" element={<RedeemOffers />} />
        <Route path="/offer-details/:id" element={<OfferDetails />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/success" element={<Success />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/stripedetail" element={<StripeDetail />} />
        <Route path="/your-selection" element={<NewSelectedPlan />} /> 
        <Route path="/choose-how-to-promote" element={<MerchantPlans/>} />   
      </Route>
      
      <Route path="/" element={<LandingPage />} />
      <Route path="/spinner" element={<Spinner />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/otp-verification" element={<OtpVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/password-changed" element={<PasswordChanged />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
       <Route path="/contact" element={<ContactUs/>} />
       <Route path="/termsofservice" element={<TermsOfService/>} />
       <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
       <Route path="/cookiespolicy" element={<CookiesPolicy/>} />
       
       <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};
export default Navigation;
