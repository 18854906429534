import {Box, Button, Image} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {notfound }from '../../assets/images';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box w="full" h="100vh" display="grid" placeItems="center">
      <Box>
        <Image src={notfound} w="100%" h="100%" />
        <Button
          size="lg"
          w="full"
          colorScheme="pink"
          onClick={() => navigate('/')}>
          Back to Home!
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
