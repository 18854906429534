import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { icon_22 } from "../../assets/icons";

const Index = () => {
  const navigate = useNavigate()
  const handleLogin = ()=>{
    navigate('/login')
  }
  return (
    <Box display={"grid"} placeItems={"center"} h={"100vh"}>
      <Box w={"60%"} display={"grid"} placeItems={"center"} gap="40px">
        <Box>
          <VStack>
            <Image
              src={icon_22}
              border="1px solid #FF1A57"
              borderRadius="full"
              p="5px"
            />
          </VStack>
          <Text
            textAlign={"center"}
            fontSize="20px"
            color="#3B3538"
            fontWeight="600"
            mt="10px"
          >
            Password Changed!
          </Text>
          <Text
            textAlign={"center"}
            fontSize="16px"
            color="#3B3538"
            fontWeight="450"
            mt="5px"
          >
            Your password has been changed successfully.
          </Text>
          <Button color="white" bg="#FF1A57" mt="25px" w="full" onClick={handleLogin}>
          Login
        </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
