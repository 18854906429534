import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Stat, StatNumber } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import Layout from "../../components/Layout";
import { icon_16 } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, statusChangeMultipleOffers } from "../../api/user";
import { SearchIcon } from "@chakra-ui/icons";
import { toast } from "react-toastify";
import OrderTable from "../../components/modals/ordersTable";
import useFetch from "../../customHooks/useFetch";
import Navbar from "../../components/Navbar";

const Orders = () => {
  const [ids, setIds] = useState([]);
  const [status, setStatus] = useState("");
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const navigate = useNavigate();
  const { orders } = useSelector((state) => state.orders);

  const handleId = (id) => {
    navigate(`/edit-offers/${id}`);
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      let status = tempUser.map((value) => value.status);
      if (checked) {
        setIds(ids);
        setStatuses(status);
      } else {
        setIds([]);
        setStatuses([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setStatus(value.status);
        setIds([...ids, idss[0]]);
      } else {
        setStatus("");
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };
  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers);
  let redeemedPrice = (offers?.totalRedeemed?.redeemedPrice)?.toFixed(2)

  let apiData = {
    offset: "",
    limit: "",
  };

  const { data , isLoading} = useFetch(getAllOrders(apiData));


  useEffect(()=>{
    if(data){
      setUsers(data?.data?.manageOrder)
    }
  },[data])



  // const getOrdersData = () => {
  //   const apiData = {
  //     offset: "",
  //     limit: "",
  //   };
  //   dispatch(getAllOrders(apiData))
  //     .then((res) => {
  //       if (res.statusCode === 200) {
  //         setIsLoading(false);
  //         setUsers(res.data.manageOrder);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getOrdersData();
  // }, []);

  const handleResumeOffers = () => {
    if (ids?.length > 0) {
      const apiData = {
        ids,
        status: "active",
      };
      dispatch(statusChangeMultipleOffers(apiData))
        .then((res) => {
          if (res.statusCode == 200) {
            toast.success(res.message);
            setIds([]);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    // console.log(query, "query");
    const updatedList = users.filter((item) => {
      return (
        item.offerName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.transDetails.status.toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
    });

    console.log(updatedList, "update");

    if (query === "") {
      setUsers(orders?.manageOrder);
    } else {
      setUsers(updatedList);
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box  w={{xl: "99%",lg:"99%",md: "98%" }}
             mx={{lg:"auto", md:"auto"}}

            >
              <Navbar />
            {/* <Box
              w={{xl: "99%",md: "100%" }}
             mx={{md:"auto",lg:"auto"}}
              display="flex"
              justifyContent="space-between"
              ml={{md:"10px"}}
            >
              <Text
                fontFamily="Rubik"
                color="#2E1A47"
                fontSize={30}
                fontWeight="600"
                lineHeight="40px"
                letter="-4%"
              >
                Manage Orders
              </Text>
              <Header />
            </Box> */}
            <Grid
              w={{ xl: "99%",lg:"99%",md:"99%" }}
              mx={{xl:"auto",lg:"auto",md:"auto"}}
              mt={4}
              templateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={{lg:6,md:4}}
              ml={{ md: 2, xl: 0 }}
             
            >
              <GridItem w={"100%"}>
                <Box
                  h={"186px"}
                  textAlign="center"
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={{lg:"space-evenly",md:"space-between"}}
                  bg="white"
                >
                  <Box >
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#B5D779"
                    >
                      {offers?.totalActiveOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize="20px"
                      lineHeight="34px"
                      rounded="lg"
                      mt="-15px"
                      textTransform="capitalize"
                    >
                      active Orders
                    </Text>
                  </Box>
                  <Box mt="-15px">
                    <Text
                      fontWeight={600}
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize="20px"
                      lineHeight="34px"
                      mt="-15px"
                      textTransform="capitalize"
                    >
                      total Orders
                    </Text>
                  </Box>
                </Box>
              </GridItem>
              <GridItem w="100%">
                <Box
                  textAlign="center"
                  h={"186px"}
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={"space-evenly"}
                  bg="white"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalSold?.sold || 0}
                    </Text>
                    <Stat>
                      <StatNumber
                        color="#7B6F6F"
                        fontSize="28px"
                        lineHeight={"34px"}
                        fontFamily={"normal"}
                        fontWeight={600}
                        mt="-10px"
                        letterSpacing={"-0.02em"}
                      >
                        £{offers?.totalSold?.SoldPrice || 0}
                      </StatNumber>
                    </Stat>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      textTransform="capitalize"
                    >
                      total sold
                    </Text>
                  </Box>
                </Box>
              </GridItem>
              <GridItem w="100%">
                <Box
                  textAlign="center"
                  h={"186px"}
                  boxShadow="lg"
                  rounded="lg"
                  p={"1"}
                  display={"flex"}
                  flexDirection="column"
                  justifyContent={"space-evenly"}
                  bg="white"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.totalRedeemed?.redeemed || 0}
                    </Text>
                    <Stat>
                      <StatNumber
                        color="#7B6F6F"
                        fontSize="28px"
                        lineHeight={"34px"}
                        fontFamily={"normal"}
                        fontWeight={600}
                        mt="-10px"
                        letterSpacing={"-0.02em"}
                      >
                       £{redeemedPrice || 0}
                      </StatNumber>
                    </Stat>
                  </Box>
                  <Box>
                    <Text
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      total redemeed
                    </Text>
                  </Box>
                </Box>
              </GridItem>

              <GridItem w="100%">
                <Box
                  textAlign="center"
                  boxShadow="lg"
                  p="1"
                  rounded="lg"
                  h={"186px"}
                  bg="#FFEBEB"
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="space-evenly"
                >
                  <Box>
                    <Text
                      fontWeight="600"
                      fontFamily={"Rubik"}
                      fontStyle={"normal"}
                      fontSize="40px"
                      lineHeight={"56px"}
                      color="#FF1A57"
                    >
                      {offers?.suspendedOffers || 0}
                    </Text>
                    <Text
                      fontWeight={600}
                      fontFamily={"Commissioner"}
                      fontStyle={"normal"}
                      color="#1E0F42"
                      fontSize={{ xl: "20px", lg: "20px" }}
                      lineHeight="34px"
                      mt="-5px"
                    >
                      Suspended orders
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      bg="transparent"
                      border="1px solid #FF1A57"
                      w="140px"
                      h="33px"
                      p="1px"
                    >
                      <Flex>
                        <Image src={icon_16} />
                        <Text color="#FF1A57" fontSize="xs">
                          Filter by Suspended
                        </Text>
                      </Flex>
                    </Button>
                  </Box>
                </Box>
              </GridItem>
            </Grid>

            <Box
              display={{xl:"flex",lg:"flex"}}
              justifyContent="space-between"
              mt="15px"
              gap="2"
            >
              <Box
                w={{ xl: "50.5%", lg: "51%", md: "99%" }}
                mx={{xl:"auto",lg:"auto",md:"auto"}}
                ml={{ md: "10px", xl: "0" }}
              >
                <FormLabel
                  color="#6B6162"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight={"21px"}
                >
                  Search orders
                </FormLabel>
                <Box display="flex">
                  <InputGroup w={["98%", "100%"]}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="#FF1A57" />}
                    />
                    <Input
                      type="tel"
                      pl={12}
                      _focus={{
                        outline: "none",
                        border: "none",
                      }}
                      _after={{
                        outline: "none",
                        border: "none",
                      }}
                      _active={{
                        outline: "none",
                      }}
                      bg="white"
                      shadow="lg"
                      borderRadius={5}
                      borderColor="#B1ABA9"
                      onChange={handleSearch}
                      placeholder="Search for offer name and status..."
                    />
                  </InputGroup>
                </Box>
              </Box>
              <Box
                w={{ xl: "24.5%", md: "99%" }}
                mx={{xl:"auto",lg:"auto",md:"auto"}}
                display="flex"
                justifyContent={"flex-end"}
                flexDirection="column"
                gap="2"
                mt={{md:"3px",lg:"0px"}}
                ml={{md:"10px"}}
              >
                <Text color="#6B6162" fontSize="14px" fontWeight="400">
                  Bulk actions(select admins)
                </Text>
                <Button
                  bg={"primary.100"}
                  color="white"
                  disabled={ids?.length < 1 ? true : false}
                  fontSize="14px"
                  fontWeight="600"
                >
                  Pause order(s)
                </Button>
              </Box>
              <Box
                w={{ xl: "24%", lg: "24%", md: "99%" }}
                mx={{xl:"auto",lg:"auto",md:"auto"}}
                mr={{ md: "9", xl: "2" }}
                display={{xl:"flex",lg:"flex",md:"flex"}}
                justifyContent={"flex-end"}
                flexDirection="column"
                mt={{md:"10px"}}
                ml={{md:"10px"}}
              >
                <Button
                  bg={"primary.100"}
                  color="white"
                  disabled={ids?.length < 1 ? true : false}
                  onClick={handleResumeOffers}
                  fontSize="14px"
                  fontWeight="600"
                >
                  Resume order(s)
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt="2%" w="full">
            <OrderTable
              itemsPerPage={10}
              items={users}
              handleChange={handleChange}
              handleId={handleId}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default Orders;
