import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/CheckoutForm";
import {useLocation} from 'react-router-dom'
const Payment = () => {
  const {state} = useLocation();
  const { clientSecret } = state || {};


  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    // <Layout>
    <Box display={'flex'} justifyContent={"center"} alignItems={"center"} mt={"6"} height={"full"}>
      
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    
    {/* <Layout>
      <Box ml={{xl:"5px",lg:"5px",md:"3px"}}>
   
         <Navbar />
           <Box>
           <Box w={{xl:"55%",lg:"55%",md:"100%"}} mt="15">
        <FormLabel color="#6B6162" fontSize="14px" fontWeight="400" mb="1">
          Card Holder Name
        </FormLabel>
        <Input
        bg="white"
        shadow="lg"
          type="text"
          placeholder="Nishtha"
          color="#4B4447"
          fontSize="14px"
          fontWeight="400"
        />
        <FormLabel mt="25"fontSize="14px" fontWeight="400" color="#6B6162" mb="1">
          Card Number
        </FormLabel>
        <Input
        bg="white"
        shadow="lg"
          type="text"
          placeholder="0515 0100 0011 1234"
          color="#4B4447"
          fontSize="14px"
          fontWeight="400"
        />
           </Box>
        </Box>
        <Box>
        <Box w={{xl:"55%",lg:"55%",md:"100%"}} mt="25">
        <FormControl
          display="flex"
          direction="row"
          justifyContent="space-between"
        >
          <Box w="48%">
            <FormLabel fontSize="14px" fontWeight="400" color="#6B6162" mb="1">
              Expiry Date
            </FormLabel>
            <Input
            bg="white"
            shadow="lg"
              type="text"
              placeholder="MM/YY"
              color="#4B4447"
              fontSize="14px"
              fontWeight="400"
            />
          </Box>
          <Box w="48%">
            <FormLabel fontSize="14px" fontWeight="400" color="#6B6162" mb="1">
              CVV
            </FormLabel>
            <Input
            bg="white"
            shadow="lg"
              type="text"
              placeholder="XXX"
              color="#4B4447"
              fontSize="14px"
              fontWeight="400"
            />
          </Box>
        </FormControl>
      </Box>
      </Box>
      <Box>
      <Box w={{xl:"55%",lg:"55%",md:"100%"}} mt="30px">
        <Button
          w="100%"
          color="white"
          bg="#FF1A57"
          fontSize="14px"
          fontWeight="600"
        >
          Continue
        </Button>
      </Box>
      </Box>
      </Box>
      </Layout> */}

   </Box>
  //  </Layout>
  );
};

export default Payment;
