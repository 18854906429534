import React from 'react'

const Home = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.5 8L10 2.16666L17.5 8V17.1667C17.5 17.6087 17.3244 18.0326 17.0118 18.3452C16.6993 18.6577 16.2754 18.8333 15.8333 18.8333H4.16667C3.72464 18.8333 3.30072 18.6577 2.98816 18.3452C2.67559 18.0326 2.5 17.6087 2.5 17.1667V8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 18.8333V10.5H12.5V18.8333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Home;