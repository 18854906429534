import React from 'react'

const SupportCenter = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0001 18.8333C14.6025 18.8333 18.3334 15.1024 18.3334 10.5C18.3334 5.89762 14.6025 2.16666 10.0001 2.16666C5.39771 2.16666 1.66675 5.89762 1.66675 10.5C1.66675 15.1024 5.39771 18.8333 10.0001 18.8333Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.0001 13.8333C11.841 13.8333 13.3334 12.3409 13.3334 10.5C13.3334 8.65905 11.841 7.16666 10.0001 7.16666C8.15913 7.16666 6.66675 8.65905 6.66675 10.5C6.66675 12.3409 8.15913 13.8333 10.0001 13.8333Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.3584 12.8583L15.8917 16.3917" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.1084 16.3917L7.64173 12.8583" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.3584 8.14166L15.8917 4.60833" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.3584 8.14166L15.3001 5.2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.1084 4.60833L7.64173 8.14166" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default SupportCenter;