import React from 'react';
import {
    Box,
    Hide,
    Text,
    Menu,
    Image,
    MenuButton,
    MenuItem,
    MenuList,
  } from '@chakra-ui/react';
  import {useEffect, useState} from 'react';
  import {useDispatch, useSelector} from 'react-redux';
  import {useNavigate} from 'react-router-dom';
  import { getSellerDetails } from '../api/user';
import { removeAllFromLocalStorage } from '../helpers';
import { profilee } from '../assets/images';
import { BsBell } from "react-icons/bs";
import Badge from 'react-bootstrap/Badge';
  
const Header = () => {
    const {seller} = useSelector(state => state.seller);
    const {count} = useSelector(state=>state.notification) 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState('')

    useEffect(() => {
      dispatch(getSellerDetails())
     
        .then(res => {
        
        })
        .catch(err => {
          console.log(err);
        });
    }, []);

    

  return (
    <Box display="flex">
       <Box 
       mt={2}
       mr={6}
       position={"relative"}
       onClick={()=>navigate("/notification")}
       >{
        count > 0 ? 
        <Badge bg='none'  
        style={{
          position:"absolute",
          top:"-14px",left:"8px",
          fontSize:"15px",
          color:"white",
          backgroundColor:"red",
          height:"26px",
          width:"26px",
          borderRadius:"22px"
          }}
          >{count}</Badge> : null
       }
        
        <BsBell size={25} /></Box>

    <Hide below="lg">
      <Box mx={1}>
        <Text
          fontSize={['16px', '16px', '14px']}
          fontWeight={600}
          textAlign="end"
          color="#1E0F42">
          {seller?.name?.businessName}
        </Text>
        <Text
          fontSize={['10px', '10px', '11px']}
          textAlign="end"
          fontWeight={400}

          color="#1E0F42">
          {seller?.email}
        </Text>
      </Box>
     
    </Hide>
    <Menu>
     
      <MenuButton aria-label="Options" variant="outline">
        <Image
          src={  seller?.profileImage || profilee }
          objectFit="cover"
          w={10}
          h={10}
          rounded="full"
        />
      </MenuButton>
     
      <MenuList>
        <MenuItem
          onClick={() => navigate('/profile')}
          _hover={{
            bg: '#FF1A57',
            color: 'white',
          }}>
          Profile
        </MenuItem>
        {/* <MenuItem>Settings</MenuItem> */}
      </MenuList>
    </Menu>
  </Box>
  )
}

export default Header;