import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [],
  offer: {},
};

const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    getOffers: (state, action) => {
      state.offers = action.payload;
      
    },
    getOffer: (state, action) => {
        state.offer = action.payload;
        
      },
  },
});

export const { getOffers, getOffer } = offerSlice.actions;

export default offerSlice.reducer;
