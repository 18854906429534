import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
  Stack,
  Hide,
  StatNumber,
  Stat,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const TableHead = [
  { name: "Offer Name" },
  { name: "Status" },
  { name: "Quantity" },
  { name: "Sold" },
  { name: "redeemed" },
  { name: "price" },
  { name: "sales" },
  { name: "created" },
  { name: "action" },
];

function Items({
  currentItems,
  handleChange,
  handleId,
  onOpen,
  handleDescriptionData,
  getId,
}) {
  return (
    <>
      <Box mt="2%" w={{ md: "99%" }}>
        <TableContainer rounded="md">
          {currentItems?.length > 0 ? (
            <Table size={{ xl: "sm", lg: "xs", md: "xs" }}>
              <Thead bg="gray.100">
                <Tr>
                  <Th>
                    <Checkbox
                      p={4}
                      colorScheme="red"
                      isChecked={
                        currentItems?.filter((user) => user?.isChecked !== true)
                          .length < 1
                      }
                      name="allChecked"
                      onChange={handleChange}
                    ></Checkbox>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      Buyer name
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      event type
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      date and time
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px " }}
                      textAlign="center"
                    >
                      guests
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      location
                    </Text>
                  </Th>
                  {/* <Th>
                  <Text fontSize={{ xl: "12px", lg: "11px", md: "10px" }} textAlign="center">
                    activities
                  </Text>
                </Th> */}
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      budget
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      message
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize={{ xl: "12px", lg: "11px", md: "10px" }}
                      textAlign="center"
                    >
                      Action
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems
                  ?.map((value, index) => (
                    <Tr key={value._id} mt={4}>
                      <Td py={4} mt={4}>
                        <Checkbox
                          p={4}
                          colorScheme="red"
                          isChecked={value.isChecked || false}
                          name={value._id}
                          onChange={(e) => handleChange(e, value)}
                        />
                      </Td>
                      <Td>
                        <Text
                          fontWeight={700}
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          textAlign="center"
                        >
                          {value.senderName}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontWeight={700}
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          textAlign="center"
                        >
                          {value.eventType}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          fontWeight={600}
                          textAlign="center"
                        >
                          {value.date}
                        </Text>
                      </Td>

                      <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "10px" }}
                          fontWeight={600}
                          textAlign="center"
                        >
                          {value.guests}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          fontWeight={600}
                          textAlign="center"
                          whiteSpace="break-spaces"
                        >
                          {value?.location?.address} {value?.location?.city}
                        </Text>
                      </Td>
                      {/* <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          fontWeight={600}
                          textAlign="center"
                        >
                          {value.activities}
                        </Text>
                      </Td> */}
                      <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          textAlign="center"
                          fontWeight={600}
                        >
                          {value.budget}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize={{ xl: "13px", lg: "12px", md: "12px" }}
                          whiteSpace="break-spaces"
                          fontWeight={600}
                          textAlign="center"
                        >
                          {value.information?.length > 30 ? (
                            <>
                              <span
                                className="dis pargh_block"
                                dangerouslySetInnerHTML={{
                                  __html: value.information.substring(0, 50),
                                }}
                              />
                              <a
                                class="view-see-more_btn"
                                style={{ cursor: "pointer", color: "#ff1a57" }}
                                onClick={() =>
                                  handleDescriptionData(value.information)
                                }
                              >
                                {""} See More
                              </a>
                            </>
                          ) : (
                            <>
                              (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: value.information,
                                }}
                              />
                              )
                            </>
                          )}
                        </Text>
                      </Td>
                      {/* {item.message.length > 60 ? (
                              <>
                                <span className='dis pargh_block'
                                  dangerouslySetInnerHTML={{
                                    __html: item.message.substring(0, 50),
                                  }}
                                />
                                <a
                                class="view-see-more_btn"
                                style={{cursor:"pointer"}}
                                  onClick={() =>
                                    handleDescriptionData(item.message)
                                  }
                                >
                                  See More
                                </a>
                              </>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.message,
                                }}
                              />
                            )} */}
                      <Td>
                        <Button
                          colorScheme="white"
                          color="#FF1A57"
                          border="1px solid #FF1A57"
                          fontSize={{ xl: "13px", lg: "12px", md: "10px" }}
                          onClick={() => {
                            onOpen();
                            getId(value._id);
                          }}
                        >
                          Send
                        </Button>
                      </Td>
                    </Tr>
                  ))
                  .slice(0, 4)}
              </Tbody>
            </Table>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"50vh"}
            >
              <Text fontFamily={"bold"} fontSize={"25px"}>
                {" "}
                No Data Found
              </Text>
            </Box>
          )}
        </TableContainer>
      </Box>
    </>
  );
}

function RequestedOfferTable({
  itemsPerPage,
  items,
  handleChange,
  handleId,
  onOpen,
  getId,
  handleDescriptionData
}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;


    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
        onOpen={onOpen}
        getId={getId}
        handleDescriptionData={handleDescriptionData}
      />
      <Box bg="white" w="98%" mx="auto">
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default RequestedOfferTable;
